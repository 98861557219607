import React from 'react';
import BasicPage from '../components/BasicPage';

const ContributingPartners = () => {
  return (
    <BasicPage title="Contributing partners">
      <h2>Argentina</h2>
      <ul>
        <li>
          <a href="https://www.bellasartes.gob.ar/">
            Museo Nacional de Bellas Artes
          </a>
        </li>
      </ul>
      <h2>Austria</h2>
      <ul>
        <li>
          <a href="https://www.albertina.at/en/">Albertina</a>
        </li>
        <li>
          <a href="https://www.belvedere.at/">
            Österreichische Galerie Belvedere
          </a>
        </li>
      </ul>
      <h2>Belgium</h2>
      <ul>
        <li>
          <a href="https://www.bam.mons.be/">Beaux-Arts Mons (BAM)</a>
        </li>
        <li>
          <a href="https://kmska.be/nl">
            Koninklijk Museum voor Schone Kunsten Antwerpen
          </a>
        </li>
      </ul>
      <h2>Canada</h2>
      <ul>
        <li>
          <a href="https://www.ngprague.cz/">Art Gallery of Ontario</a>
        </li>
        <li>
          <a href="https://museum.mcmaster.ca/">McMaster Museum of Art</a>
        </li>
      </ul>
      <h2>Czech Republic</h2>
      <ul>
        <li>
          <a href="https://www.ngprague.cz/">National Gallery Prague</a>
        </li>
      </ul>
      <h2>Denmark</h2>
      <ul>
        <li>
          <a href="https://glyptoteket.dk/">Ny Carlsberg Glyptotek</a>
        </li>
      </ul>
      <h2>Finland</h2>
      <ul>
        <li>
          <a href="https://ateneum.fi/">Ateneum Art Museum</a>
        </li>
      </ul>
      <h2>France</h2>
      <ul>
        <li>
          <a href="https://www.inha.fr/">
            Institut national d’histoire de l'art
          </a>
        </li>
        <li>
          <a href="https://angladon.com/">
            Musée Angladon – Collection Jacques Doucet
          </a>
        </li>
        <li>
          <a href="https://www.mba-lyon.fr/fr">Musée des Beaux Arts de Lyon</a>
        </li>
        <li>
          <a href="https://www.musee-orsay.fr/">Musée d'Orsay</a>
        </li>
      </ul>
      <h2>Germany</h2>
      <ul>
        <li>
          <a href="https://www.hamburger-kunsthalle.de/">
            Hamburger Kunsthalle
          </a>
        </li>
        <li>
          <a href="https://www.museum-folkwang.de/">Museum Folkwang</a>
        </li>
        <li>
          <a href="https://museum-wiesbaden.de/">Museum Wiesbaden</a>
        </li>
        <li>
          <a href="https://www.pommersches-landesmuseum.de/">
            Pommersches Landesmuseum
          </a>
        </li>
        <li>
          <a href="https://www.kunsthalle-karlsruhe.de/">
            Staatliche Kunsthalle Karlsruhe
          </a>
        </li>
        <li>
          <a href="https://www.staatsgalerie.de/">Staatsgalerie Stuttgart</a>
        </li>
        <li>
          <a href="https://von-der-heydt-museum.de/">Von der Heydt-Museum</a>
        </li>
      </ul>
      <h2>Hungary</h2>
      <ul>
        <li>
          <a href="https://www.mfab.hu/">Museum of Fine Arts (Budapest) </a>
        </li>
      </ul>
      <h2>Japan</h2>
      <ul>
        <li>
          <a href="https://h-am.jp/">Hikaru Museum</a>
        </li>
        <li>
          <a href="https://www.hiroshima-museum.jp/">Hiroshima Museum of Art</a>
        </li>
        <li>
          <a href="https://dali.jp/">Morohashi Museum of Modern Art</a>
        </li>
        <li>
          <a href="https://www.sompo-museum.org/">Sompo Museum of Art</a>
        </li>
        <li>
          <a href="https://www.woodone-museum.jp/">Woodone Museum of Art</a>
        </li>
      </ul>
      <h2>Netherlands</h2>
      <ul>
        <li>
          <a href="https://www.centraalmuseum.nl/">Centraal Museum (Utrecht)</a>
        </li>
        <li>
          <a href="https://www.dordrechtsmuseum.nl/">
            Dordrechts Museum (Dordrecht)
          </a>
        </li>
        <li>
          <a href="https://drentsmuseum.nl/">Drents Museum (Assen)</a>
        </li>
        <li>
          <a href="https://www.groningermuseum.nl/">
            Groninger Museum (Groningen)
          </a>
        </li>
        <li>
          <a href="https://www.hetnoordbrabantsmuseum.nl/">
            Het Noordbrabants Museum (Den Bosch)
          </a>
        </li>
        <li>
          <a href="https://www.kunstmuseum.nl/">Kunstmuseum Den Haag</a>
        </li>
        <li>
          <a href="https://www.boijmans.nl/">
            Museum Boijmans van Beuningen (Rotterdam)
          </a>
        </li>
        <li>
          <a href="https://www.museumdefundatie.nl/">
            Museum de Fundatie (Zwolle)
          </a>
        </li>
        <li>
          <a href="https://www.voorlinden.nl/">Museum Voorlinden (Wassenaar)</a>
        </li>
        <li>
          <a href="https://www.rijksmuseum.nl/">Rijksmuseum (Amsterdam)</a>
        </li>
        <li>
          <a href="https://www.rijksmuseumtwenthe.nl/">
            Rijksmuseum Twenthe (Enschede)
          </a>
        </li>
        <li>
          <a href="https://www.stedelijk.nl/">Stedelijk Museum Amsterdam</a>
        </li>
        <li>
          <a href="https://www.vangoghhuis.com/">Van Gogh Huis (Zundert)</a>
        </li>
      </ul>
      <h2>Norway</h2>
      <ul>
        <li>
          <a href="https://www.nasjonalmuseet.no/">
            The National Museum of Art, Architecture and Design
          </a>
        </li>
      </ul>
      <h2>Romania</h2>
      <ul>
        <li>
          <a href="https://www.mnar.arts.ro/">
            National Museum of Art of Romania
          </a>
        </li>
      </ul>
      <h2>Spain</h2>
      <ul>
        <li>
          <a href="https://www.museothyssen.org/">
            Museo Nacional Thyssen-Bornemisza
          </a>
        </li>
      </ul>
      <h2>Sweden</h2>
      <ul>
        <li>
          <a href="https://goteborgskonstmuseum.se/">Göteborgs Konstmuseum</a>
        </li>
      </ul>
      <h2>Switzerland</h2>
      <ul>
        <li>
          <a href="https://www.fondationbeyeler.ch/">Fondation Beyeler</a>
        </li>
        <li>
          <a href="https://kunstmuseumbasel.ch/">Kunstmuseum Basel</a>
        </li>
        <li>
          <a href="https://www.kunsthaus.ch/">Kunsthaus Zürich</a>
        </li>
        <li>
          <a href="https://www.gianadda.ch/">Fondation Pierre Gianadda</a>
        </li>
        <li>
          <a href="https://www.kmw.ch/">Kunst Museum Winterthur</a>
        </li>
        <li>
          <a href="https://www.langmatt.ch/">Museum Langmatt</a>
        </li>
      </ul>
      <h2>United Kingdom</h2>
      <ul>
        <li>
          <a href="https://barber.org.uk/">Barber Institute of Fine Arts</a>
        </li>
        <li>
          <a href="https://courtauld.ac.uk/gallery/">The Courtauld Gallery</a>
        </li>
        <li>
          <a href="https://fitzmuseum.cam.ac.uk/">Fitzwilliam Museum</a>
        </li>
        <li>
          <a href="https://www.nationalgalleries.org/">
            National Galleries Scotland
          </a>
        </li>
        <li>
          <a href="https://museum.wales/">National Museum of Wales</a>
        </li>
        <li>
          <a href="https://wellcomecollection.org/">Wellcome collection</a>
        </li>
      </ul>
      <h2>United States</h2>
      <ul>
        <li>
          <a href="https://www.artic.edu/">Art Institute of Chicago</a>
        </li>
        <li>
          <a href="https://artbma.org/">Baltimore Museum of Art</a>
        </li>
        <li>
          <a href="https://buffaloakg.org/">Buffalo AKG Art Museum</a>
        </li>
        <li>
          <a href="https://www.denverartmuseum.org/en">Denver Art Museum</a>
        </li>
        <li>
          <a href="https://discovernewfields.org/">
            Indianapolis Museum of Art
          </a>
        </li>
        <li>
          <a href="https://www.mfah.org/">Museum of Fine Arts, Houston</a>
        </li>
        <li>
          <a href="https://www.guggenheim.org/">Solomon R. Guggenheim Museum</a>
        </li>
        <li>
          <a href="https://www.nortonsimon.org/">Norton Simon Museum</a>
        </li>
        <li>
          <a href="https://www.barnesfoundation.org/">The Barnes Foundation</a>
        </li>
        <li>
          <a href="https://toledomuseum.org/">Toledo Museum of Art</a>
        </li>
        <li>
          <a href="https://artgallery.yale.edu/">Yale University Art Gallery</a>
        </li>
      </ul>
      <h2>Vatican City State</h2>
      <ul>
        <li>
          <a href="https://m.museivaticani.va/">Vatican Museums</a>
        </li>
      </ul>
    </BasicPage>
  );
};

export default ContributingPartners;
