import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import ProvenanceArtworks from './ProvenanceArtworks';
import GridContainer from '../layout/GridContainer';
import { ReactComponent as ArrowIcon } from '../assets/images/icon-arrow-next.svg';
import { colors } from '../style/variables/colors';
import LinkToSearch from './LinkToSearch';
import LazyLoad from 'react-lazyload';

const StyledActorProvenance = styled(GridContainer)`
  .provenance-list__header {
    display: flex;
    margin-bottom: 2rem;

    .sort-buttons {
      font-weight: bold;
      margin-left: auto;
    }

    .sort-buttons__label {
      margin-right: 1em;
    }
  }

  .button-sort {
    height: 35px;
    width: 35px;
    min-width: 0;
    background-color: ${colors.backgroundAlternate};
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &.selected {
      background-color: ${colors.ink};
      color: ${colors.background};
    }
  }

  .button-sort--asc {
    svg {
      transform: rotate(90deg);
    }
  }
  .button-sort--desc {
    svg {
      transform: rotate(-90deg);
    }
  }

  .provenance-item {
    position: relative;

    &--with-date {
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        z-index: 4;
        top: 28px;
        left: 21px;
        height: 100%;
        width: 2px;
        background-color: ${colors.timelineLine};
      }
      &.provenance-item--last-of-timeline {
        &::before {
          height: calc(100% - 52px);
        }
      }
    }
  }

  .provenance-item__header {
    background-color: ${colors.backgroundAlternate};
    padding: 1rem;

    > * {
      margin-left: 3rem;
    }

    &::before {
      content: '';
      display: inline-block;
      position: relative;
      z-index: 5;
      margin-top: 2px;
      margin-left: -4px;
      width: 20px;
      height: 20px;
      background-color: ${colors.timelineDot};
      border: 4px solid ${colors.backgroundAlternate};
      border-radius: 50%;
      float: left;
    }
  }

  .provenance-item__artworks {
    margin-left: 4rem;
  }
`;

const ActorProvenance = ({ actorProvenanceItems }) => {
  const [sortOrder, setSortOrder] = useState('asc');

  // Use `.slice()` to clone the array instead of letting .reverse() mutate
  // actorProvenanceItems itself.
  const sortedActorProvenanceItems =
    sortOrder === 'asc'
      ? actorProvenanceItems.slice()
      : actorProvenanceItems.slice().reverse();

  return actorProvenanceItems.length > 0 ? (
    <StyledActorProvenance className="provenance-list">
      <div className="grid-col">
        <header className="provenance-list__header">
          <h2 className="visually-hidden">Provenance</h2>
          <div
            className="sort-buttons"
            role="group"
            aria-labelledby="sort-buttons-label"
          >
            <span id="sort-buttons-label" className="sort-buttons__label">
              Order
            </span>
            {[
              { value: 'asc', label: 'Sort ascending' },
              { value: 'desc', label: 'Sort descending' },
            ].map(button => (
              <Fragment key={button.value}>
                <input
                  type="radio"
                  id={`sort--${button.value}`}
                  name="sort-order"
                  value={button.label}
                  checked={sortOrder === button.value}
                  onChange={() => setSortOrder(button.value)}
                  className="visually-hidden"
                />
                <label
                  htmlFor={`sort--${button.value}`}
                  className={`button-sort button-sort--${button.value} ${
                    sortOrder === button.value ? 'selected' : ''
                  }`}
                >
                  <span className="visually-hidden">{button.label}</span>
                  <ArrowIcon />
                </label>
              </Fragment>
            ))}
          </div>
        </header>
        <ul className="unstyled-list">
          {sortedActorProvenanceItems.map((item, i) => {
            // This item is the last of the timeline if it is the last of all
            // items, or if the next does not have a period value.
            const nextIndex = i + 1;
            const isLastOfTimeline =
              item.period &&
              (sortedActorProvenanceItems.length === nextIndex ||
                !sortedActorProvenanceItems[nextIndex].period);
            return (
              <li
                key={item.id}
                className={`provenance-item ${
                  item.period
                    ? 'provenance-item--with-date'
                    : 'provenance-item--no-date'
                } ${
                  isLastOfTimeline ? 'provenance-item--last-of-timeline' : ''
                }`}
              >
                <ActorProvenanceItem provenance={item} />
              </li>
            );
          })}
        </ul>
      </div>
    </StyledActorProvenance>
  ) : null;
};

const ActorProvenanceItem = ({ provenance }) => {
  return (
    <LazyLoad height={300} offset={300} once resize>
      <header className="provenance-item__header">
        <h3>{provenance.period ? provenance.period : '(Date unknown)'}</h3>
        <ul className="unstyled-list--inline piped">
          {provenance.type && <li>{provenance.type}</li>}
          {provenance.auctionLabel && provenance.auctionPath && (
            <li>
              <LinkToSearch
                basePath={provenance.auctionPath}
                filters={[
                  {
                    field: 'auction',
                    values: [provenance.auctionId],
                  },
                ]}
              >
                {provenance.auctionLabel}
              </LinkToSearch>
            </li>
          )}
          {provenance.location && <li>{provenance.location}</li>}
        </ul>
      </header>
      <ProvenanceArtworks
        provenance={provenance}
        className="provenance-item__artworks"
      />
    </LazyLoad>
  );
};

export default ActorProvenance;
