/**
 * Normalizes an integer date to a date string
 *
 * @param date
 * @returns {string|null}
 */
const getNormalizedDate = date => {
  if (date && isNaN(date)) return date;

  // Some data items contain integer years instead of string type. This should
  // be fixed in the API but here is a quick fix.
  let normalizedDate = null;
  if (date && typeof date !== 'string' && date.toString().length === 4) {
    normalizedDate = `${date.toString()}-01-01`;
  }

  return normalizedDate;
};

export default getNormalizedDate;
