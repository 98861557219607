import styled from 'styled-components';
import { colors } from '../style/variables/colors';
import mediaQueries from '../style/variables/mediaQueries';

// Styles copied from react-tabs/style/react-tabs.css and then modified.
const StyledHorizontalTabs = styled.div`
  .react-tabs {
    -webkit-tap-highlight-color: transparent;
    margin-top: 2rem;

    ${mediaQueries.md} {
      margin-top: 4rem;
    }
  }

  .react-tabs__tab-list {
    border-bottom: 1px solid ${colors.border};
    margin: 0 0 2rem;
    padding: 0;
  }

  .react-tabs__tab {
    display: inline-block;
    position: relative;
    margin-bottom: -1px;
    list-style: none;
    padding: 0 0 7px;
    border-bottom: 3px solid transparent;
    color: ${colors.inkAlternate};
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4444;
    cursor: pointer;

    &:not(.react-tabs__tab--disabled) + .react-tabs__tab {
      margin-left: 3rem;
    }

    &:hover {
      border-color: ${colors.inkAlternate};
    }

    &:focus {
      border-color: ${colors.ink};
      outline: none;
    }
  }

  .react-tabs__tab--selected {
    border-bottom: 3px solid ${colors.tabHighlight};
    color: ${colors.ink};
    border-radius: 5px 5px 0 0;
    &:hover {
      border-color: ${colors.tabHighlight};
    }
  }

  .react-tabs__tab--disabled {
    color: ${colors.inkDisabled};
    cursor: default;
  }

  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }
`;

export default StyledHorizontalTabs;
