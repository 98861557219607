/**
 * Checks if dataObject has at least one data item.
 *
 * @param dataObject
 * @param renderType
 * @returns {boolean}
 */
export default function hasAtLeastOneDataItem(dataObject) {
  return typeof dataObject !== 'undefined' ? dataObject.length > 0 : false;
}
