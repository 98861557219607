import getFullDate from '../getFullDate';

/**
 * Collects letter data for rendering.
 *
 * @param {LetterTuple[]} artworkLinkedLetters
 *   An array of LetterTuple objects.
 * @returns {object[]} An array of objects with the expected properties.
 */
export default function getContentLetters(artworkLinkedLetters) {
  if (!Array.isArray(artworkLinkedLetters)) {
    return [];
  }

  const data = artworkLinkedLetters.map(letter => {
    const label = letter.dateSent || '';
    return {
      label: label,
      value: {
        id: letter.letterId,
        name: letter.name,
        url: letter.url,
        dateSent: getFullDate(letter.dateSent),
        locationCreated: letter.locationCreated,
        recipient: letter.recipient,
        sender: letter.sender,
      },
      renderType: 'letters',
    };
  });

  data.sort((a, b) => {
    const aLabel = a.label;
    const bLabel = b.label;

    if (aLabel === bLabel) {
      if (a.value.id && b.value.id) {
        return a.value.id < b.value.id ? -1 : 1;
      }
      return 0;
    }

    if (aLabel === null && bLabel !== null) {
      return -1;
    }

    if (bLabel === null && aLabel !== null) {
      return 1;
    }

    return aLabel < bLabel ? -1 : 1;
  });

  return data;
}
