import styled from 'styled-components';
import AdjustOrder from '../components/AdjustOrder';
import { ReactComponent as ArrowIcon } from '../assets/images/icon-arrow-next.svg';

const StyledContent = styled.div`
  h3 {
    margin: 0;

    svg {
      margin-left: 0.5em;
      position: relative;
      top: 0.05em;
    }
  }

  p {
    margin: 0 0 8px 0;
  }
`;

function ViewModeListMinimal({ title, date }) {
  return (
    <StyledContent>
      <AdjustOrder>
        <h3>{title}<ArrowIcon /></h3>
        {date && (
          <p className="order-min">{date}</p>
        )}
      </AdjustOrder>
    </StyledContent>
  );
}

export default ViewModeListMinimal;
