import React from 'react';
import ArtworkDimensions from './ArtworkDimensions';
import ExternalLink from './ExternalLink';
import getIdentifiers from '../utils/Display/getIdentifiers';
import getDisplayDates from '../utils/Display/getDisplayDates';

const ArtworkDlItem = ({ label, data, className }) => {
  if (data.constructor === Array) {
    data = data.filter(Boolean);
  }
  if (!data.length && !React.isValidElement(data)) {
    return null;
  }
  return (
    <div className={`dl-item ${className || ''}`}>
      <dt className="visually-hidden">{label}</dt>
      {data.constructor === Array ? (
        data.map((value, i) => <dd key={i}>{value}</dd>)
      ) : (
        <dd>{data}</dd>
      )}
    </div>
  );
};

/**
 * @param {Tuple} linkedData
 * @param {string} className
 */
const ArtworkBasicInformation = ({ linkedData, className }) => {
  const descriptions = [
    { label: 'Artist', data: linkedData.creator.label },
    {
      label: 'Production date',
      data: [
        getDisplayDates(linkedData.productionDates).join(', '),
        linkedData.location,
      ],
      className: 'piped',
    },
    {
      label: 'Category',
      data: linkedData.objectCategory,
      className: 'space-before',
    },
    {
      label: 'Dimensions',
      data: <ArtworkDimensions {...linkedData.dimensions} />,
    },
    {
      label: 'Owner',
      data: linkedData.owner.label,
      className: 'space-before',
    },
    {
      label: 'Credits',
      data: linkedData.creditLine,
      className: 'piped',
    },
    {
      label: 'Owner inventory ID',
      data: linkedData.ownerInventoryId,
    },
  ];

  // Conditionally add collection url if present.
  const collectionUrl = linkedData.subjectOf;
  if (collectionUrl) {
    descriptions.push({
      label: 'This artwork in the collection online',
      data: <ExternalLink href={collectionUrl}>This artwork in the collection online</ExternalLink>,
    });
  }

  descriptions.push({
    label: 'Identifiers',
    data: getIdentifiers(linkedData.identifiers, false),
    className: 'piped space-before',
  });

  return (
    <div className={`${className || ''} basic-information`}>
      <h2 className="visually-hidden">Artwork summary</h2>
      <dl className="unstyled-list">
        {descriptions.map((description, i) => (
          <ArtworkDlItem key={i} {...description} />
        ))}
      </dl>
    </div>
  );
};

export default ArtworkBasicInformation;
