import React from 'react';
import iconVGW from '../assets/images/icon.svg';
import styled from 'styled-components';
import GridContainer from '../layout/GridContainer';

const StyledFooter = styled.div`
  padding: 0.625rem 0;
`;

const StyledCopyright = styled.div`
  font-size: 0.875rem;
  display: flex;
  flex-direction: row;

  p {
    font-weight: bold;
    line-height: 1.57;
    margin-left: 20px;
  }

  img {
    width: 13px;
    height: 22px;
    align-self: center;
  }
`;

function CopyrightFooter() {
  // ToDo: determine static or dynamic year.
  return (
    <StyledCopyright>
      <img src={iconVGW} alt="Van Gogh Worldwide copyright icon" />
      <p>&copy; 2023 Van Gogh Worldwide</p>
    </StyledCopyright>
  );
}

function PageFooter() {
  return (
    <StyledFooter>
      <GridContainer>
        <div className="grid-col">
          <CopyrightFooter />
        </div>
      </GridContainer>
    </StyledFooter>
  );
}

export default PageFooter;
