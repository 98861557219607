import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import mediaQueries from '../style/variables/mediaQueries';
import GridContainer from '../layout/GridContainer';
import { ReactComponent as VgwLogo } from '../assets/images/logo.svg';
import { ReactComponent as VgwIcon } from '../assets/images/icon.svg';
import SearchBox from './SearchBox';
//import PageHeaderMenu from './PageHeaderMenu';

const StyledHeader = styled.div`
  // Override flex wrap for header.
  .grid-container {
    flex-wrap: nowrap;
  }
`;

const StyledLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  line-height: 1;
  .logo__full {
    display: none;
  }
  ${mediaQueries.md} {
    height: 80px;
    .logo__full {
      display: block;
    }
    .logo__compact {
      display: none;
    }
  }
`;
const SearchBoxWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PageHeader = ({ searchTerm, setSearchTerm }) => {
  return (
    <StyledHeader>
      <GridContainer className="page-header">
        <StyledLogo className="logo grid-col--fluid">
          <Link to="/">
            <span className="visually-hidden">
              Van Gogh Worldwide home page
            </span>
            <VgwLogo aria-hidden="true" className="logo__full" />
            <VgwIcon aria-hidden="true" className="logo__compact" />
          </Link>
        </StyledLogo>
        <SearchBoxWrapper className="logo grid-col--fluid">
          <SearchBox />
        </SearchBoxWrapper>
        {
          /**
           * Page header menu has been disabled per client request. Let's leave
           * it here in case we need it again in the future.
           */
          //<PageHeaderMenu />
        }
      </GridContainer>
    </StyledHeader>
  );
};

export default PageHeader;
