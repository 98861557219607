import { NewsItems } from '../../news/NewsItems';

/**
 * Gets the news items sorted by date.
 *
 * @param order
 *   ASC or DESC
 *
 * @returns {{}}
 *   The sorted news items object.
 */
export default function getSortedNewsItems(order = 'DESC') {
  // Makes item sortable by converting it to an array and sort the array.
  let sortedArray = Object.entries(NewsItems)
    .sort(([,a],[,b]) => {
      const itemA = a.sort_date;
      const itemB = b.sort_date

      if (itemA === itemB) {
        return 0;
      }

      return itemA < itemB ? -1 : 1;
    });

  // Determine order.
  if (order === 'DESC') {
    sortedArray.reverse();
  }

  // Recreate object.
  return sortedArray.reduce((rest, [key, value]) => ({ ...rest, [key]: value }), {});
}
