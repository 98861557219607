import styled from 'styled-components';

const AdjustOrder = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    flex: 0 0 auto;
  }

  .order-min {
    order: -1;
  }
`;

export default AdjustOrder;
