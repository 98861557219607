import React from 'react';
import { Helmet } from 'react-helmet/es/Helmet';
import { withSearch } from '@elastic/react-search-ui';
import SearchResults from '../components/SearchResults';

const Search = ({ resultSearchTerm }) => {
  return (
    <>
      <Helmet>
        <title>
          {resultSearchTerm ? `Search for "${resultSearchTerm}"` : 'Search'}
        </title>
      </Helmet>
      <SearchResults />
    </>
  );
};

export default withSearch(({ resultSearchTerm }) => ({
  resultSearchTerm,
}))(Search);
