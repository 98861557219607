import React from 'react';
import { Link } from 'react-router-dom';
import ArtworkTuple from '../utils/Spinque/ArtworkTuple';

const ResultTableRow = ({ result }) => {
  const tuple = new ArtworkTuple(result.tuple[0]);
  const id = tuple.id;
  const path = `/artwork/${encodeURIComponent(id)}`;
  return (
    <tr>
      <td className="image">Image</td>
      <td className="title">
        <Link to={path}>{tuple.title}</Link>
      </td>
      <td className="object">{tuple.objectCategory}</td>
      <td className="date">Date</td>
      <td className="period">Period</td>
      <td className="collection">Collection</td>
      <td className="catalogue-number">Cat. No.</td>
    </tr>
  );
};

export default ResultTableRow;
