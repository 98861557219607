import React from 'react';
import styled from 'styled-components';
import { Picture } from 'react-responsive-picture';
import { colors } from '../style/variables/colors';
import sizes from '../style/variables/sizes';

const StyledArtworkImagesThumbnails = styled.ul`
  text-align: center;
  border-top: 1px solid ${colors.border};
  padding-top: ${sizes.artworkImagesPadding};
  // Negative margin-bottom to counteract the margin-bottom on the li.
  margin: 0 0 -12px;

  li {
    display: inline-flex;
    cursor: pointer;
    // Margin bottom to provide spacing when the line wraps.
    margin: 0 6px 12px;
  }

  button {
    opacity: 0.4;
    transition: opacity 150ms ease;
    padding: 0;
    height: 80px;
    cursor: pointer;

    &.active,
    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  img {
    height: 100%;
  }
`;

/**
 *
 * Displays a list of image thumbnails.
 *
 * @param Array images
 *   Image data [{uri, srcset, thumbUri, thumbSrcset, alt}]
 * @returns{*}
 *   A styled list of image thumbnails or null
 */
const ArtworkImagesThumbnails = ({ images, activeImage, setActiveImage }) => {
  if (!images || images.length === 0) {
    return null;
  }

  const handleThumbnailClick = e => {
    setActiveImage(parseInt(e.currentTarget.getAttribute('data-index')));
  };

  return (
    <StyledArtworkImagesThumbnails className="unstyled-list--inline">
      {images.map((image, i) => {
        const src = image.thumbSrcset.join(', ');
        return (
          <li key={i}>
            <button
              data-index={i}
              className={i === activeImage ? 'active' : ''}
              onClick={handleThumbnailClick}
            >
              <Picture src={src} alt={`Switch to: ${image.alt}`} />
            </button>
          </li>
        );
      })}
    </StyledArtworkImagesThumbnails>
  );
};

export default ArtworkImagesThumbnails;
