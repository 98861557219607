import React from 'react';
import { ReactComponent as ArrowIcon } from '../assets/images/icon-arrow-next.svg';
import styled from 'styled-components';

const StyledExternalLink = styled.span`
  a {
    display: inline;
  }

  svg {
    margin-left: 0.5em;
    transform: rotate(-45deg);
  }
`;

const ExternalLink = ({ href, onClick, children, className }) => {
  return (
    <StyledExternalLink>
      <a href={href} onClick={onClick} target="_blank" rel="noopener noreferrer" className={className}>
        {children}
        <ArrowIcon />
      </a>
    </StyledExternalLink>
  );
};

export default ExternalLink;
