const periods = [
  {
    id: 'https://vangoghworldwide.lmn.gr/data/period/early-1878',
    label: 'Early period',
    timespan: {
      end_of_the_end: '1878-05-10',
      begin_of_the_begin: '1800-01-01',
    },
  },
  {
    id: 'https://vangoghworldwide.org/data/period/brussel-1878',
    label: 'Borinage/Brussels',
    timespan: {
      end_of_the_end: '1881-04-11',
      begin_of_the_begin: '1878-05-01',
    },
  },
  {
    id: 'https://vangoghworldwide.org/data/period/etten-1881',
    label: 'Etten',
    timespan: {
      end_of_the_end: '1881-09-11',
      begin_of_the_begin: '1881-04-12',
    },
  },
  {
    id: 'https://vangoghworldwide.org/data/period/den_haag-1881',
    label: 'The Hague',
    timespan: {
      end_of_the_end: '1883-09-11',
      begin_of_the_begin: '1881-11-09',
    },
  },
  {
    id: 'https://vangoghworldwide.org/data/period/drenthe-1883',
    label: 'Drenthe',
    timespan: {
      end_of_the_end: '1883-12-04',
      begin_of_the_begin: '1883-09-11',
    },
  },
  {
    id: 'https://vangoghworldwide.org/data/period/nuenen-1883',
    label: 'Nuenen',
    timespan: {
      end_of_the_end: '1885-11-24',
      begin_of_the_begin: '1883-12-05',
    },
  },
  {
    id: 'https://vangoghworldwide.org/data/period/antwerp_paris-1885',
    label: 'Antwerp/Paris',
    timespan: {
      end_of_the_end: '1888-02-19',
      begin_of_the_begin: '1885-11-24',
    },
  },
  {
    id: 'https://vangoghworldwide.org/data/period/arles-1888',
    label: 'Arles',
    timespan: {
      end_of_the_end: '1889-05-08',
      begin_of_the_begin: '1888-02-20',
    },
  },
  {
    id: 'https://vangoghworldwide.org/data/period/saint-remy-1889',
    label: 'Saint-Rémy',
    timespan: {
      end_of_the_end: '1890-05-16',
      begin_of_the_begin: '1889-05-09',
    },
  },
  {
    id: 'https://vangoghworldwide.org/data/period/auvers-sur-oise-1890',
    label: 'Auvers-sur-Oise',
    timespan: {
      end_of_the_end: '1890-07-29',
      begin_of_the_begin: '1890-05-20',
    },
  },
];

/**
 * Gets the named creative period of the artwork.
 *
 * @param start
 *   Dating period start in ISO format YYYY-mm-dd
 * @param end
 *   Dating period end in ISO format YYYY-mm-dd
 * @returns {boolean|{id: string, label: string, timespan: {begin_of_the_begin: string, end_of_the_end: string}}|{id: string, label: string, timespan: {begin_of_the_begin: string, end_of_the_end: string}}|{id: string, label: string, timespan: {begin_of_the_begin: string, end_of_the_end: string}}|{id: string, label: string, timespan: {begin_of_the_begin: string, end_of_the_end: string}}|{id: string, label: string, timespan: {begin_of_the_begin: string, end_of_the_end: string}}}
 *   The period in which the artwork was made.
 */
export default function getPeriodFromTimespan(start, end) {
  if (start === undefined && end === undefined) {
    return false;
  }
  // If only one of the dates is given, set a period of 1 day.
  start = start ? start : end;
  end = end ? end : start;

  for (let i = 0; i < periods.length; i++) {
    const period = periods[i];
    if (
      start >= period.timespan.begin_of_the_begin &&
      end <= period.timespan.end_of_the_end
    ) {
      return period;
    }
  }

  return false;
}
