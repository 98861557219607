import React from 'react';
import useSpinqueData from '../hooks/useSpinqueData';
import ArtworkTuple from '../utils/Spinque/ArtworkTuple';
import ResultGridCell, { Property } from './ResultGridCell';
import styled from 'styled-components';
import HorizontalSlider from './HorizontalSlider';
import mediaQueries from '../style/variables/mediaQueries';
import getRenderedRepresentation from '../utils/Display/getRenderedRepresentation';

const StyledProvenanceArtworks = styled.div`
  .artwork-list {
    > li {
      width: 14rem;
      padding-right: 1rem;

      ${mediaQueries.md} {
        width: 15rem;
        padding-right: 2rem;
      }

      &:last-child {
        width: 13rem;
        padding-right: 0;
      }

      &.force-inline-flex {
        display: inline-flex;
      }
    }
  }
`;

const ProvenanceArtworks = ({ provenance, className = '' }) => {
  const provenanceArtworksQuery = provenance.isAuction
    ? `/e/artworks/q/auction%3AFILTER/p/value/1.0(${encodeURIComponent(
        provenance.id
      )})/results`
    : `/e/artworks/q/provenance%3AFILTER/p/value/1.0(${encodeURIComponent(
        provenance.id
      )})/results`;

  const {
    data: [spinqueData],
  } = useSpinqueData([
    {
      path: provenanceArtworksQuery,
      params: ['count=200'],
    },
  ]);
  const provenanceArtworkItems = (spinqueData?.items ?? []).map(item => {
    return new ArtworkTuple(item.tuple[0]);
  });

  return (
    <StyledProvenanceArtworks className={className}>
      <h4 className="visually-hidden">
        Artworks involved{provenance.type && ` in this ${provenance.type}`}
      </h4>
      <HorizontalSlider
        listTag="ul"
        listClassName="unstyled-list artwork-list"
        tinySliderOptions={{
          lazyload: true,
          slideBy: '1',
          responsive: {
            768: {
              slideBy: '3',
            },
            1360: {
              slideBy: '5',
            },
          },
        }}
        buttonStyle="black"
        featherEdges={true}
      >
        {provenanceArtworkItems.map(artwork => {
          // ResultGridCell returns a <li> tag.
          return (
            <ResultGridCell
              artwork={artwork}
              key={artwork.id}
              className="force-inline-flex"
              overrideComponents={{
                representation: ProvenanceArtworkRepresentation,
              }}
            />
          );
        })}
      </HorizontalSlider>
    </StyledProvenanceArtworks>
  );
};

// This component is passed to ResultGridCell to override its Representation
// sub-component. This allows us to add the markup required for lazyloading
// support in Tiny Slider.
const ProvenanceArtworkRepresentation = ({ linkedData }) => {
  const renderableRepresentation = linkedData.representation.uri
    ? getRenderedRepresentation(linkedData.representation)
    : null;

  return (
    <Property className="image">
      {renderableRepresentation && (
        <>
          <img
            className="tns-lazy-img"
            data-src={renderableRepresentation.uri}
            alt={renderableRepresentation.alt}
          />
        </>
      )}
    </Property>
  );
};

export default ProvenanceArtworks;
