import React from 'react';
import logoMondriaanFonds from '../assets/images/supporters/logo-supporter-mondriaan-fund-2023.svg';
import logoVVGS from '../assets/images/supporters/logo-supporter-vincent-van-gogh-stichting-colored.png';
import logoCHA from '../assets/images/partners/logo-partner-cultural-heritage-agency.png';
import logoCHA2x from '../assets/images/partners/logo-partner-cultural-heritage-agency-2x.png';
import { Picture } from 'react-responsive-picture';
import styled from 'styled-components';
import { colors } from '../style/variables/colors';
import mediaQueries from '../style/variables/mediaQueries';

const StyledSupportedBy = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border-top: solid 1px ${colors.border};
  padding: 30px 0;
  margin-top: 20px;

  ${mediaQueries.md} {
    flex-direction: row;
    margin-top: 35px;
  }

  h3 {
    font-size: 16px;
    margin-bottom: 0;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 30px;

    ${mediaQueries.lg} {
      justify-content: center;
    }

    li {
      padding-right: 20px;
    }
  }

  .partner {
    margin-left: 0;

    ${mediaQueries.md} {
      margin-left: auto;
    }
  }

  .partner-visual {
    padding-top: 16px;
    align-items: center;
  }

  .supported-by {
    img {
      filter: grayscale(100%);
    }
  }
`;

export default function SupportedByPartner() {
  const supportedBy = [
    {
      name: 'Vincent van Gogh Stichting',
      url: 'https://www.vangoghmuseum.nl/',
      className: 'img-grayscale',
      image: {
        path: logoVVGS,
        width: 80,
        height: 48,
      },
    },
    {
      name: 'Mondriaan fund',
      url: 'https://www.mondriaanfonds.nl/',
      image: {
        path: logoMondriaanFonds,
        width: 82,
        height: 92,
      },
    },
  ];

  const partner = {
    name: 'Cultural Heritage Agency',
    url: 'https://english.cultureelerfgoed.nl/',
    image: {
      path: logoCHA,
      path2x: logoCHA2x,
      width: 226,
      height: 80,
    },
  };

  return (
    <StyledSupportedBy>
      <div className="supported-by">
        <h3>Supported by</h3>
        <ul className="unstyled-list">
          {supportedBy.map((supporter, i) => (
            <li key={i}>
              <Partner
                name={supporter.name}
                url={supporter.url}
                image={supporter.image}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="partner">
        <h3>Partner</h3>
        <div className="partner-visual">
          <Partner
            name={partner.name}
            url={partner.url}
            image={partner.image}
          />
        </div>
      </div>
    </StyledSupportedBy>
  );
}

function Partner({ name, url, image }) {
  let imgSrc = image.path;
  if (image.path2x) {
    imgSrc += `,  ${image.path2x} 2x`;
  }
  return (
    <a target="_blank" rel="noopener noreferrer" href={url}>
      <Picture
        src={imgSrc}
        width={image.width}
        height={image.height}
        alt={`Logo of ${name}`}
      />
    </a>
  );
}
