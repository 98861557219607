import React from 'react';
import BasicPage from '../components/BasicPage';

const NotFound = () => {
  return (
    <BasicPage title="Not found">
      <p>The page you requested could not be found.</p>
    </BasicPage>
  );
};

export default NotFound;
