import React from 'react';
import styled from 'styled-components';
import { gutters } from '../style/variables/sizes';
import mediaQueries from '../style/variables/mediaQueries';
import GridContainer from '../layout/GridContainer';
import { Helmet } from 'react-helmet/es/Helmet';
import BackButton from '../components/BackButton';

const StyledContent = styled.div`
  margin: 3rem auto;

  h2 {
    font-size: 1.125rem;
  }

  .news-item--aside {
    ${mediaQueries.max_md} {
      margin-bottom: 24px;
    }
  }

  figure {
    margin-top: 2rem;
    margin-bottom: 2rem;

    ${mediaQueries.max_md} {
      margin-left: -${gutters.xs};
      margin-right: -${gutters.xs};
    }

    ${mediaQueries.md} {
      margin-left: -16.6667%;
      margin-right: -16.6667%;
    }

    figcaption {
      ${mediaQueries.max_md} {
        margin-left: ${gutters.xs};
        margin-right: ${gutters.xs};
      }
    }
  }
`;


const NewsPage = ({ title, children }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <StyledContent>
        <GridContainer>
          <div className="news-item--aside grid-col--md-2 grid-col--lg-3">
            <BackButton>Back</BackButton>
          </div>
          <div className="news-item--main grid-col--md-8 grid-col--lg-6">
            {children}
          </div>
        </GridContainer>
      </StyledContent>
    </>
  );
};

export default NewsPage;
