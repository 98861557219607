import React from 'react';
import styled from 'styled-components';
import { colors } from '../style/variables/colors';
import { gutters } from '../style/variables/sizes';
import mediaQueries from '../style/variables/mediaQueries';
import GridContainer from '../layout/GridContainer';
import { Helmet } from 'react-helmet/es/Helmet';

const StyledContent = styled.div`
  margin: 3rem auto;

  h2 {
    color: ${colors.inkAlternate};
  }

  img {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .breakout {
    margin: 2rem -${gutters.xs};

    ${mediaQueries.md} {
      margin-left: -16.6667%;
      margin-right: -16.6667%;
    }

    img {
      width: 100%;
    }
  }

  .boxed {
    border-top: solid 1px ${colors.border};
    border-bottom: solid 1px ${colors.border};
    padding: 24px 0;
    margin-top: 24px;

    .heading {
      cursor: pointer;

      .text {
        font-weight: bold;
        font-size: 18px;
      }
      .icon {
        display: inline-block;
        margin-left: 10px;
      }
    }

    .description {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  .small-text {
    font-size: 14px;
  }

  .alphabetic-styled-list {
    counter-reset: list;
    margin: 0;

    li {
      list-style: none;
      position: relative;
    }

    li:before {
      counter-increment: list;
      content: counter(list, lower-alpha) ') ';
      position: absolute;
      left: -1.4em;
    }
  }
`;

export const BoxedStyledContent = ({ heading, icon, text, href }) => {
  return (
    <div className="boxed">
      <a className="heading" href={href}>
        <span className="text">{heading}</span>
        <span className="icon">
          <img src={icon.src} alt={icon.altText} />
        </span>
      </a>
      <p className="description">{text}</p>
    </div>
  );
};

const BasicPage = ({ title, children }) => {
  return (
    <GridContainer>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <StyledContent className="grid-col--lg-6 grid-col--md-8">
        <h1>{title}</h1>
        {children}
      </StyledContent>
    </GridContainer>
  );
};

export default BasicPage;
