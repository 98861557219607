/**
 * Header image info keyed by zero-based month numbers (0 = January). The first
 * element will be used as fallback option.
 */
const homeHeaderImages = {
  0: {
    fileName: 'home-header-placeholder.jpg',
    altText: 'detail of Vincent van Gogh artwork',
  },
  4: {
    fileName: 'home-header-placeholder.jpg',
    altText: 'detail of Vincent van Gogh artwork (May)',
  },
};

export default function getHomeHeaderImage() {
  // Get the zero-based month number.
  const monthNumber = new Date().getMonth();
  // Get the object property matching the month number, or fall back on the
  // first element of the object.
  return (
    homeHeaderImages[monthNumber] ??
    homeHeaderImages[Object.keys(homeHeaderImages)[0]]
  );
}
