import React from 'react';
import styled from 'styled-components';
import { colors } from '../style/variables/colors';
import GridContainer from '../layout/GridContainer';
import FoundingPartners from './FoundingPartners';
import PagesMenu from './PagesMenu';
import SupportedByPartner from './SupportedByPartner';
import iconLinkedin from "../assets/images/icon-linkedin.svg";

const StyledDoormat = styled.div`
  padding: 2rem 0 0;
  background-color: ${colors.backgroundAlternate};
  margin-top: 5rem;
  
  .iconlinkedin {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.background};
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
`;

function PageDoormat() {
  return (
    <StyledDoormat>
      <GridContainer>
        <div className="grid-col--sm-5 grid-col--md-4 grid-col--lg-3 grid-col--xl-2">
          <PagesMenu />
          <a className="iconlinkedin"  href="https://www.linkedin.com/company/van-gogh-worldwide">
            <img src={iconLinkedin} alt="Van Gogh Worldwide Linkedin icon"/>
          </a>
        </div>
        <div className="grid-col--sm-7 grid-col--md-8 grid-col--lg-9 grid-col--xl-10">
          <FoundingPartners />
          <SupportedByPartner />
        </div>
      </GridContainer>
    </StyledDoormat>
  );
}

export default PageDoormat;
