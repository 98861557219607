import PropTypes from 'prop-types';
import React from 'react';
import Select, { components } from 'react-select';
import { ReactComponent as SelectIndicator } from '../assets/images/icon-select-indicator.svg';

const setDefaultStyle = {
  option: () => ({}),
  control: () => ({}),
  dropdownIndicator: () => ({}),
  indicatorSeparator: () => ({}),
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

function Sorting({
  className,
  label,
  onChange,
  options,
  value,
  style,
  ...rest
}) {
  const selectedValue = value;

  const selectedOption = selectedValue
    ? options.find(option => option.value === selectedValue)
    : null;

  const mergedStyle = style
    ? Object.assign({}, setDefaultStyle, style)
    : setDefaultStyle;

  return (
    <div className={`sui-sorting' ${className}`} {...rest}>
      {label && <div className="sui-sorting__label">{label}</div>}
      <Select
        className="sui-select"
        classNamePrefix="sui-select"
        value={selectedOption}
        onChange={o => onChange(o.value)}
        options={options}
        isSearchable={false}
        styles={mergedStyle}
        components={{ DropdownIndicator }}
      />
    </div>
  );
}

Sorting.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ).isRequired,
  value: PropTypes.string,
  style: PropTypes.object,
};

export default Sorting;
