/**
 * Removes the ', ' in name and swaps it in the correct order.
 * E.g. Gogh, Vincent van -> Vincent van Gogh
 *
 * @param name
 * @returns {string|*}
 */
export default function splitAndSwapName(name) {
  return name.indexOf(', ') > -1
    ? name
        .split(', ')
        .reverse()
        .join(' ')
    : name;
}
