/**
 * Filters the catalogue numbers from linkedDataIdentifiers parameter.
 * The identifiers without an actual number in it (just 'JH' or 'F') will not be pushed to the array.
 *
 * @param linkedDataIdentifiers
 * @param pushWithKeys
 * @returns {[]}
 */
export default function getIdentifiers(
  linkedDataIdentifiers,
  pushWithKeys = true
) {
  const F_KEY = 'f';
  const JH_KEY = 'jh';

  const identifiers = [];
  const { f, jh } = linkedDataIdentifiers;

  if (f && f.length > F_KEY.length) {
    identifiers.push(pushWithKeys ? [F_KEY, f] : f);
  }

  if (jh && jh.length > JH_KEY.length) {
    identifiers.push(pushWithKeys ? [JH_KEY, jh] : jh);
  }

  return identifiers;
}
