import React from 'react';
import styled from 'styled-components';
import NewsPage from '../components/NewsPage';
import { NewsItems } from '../news/NewsItems';
import { useParams } from 'react-router-dom';

const StyledContent = styled.div`
  .date {
    &:first-child {
      margin-top: 0;
    }
  }
`;

const NewsItem = () => {

  const { id } = useParams();
  const currentNewsItem = NewsItems[id];

  return (
    <NewsPage title={currentNewsItem.title}>
      <StyledContent>
        <p className="date">{currentNewsItem.display_date}</p>
        <h1>{currentNewsItem.title}</h1>
        {currentNewsItem.body}
      </StyledContent>
    </NewsPage>
  );
};

export default NewsItem;
