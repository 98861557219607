import React, { useEffect } from 'react';
import useSpinqueData from '../hooks/useSpinqueData';
import { useParams } from 'react-router-dom';
import ExhibitionHeader from '../components/ExhibitionHeader';
import SearchUI from '../components/SearchUI';
import { withSearch } from '@elastic/react-search-ui';
import { Helmet } from 'react-helmet/es/Helmet';
import NotFound from './NotFound';
import ExhibitionTuple from '../utils/Spinque/ExhibitionTuple';

const Exhibition = ({ filters, setFilter }) => {
  const id = encodeURIComponent(useParams().id);
  // It makes complete sense to make the exhibition route responsible for
  // setting the exhibition filter. However, when the route calls the setFilter
  // method, that causes a push to the browser history stack so the back button
  // will not behave as expected. Therefore it is preferred to link to an
  // exhibition page with the filter value already set.
  useEffect(() => {
    // Check if the exhibition filter is already correctly set.
    const exhibitionFilter = filters.find(
      filter =>
        filter.field === 'exhibition' &&
        filter.values[0] === decodeURIComponent(id)
    );
    if (!exhibitionFilter) {
      setFilter('exhibition', decodeURIComponent(id));
    }
  }, [filters, setFilter, id]);

  const {
    isLoading,
    isError,
    data: [spinqueData],
  } = useSpinqueData([`/q/exhibition/p/id/${id}/results`]);

  const exhibitionJson = spinqueData?.items?.[0]?.tuple?.[0] ?? false;

  if (!isLoading && (isError || !exhibitionJson)) {
    return <NotFound />;
  }

  const exhibition = new ExhibitionTuple(exhibitionJson);

  const title = exhibition.title;

  return (
    <>
      <Helmet>
        <title>{title ? `Exhibition "${title}"` : 'Exhibition'}</title>
      </Helmet>
      <article>
        <ExhibitionHeader exhibition={exhibition} />
        <SearchUI
          fixedFilters={['exhibition']}
          fixedFilterLabel={title ?? 'this exhibition'}
          displaySummary={true}
        />
      </article>
    </>
  );
};

export default withSearch(({ filters, setFilter }) => ({
  filters,
  setFilter,
}))(Exhibition);
