import React from 'react';
import GridContainer from '../layout/GridContainer';
import { ReactComponent as InstituteIcon } from '../assets/images/icon-institute.svg';
import { ReactComponent as UserIcon } from '../assets/images/icon-user.svg';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../style/variables/colors';

const StyledActorResult = styled(GridContainer)`
  .resultActorItems {
    // Compensate default padding on .react-tabs__tab-list.
    margin-top: -2rem;

    > li {
      border-bottom: 1px solid #cccccc;
      padding:  2rem 0;
      display: flex;
      cursor: pointer;

      &:hover {
        // @todo is the hover effect obvious enough? Should we set a background?
        //background-color: ${colors.backgroundAlternate};
        .actor-title a {
          text-decoration: underline;
        }
      }
    }
  }

  .textual {
    padding-left: 41px;
    display: flex;
    flex-direction: column;
  }

  .actor-title {
    font-weight: bold;
    margin: 0;
    a {
      color: ${colors.ink};
      font-size: 1rem;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
  }

  .actor-type {
    order: -1;
  }

  .actor-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: ${colors.backgroundAlternate};

    svg {
      height: 1.75rem;
      width: 1.75rem;
    }
  }
`;

const SearchActorResults = ({ actorResults }) => {
  const navigate = useNavigate();

  function clickResult(e) {
    e.preventDefault();
    if (e.target.tagName !== 'A') {
      navigate(
        e.currentTarget.querySelectorAll('[data-result-target]')[0].dataset
          .resultTarget
      );
    }
  }

  return actorResults.length > 0 ? (
    <StyledActorResult>
      <div className="grid-col">
        <ul className="unstyled-list resultActorItems">
          {actorResults.map((actor, i) => (
            <li key={actor.id} onClick={clickResult}>
              <div className="visual">
                <div className="actor-icon-wrapper">
                  <IconComponent actorType={actor.type} />
                </div>
              </div>
              <div className="textual">
                {actor.title && (
                  <h3 className="actor-title">
                    <Link to={actor.path} data-result-target={actor.path}>
                      {actor.title}
                    </Link>
                  </h3>
                )}
                {
                  <div className="actor-type">
                    {actor.type ?? 'organization'}
                  </div>
                }
                <ActorDate actor={actor} />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </StyledActorResult>
  ) : null;
};

const ActorDate = ({ actor }) => {
  let date = '';
  if (actor.birth) {
    date += actor.birth;
  }
  if (actor.death) {
    date += date ? ' - ' + actor.death : actor.death;
  }
  return date ? <div>{date}</div> : null;
};

const IconComponent = ({ actorType }) => {
  if (actorType === 'person') {
    return <UserIcon aria-hidden="true" width="3rem" height="3rem" />;
  } else {
    return <InstituteIcon aria-hidden="true" width="3rem" height="3rem" />;
  }
};

export default SearchActorResults;
