import Figure from '../components/Figure';
import Intro from '../components/Intro';
import contentImageStillLifeWithCabbageAndClogs from '../assets/images/news/vangoghworldwide_Still_Life_with_Cabbage_and_Clogs_F1.jpg';
import contentImageDescargadoresArles from '../assets/images/news/vangoghworldwide_Descargadores_Arles.jpg';
import contentImageYale from '../assets/images/news/vangoghworldwide_Yale.jpg';

const NewsItems = {
  'news-page-van-gogh-worldwide': {
    title: 'News page Van Gogh Worldwide',
    sort_date: '2023-02-21',
    display_date: '21 February 2023',
    summary:
      'On the news page we will keep you informed about the progress of the project and changes to the website. As we are currently in the second phase of the project, the number of international collections joining the platform is increasing and the functionality of the platform has been improved.',
    body: (
      <>
        <Intro>
          <p>
            Welcome to the news page of Van Gogh Worldwide. On this page we will
            keep you informed about the progress of the project and changes to
            the website.
          </p>
        </Intro>
        <p>
          In the first phase of the project we added all the works of Vincent
          Van Gogh in Dutch collections (1.100). At this moment the Van Gogh
          Worldwide team is working on connecting collections from all over the
          world. At this moment 22 international museums have already joined the
          platform with 93 works. Very diverse collections are being added to
          the platform: from museums in Europe (Musée d’Orsay) and the United
          States (The Chicago Art Institute) to museums in Japan (Morohashi
          Museum of Modern Art). Every new connection brings us one step closer
          to achieving the goal of Van Gogh Worldwide: providing art-historical
          and -technical data about the complete works of Vincent van Gogh.
        </p>
        <p>
          In addition to the collections already available on the platform,
          there are also 18 collections (62 works) that are on the test
          platform. These will, after the museums have checked their data and
          signed the collaboration agreement, go live soon. In addition, we are
          in contact with around fifty museums collaborating and helping them
          provide the necessary data. On{' '}
          <a href="https://www.linkedin.com/company/van-gogh-worldwide/">
            our LinkedIn account
          </a>
          , we share a message for every new collection featured on the
          platform. Please follow us on LinkedIn and share your information with
          us by tagging #vangoghworldwide.
        </p>
        <Figure
          src={contentImageStillLifeWithCabbageAndClogs}
          caption="Vincent van Gogh, Still Life with Cabbage and Clogs, 1881, Van Gogh Museum, Amsterdam (Vincent van Gogh Foundation)."
        />
        <p>
          Besides this news page, other additions have been made to the website
          to improve the quality of the website, such as an exhibition
          thesaurus, which enables us to link the exhibition data of the
          different participants to each other, and a link to the artwork in the
          collection online.
        </p>
        <p>
          Van Gogh Worldwide has recently partnered with Musea Brugge for the
          development of a future research platform on the works of Hans
          Memling, which will be developed in the coming years. Musea Brugge is
          able to do this research thanks to vital project grants from the
          Flemish government. Memling's works will be examined through new
          technologies and they will also be made available to a wider audience
          by innovative digital applications. We are proud to help develop a new
          art history research platform. For more information about this future
          research platform see{' '}
          <a href="https://www.museabrugge.be/en/news/projectsubsidies_memling">
            https://www.museabrugge.be/en/news/projectsubsidies_memling
          </a>
        </p>
      </>
    ),
  },
  'user-survey-and-new-participants': {
    title: 'User survey and new participants',
    sort_date: '2023-08-07',
    display_date: '07 August 2023',
    summary:
      'A user survey has gone live to research and improve the functionality of the Van Gogh Worldwide platform. In addition, many new collections have joined the platform in the past months.',
    body: (
      <>
        <Intro>
          <p>
            A user survey has gone live to research and improve the
            functionality of the Van Gogh Worldwide platform. In addition, many
            new collections have joined the platform in the past months.
          </p>
        </Intro>
        <p>
          Van Gogh Worldwide has posted a user survey online. The goal of this{' '}
          <a href="https://forms.office.com/pages/responsepage.aspx?id=XdXNRBT110Cl8Vrd9_bR_9H6Q6eea0ZBn4dIBqrK-PpUOFlKSEFHM0ZDVUJDSEdTUTJON0Y1WFpDQSQlQCN0PWcu&lang=en">
            user survey
          </a>{' '}
          is to find out who the users of the platform are, for what purposes
          the platform is used, how the users search for certain information and
          whether they are currently missing any information and/or search
          filters. With the answers to those questions, the Van Gogh Worldwide
          team can adapt the platform to the users’ needs. The platform will
          continue to evolve and, furthermore, museums can continue to make
          additions to their data so that all information on the platform is
          always up-to-date. We would appreciate you taking the time to complete
          this user survey for future improvements to the platform.
        </p>
        <Figure
          src={contentImageDescargadoresArles}
          caption="Vincent van Gogh, The Stevedores in Arles, 1888, Museo Nacional Thyssen-Bornemisza, Madrid."
        />
        <p>
          The Van Gogh Worldwide team has welcomed many new collections in the
          past months. Since the previous news post, we have welcomed the
          National Museum of Art of Romania (Bucharest), Wellcome Collection
          (London), Gothenburg Museum of Art (Gothenburg), Museo Nacional
          Thyssen-Bornemisza (Madrid), Ny Carlsberg Glyptotek (Copenhagen),
          Solomon R. Guggenheim Museum (New York), Vatican Museums (Vatican
          City), Pommersches Landesmuseum (Greifswald), Denver Art Museum
          (Denver), Sompo Museum of Art (Tokyo) and Woodone Museum of Art
          (Hiroshima). A total of 23 drawings, prints and paintings from these
          collections have been added to the platform over the past three
          months. We are currently in touch with several worldwide collections
          that will be connected in the near future. Follow us on our{' '}
          <a href="https://www.linkedin.com/company/van-gogh-worldwide/">
            Linkedin account
          </a>
          , where we share a post for each new collection.
        </p>
        <p>
          {' '}
          Our Information Specialist Linked Open Data, Lian Wintermans, gave a
          demonstration of Van Gogh Worldwide at the Digital Humanities Benelux
          Conference 2023. The conference’s central theme, “Crossing borders:
          digital humanities research across languages and modalities”, fits
          well with the purpose of Van Gogh Worldwide, since data from worldwide
          collections are made available on one single platform. During this
          conference, visitors got to learn about the platform, see the
          opportunities it offers researchers, and find out more about the use
          of Linked Open Data. Next to this conference, an article about Van
          Gogh Worldwide and the use of Linked Open Data will appear in the June
          issue of E-data & Research, a Dutch magazine about data and research
          in the alpha and gamma sciences. It is possible to receive this
          magazine by mail, but the content is also made available through their
          website: <a href="https://edata.nl/">https://edata.nl/</a>.
        </p>
        <p>Wishing you a great summer!</p>
      </>
    ),
  },
  'change-of-management-of-van-gogh-worldwide': {
    title: 'Change of management of Van Gogh Worldwide',
    sort_date: '2023-09-28',
    display_date: '28 September 2023',
    summary:
      'The Van Gogh Worldwide project is changing its management structure from October 1, 2023 onward. Started as a project, it is time to hand over the work to our maintenance department.',
    body: (
      <>
        <Intro>
          <p>
            The Van Gogh Worldwide project is changing its management structure
            from October 1, 2023 onward. Started as a project, it is time to
            hand over the work to our maintenance department. But what does that
            mean for the institutions that still want to join the platform? And
            how will the data of participating institutions be updated?
          </p>
        </Intro>
        <p>
          On November 5, 2020, the digital research platform Van Gogh Worldwide
          was launched. In the first phase of the project, all the works of
          Vincent Van Gogh in Dutch collections have been added to the platform
          (more than 50% of the known works). From 2021 to 2023, the goal was to
          connect worldwide collections to the platform. We have collaborated
          with museums all over the world to achieve this.
        </p>
        <Figure
          src={contentImageYale}
          caption="Vincent van Gogh, “Le café de nuit (The Night Café)”, 1888. Yale University Art Gallery (Bequest of Stephen Carlton Clark, B.A. 1903)."
        />
        <p>
          Since we have not (yet) received the data from all international
          museums, we have found a solution to still show all currently known
          works by Vincent van Gogh on the platform in order to provide a
          complete overview of his oeuvre. The artworks of museums for which we
          have not yet received data are published on the platform with the data
          of Jacob Baart de la Faille (The Works of Vincent van Gogh. His
          Paintings and Drawings, Amsterdam 1970). On our platform, these
          artworks are identified by the label "De la Faille 1970 data". We
          explicitly invite the museums to let us replace this data with their
          more actual data.
        </p>
        <p>
          As of October 1, 2023, Van Gogh Worldwide's maintenance department
          will take care of the improvement of the platform and will invite you
          to share any updates. For more information about Van Gogh Worldwide,
          please contact{' '}
          <a href="mailto:info@vangoghworldwide.org">
            info@vangoghworldwide.org.
          </a>
        </p>
      </>
    ),
  },
};

export { NewsItems };
