import React, { useEffect } from 'react';
import useSpinqueData from '../hooks/useSpinqueData';
import { useParams } from 'react-router-dom';
import AuctionHeader from '../components/AuctionHeader';
import SearchUI from '../components/SearchUI';
import { withSearch } from '@elastic/react-search-ui';
import { Helmet } from 'react-helmet/es/Helmet';
import NotFound from './NotFound';
import AuctionTuple from '../utils/Spinque/AuctionTuple';

const Auction = ({ filters, setFilter }) => {
  const id =  encodeURIComponent(useParams().id);

  // It makes complete sense to make the auction route responsible for
  // setting the auction filter. However, when the route calls the setFilter
  // method, that causes a push to the browser history stack so the back button
  // will not behave as expected. Therefore it is preferred to link to an
  // auction page with the filter value already set.
  useEffect(() => {
    // Check if the auction filter is already correctly set.
    const auctionFilter = filters.find(
      filter =>
        filter.field === 'auction' &&
        filter.values[0] === decodeURIComponent(id)
    );
    if (!auctionFilter) {
      setFilter('auction', decodeURIComponent(id));
    }
  }, [filters, setFilter, id]);

  const {
    isLoading,
    isError,
    data: [spinqueData],
  } = useSpinqueData([`/q/auction/p/id/${id}/results`]);

  const auctionJson = spinqueData?.items?.[0]?.tuple?.[0] ?? false;

  if (!isLoading && (isError || !auctionJson)) {
    return <NotFound />;
  }

  const auction = new AuctionTuple(auctionJson);

  const title = auction.title;

  return (
    <>
      <Helmet>
        <title>{title ? `Auction "${title}"` : 'Auction'}</title>
      </Helmet>
      <article>
        <AuctionHeader auction={auction} />
        <SearchUI
          fixedFilters={['auction']}
          fixedFilterLabel={title ?? 'this auction'}
          displaySummary={true}
        />
      </article>
    </>
  );
};

export default withSearch(({ filters, setFilter }) => ({
  filters,
  setFilter,
}))(Auction);
