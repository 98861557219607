import React from 'react';
import ActorProvenance from './ActorProvenance';
import ActorExhibitions from './ActorExhibitions';
import useSpinqueData from '../hooks/useSpinqueData';
import ProvenanceTuple from '../utils/Spinque/ProvenanceTuple';
import ExhibitionTuple from '../utils/Spinque/ExhibitionTuple';
import HorizontalTabs from './HorizontalTabs';

const ActorDetails = ({ actor }) => {
  const {
    data: [actorProvenanceData, actorExhibitionsData],
  } = useSpinqueData([
    {
      path: `/e/actor_provenance/p/id/${encodeURIComponent(actor.id)}/results`,
      params: ['count=500'],
    },
    {
      path: `/e/actor_exhibitions/p/id/${encodeURIComponent(actor.id)}/results`,
      params: ['count=200'],
    },
  ]);

  const actorProvenanceItems = (actorProvenanceData?.items ?? []).map(item => {
    return new ProvenanceTuple(item.tuple[0]);
  });

  const actorExhibitionsItems = (actorExhibitionsData?.items ?? []).map(
    item => {
      return new ExhibitionTuple(item.tuple[0]);
    }
  );

  // Base config to generate other objects and arrays from.
  const tabConfig = [];

  if (actorProvenanceItems.length > 0) {
    tabConfig.push({
      name: 'provenance',
      label: 'Provenance',
      component: ActorProvenance,
      props: { actorProvenanceItems },
    });
  }
  if (actorExhibitionsItems.length > 0) {
    tabConfig.push({
      name: 'exhibitions',
      label: 'Exhibitions',
      component: ActorExhibitions,
      props: { actorExhibitionsItems },
    });
  }

  return tabConfig.length > 0 && <HorizontalTabs tabConfig={tabConfig} />;
};

export default ActorDetails;
