import React, { useState, useEffect, useRef } from 'react';
import ArtworkDetailsValue from './ArtworkDetailsValue';
import styled from 'styled-components';
import { halfGutters } from '../style/variables/sizes';
import { colors } from '../style/variables/colors';
import mediaQueries from '../style/variables/mediaQueries';
import { ReactComponent as ChevronIcon } from '../assets/images/icon-chevron.svg';
import { upperFirst as _upperFirst } from 'lodash';

const StyledTabularContent = styled.table`
  border-bottom: 1px solid ${colors.border};

  .artwork-details__collapsible {
    overflow: hidden;
    transition: all 300ms ease-in;
  }

  th {
    width: 33.3333%;
  }

  .artwork-details__value {
    p {
      margin: 0;
    }

    // Multiple element within a single value get a tiny vertical spacing. All
    // except the first get a slightly smaller font.
    > *:not(:first-child) {
      margin-top: 4px;
      font-size: 14px;
    }

    .boxed {
      padding: 0.5rem 1rem;
      background-color: ${colors.backgroundAlternate};
      border-radius: 2px;
    }

    ul:not(.unstyled-list--inline) {
      > li + li {
        margin-top: ${halfGutters.lg};
      }
    }
  }

  .artwork-details__value-label {
    text-decoration: underline;
    margin-bottom: 4px;
  }
`;

const TabularContent = ({ tabularContent, id, headingId }) => {
  return (
    <StyledTabularContent id={id} aria-describedby={headingId}>
      <tbody>
        {tabularContent.map((data, i) => (
          <tr key={i}>
            <th className="artwork-details__property">
              {data.label !== null ? _upperFirst(data.label) : ''}
            </th>
            <td className="artwork-details__value">
              {ArtworkDetailsValue(data)}
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTabularContent>
  );
};

const StyledListContent = styled.ul`
  padding: 1.5rem 0;
  border-bottom: 1px solid ${colors.border};

  &.list-content__list--with-separator {
    .list-content__item + .list-content__item {
      border-top: 1px solid ${colors.border};
      padding-top: 1.5rem;
    }
  }

  h3 {
    // Style like th elements.
    font-size: 1rem;
    margin-bottom: 0;
  }

  .list-content__item {
    display: flex;
    margin: 0;
    + .list-content__item {
      margin-top: 1.5rem;
    }

    > .textual {
      // Multiple element within a single value get a tiny vertical spacing. All
      // except the first get a slightly smaller font.
      > *:not(:first-child) {
        margin-top: 4px;
        font-size: 14px;
      }
    }

    > .visual {
      order: -1;
      width: 33.3333%;
      min-width: 80px;
      padding-right: 1rem;
      max-height: 180px;
      flex-shrink: 0;

      ${mediaQueries.md} {
        padding-right: 1.5rem;
      }

      img {
        max-height: 100%;
      }
    }
  }
`;

const ListContent = ({ listContent, headingId, className = '' }) => {
  return (
    // StyledListContent expects that each item is rendered with a .textual div
    // and optionally a .visual div after it.
    <StyledListContent
      aria-describedby={headingId}
      className={`unstyled-list list-content__list ${className}`}
    >
      {listContent.map((data, index) => (
        <li className="list-content__item" key={index}>
          <ArtworkDetailsValue {...data} />
        </li>
      ))}
    </StyledListContent>
  );
};

const StyledTextualContent = styled.div`
  border-bottom: 1px solid ${colors.border};
  padding: 2rem 0;

  p {
    margin: 0;
    + p {
      margin-top: 1rem;
    }
  }
`;

const TextualContent = ({ textualContent }) => {
  // Do not render anything if the value is empty.
  return (
    textualContent.value && (
      <StyledTextualContent className="textual-content">
        {/* Textual content is always a follow-up, hence the h3. */}
        <h3>{textualContent.label}</h3>
        <p>{textualContent.value}</p>
      </StyledTextualContent>
    )
  );
};

const StyledArtworkDetails = styled.div`
  .artwork-details__heading {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cccccc;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }

  .artwork-details__heading-content {
    flex-grow: 1;
    ${mediaQueries.sm} {
      display: flex;
      align-items: center;
    }
  }

  .artwork-details__title {
    flex: 1 0 auto;
    margin: 0;
    color: ${colors.inkAlternate};
  }

  .artwork-details__source {
    text-align: right;
    font-style: italic;
    font-size: 14px;
    color: ${colors.ink};
  }

  .artwork-details__collapse-toggler {
    height: 2rem;
    min-width: 2rem;
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      transition: transform 150ms ease;
    }
  }
  &.closed .artwork-details__collapse-toggler {
    svg {
      transform: rotate(180deg);
    }
  }

  .artwork-details__collapsible {
    overflow: hidden;
    transition: height 150ms ease;
  }

  &.is-collapsible--always {
    .artwork-details__collapsible {
      height: 0;
    }
  }

  &.is-collapsible--max-lg {
    .artwork-details__collapsible {
      ${mediaQueries.max_lg} {
        height: 0;
      }
    }
    .artwork-details__collapse-toggler {
      ${mediaQueries.lg} {
        display: none;
      }
    }
  }
`;

/**
 * @param data
 * @param className
 * @param id
 */
const ArtworkDetails = ({ data, id }) => {
  const isCollapsible =
    typeof data.collapsible !== 'undefined' && data.collapsible !== null;
  const [collapsibleOpen, setCollapsibleOpen] = useState(!isCollapsible);
  const collapsibleWrapper = useRef(null);
  const collapsibleContent = useRef(null);

  // This function will be called when the component is initialized and when the
  // collapsibleOpen value is set using setCollapsibleOpen().
  useEffect(() => {
    if (collapsibleOpen) {
      // Get the height of the collapsible content and override the wrapper
      // height to expand it.
      const height = collapsibleContent.current.getBoundingClientRect().height;
      collapsibleWrapper.current.style.height = `${height}px`;
    } else {
      // Just remove the overridden height to make it fall back on the (possibly
      // responsive) default CSS value.
      collapsibleWrapper.current.style.height = null;
    }
  });

  const toggleCollapsible = e => {
    // Set the collapsibleOpen state and let useEffect handle the rest.
    setCollapsibleOpen(!collapsibleOpen);
    // Blur on mouseclick (not keyboard!) to hide focus outline.
    if (e.clientX !== 0 || e.clientY !== 0) {
      e.currentTarget.blur();
    }
  };

  const headingId = id + '--heading';

  return (
    <StyledArtworkDetails
      id={id}
      className={[
        isCollapsible
          ? `is-collapsible is-collapsible--${data.collapsible}`
          : null,
        isCollapsible ? (collapsibleOpen ? 'open' : 'closed') : null,
      ]}
    >
      <div id={headingId} className="artwork-details__heading">
        <div className="artwork-details__heading-content">
          <h2 className="artwork-details__title">{data.heading.title}</h2>
          <span className="artwork-details__source">{data.heading.source}</span>
        </div>
        {isCollapsible && (
          <button
            className="artwork-details__collapse-toggler button--white button--border"
            onClick={toggleCollapsible}
            aria-label={
              collapsibleOpen
                ? `collapse ${data.heading.title}`
                : `expand ${data.heading.title}`
            }
          >
            <ChevronIcon aria-hidden="true" />
          </button>
        )}
      </div>

      <div ref={collapsibleWrapper} className="artwork-details__collapsible">
        <div ref={collapsibleContent}>
          {data.tabularContent && (
            <TabularContent
              tabularContent={data.tabularContent}
              headingId={headingId}
            />
          )}
          {data.listContent && (
            <ListContent
              listContent={data.listContent}
              headingId={headingId}
              className={data?.listClass}
            />
          )}
          {data.textualContent && (
            <TextualContent textualContent={data.textualContent} />
          )}
        </div>
      </div>
    </StyledArtworkDetails>
  );
};

export default ArtworkDetails;
