import { useLocation } from 'react-router-dom';
import useIsOnMainSearchPage from './useIsOnMainSearchPage';

/**
 * Hook to determine whether the component is rendered on a search page. This
 * can be /search or any other page that provides search results (eg. an
 * exhibition page).
 *
 * Checks in this function are hard-coded. The code must be updated when
 * additional search-containing pages are added.
 *
 * @returns {boolean}
 *   TRUE if on any search page, FALSE otherwise.
 */
const useIsOnAnySearchPage = () => {
  const { pathname } = useLocation();
  const isOnMainSearchPage = useIsOnMainSearchPage();

  // The exhibition and auction pages have an embedded search component.
  return (
    isOnMainSearchPage ||
    pathname.startsWith('/exhibition/') ||
    pathname.startsWith('/auction/')
  );
};

export default useIsOnAnySearchPage;
