/**
 * Utility class to provide easy access of Letter data.
 */
class LetterTuple {
  /**
   * Constructs a Tuple object which can be used to query the data inside.
   *
   * @param {object} json
   */
  constructor(json) {
    this.json = json;
  }

  get id() {
    return this.json?.id ?? null;
  }

  get letterId() {
    return (
      this.json?.attributes?.target?.[0]?.['http://schema.org/identifier'] ??
      null
    );
  }

  get name() {
    return this.json?.attributes?.target?.[0]?.['http://schema.org/name'] ?? '';
  }

  get url() {
    return this.json?.attributes?.['http://schema.org/url'] ?? '';
  }

  get dateSent() {
    return this.json?.attributes?.target?.[0]?.['http://schema.org/dateSent'];
  }

  get locationCreated() {
    return (
      this.json?.attributes?.target?.[0]?.['locationCreated']?.[0]?.[
        'http://schema.org/name'
      ] ?? ''
    );
  }

  get recipient() {
    return (
      this.json?.attributes?.target?.[0]?.['recipient']?.[0]?.[
        'http://schema.org/name'
      ] ?? ''
    );
  }

  get sender() {
    return (
      this.json?.attributes?.target?.[0]?.['sender']?.[0]?.[
        'http://schema.org/name'
      ] ?? ''
    );
  }
}

export default LetterTuple;
