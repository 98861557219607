export default function getRenderedRepresentation(representation) {
  const renderVariables = {};

  if (representation.isIiif) {
    // Create a link to an appropriately formatted image based on sizing for the artwork detail page.
    // This is a temporary measure until IIIF has been properly implemented.
    const maxHeight = 440;
    const maxWidth = 682;
    // The highDensityFactor is chosen to make sure the resulting image
    // is never larger than 640000 pixels, a hard limit imposed by the
    // IIIF server.
    const highDensityFactor = 1.46;
    const maxHeightHighDensity = Math.floor(maxHeight * highDensityFactor);
    const maxWidthHighDensity = Math.floor(maxWidth * highDensityFactor);

    renderVariables.uri = `${representation.uri}/full/!${maxWidth},${maxHeight}/0/default.jpg`;
    renderVariables.srcset = [
      renderVariables.uri,
      `${representation.uri}/full/!${maxWidthHighDensity},${maxHeightHighDensity}/0/default.jpg ${highDensityFactor}x`,
    ];

    const thumbHeight = 80;
    const thumbHighDensityFactor = 2;
    const thumbHeightHighDensity = thumbHeight * thumbHighDensityFactor;
    renderVariables.thumbUri = `${representation.uri}/full/,${thumbHeight}/0/default.jpg`;
    renderVariables.thumbSrcset = [
      renderVariables.thumbUri,
      `${representation.uri}/full/,${thumbHeightHighDensity}/0/default.jpg ${thumbHighDensityFactor}x`,
    ];
  } else {
    renderVariables.uri = representation.uri;
    renderVariables.srcset = [renderVariables.uri];
    renderVariables.thumbUri = representation.uri;
    renderVariables.thumbSrcset = [renderVariables.thumbUri];
  }

  renderVariables.alt = representation.description;
  return renderVariables;
}
