import styled from 'styled-components';

const StyledContent = styled.figure`
  img {
    width: 100%;
  }

  figcaption {
    font-size: 0.875rem;
    font-weight: 300;
    margin-top: 10px;
  }
`;

function Figure({ src, alt, caption }) {
  return (
    <StyledContent>
      <img src={src} alt={alt} />
      {caption && (
        <figcaption>{caption}</figcaption>
      )}
    </StyledContent>
  );
}

export default Figure;
