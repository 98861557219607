import React from 'react';
import BasicPage from '../components/BasicPage';

const Contact = () => {
  return (
    <BasicPage title="Contact">
      {/* ToDo: make the mailto email link spam-proof */}
      <p>
        For information about Van Gogh Worldwide, please contact{' '}
        <a href="mailto:info@vangoghworldwide.org">
          info@vangoghworldwide.org
        </a>
      </p>
    </BasicPage>
  );
};

export default Contact;
