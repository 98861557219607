/**
 * Converts date from format YYYY-MM-DD into DD Month YYYY.
 * Returns null if undefined.
 * @param value
 * @returns {string|null}
 */
export default function getFullDate(value) {
  if (typeof value !== 'undefined') {
    return new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }).format(new Date(value));
  }
  return null;
}
