import React from 'react';

/**
 * Gets a printable dimensions string.
 * @param {{}} dimensions
 *   An dimension object in the form {width: {value, unit}, height: {value, unit}}.
 *
 * @return {string}
 */
function ArtworkDimensions(dimensions) {
  if (dimensions.width.value && dimensions.height.value) {
    // Include both units if the next unit is not the same.
    if (dimensions.width.unitID !== dimensions.height.unitID) {
      return (
        <>
          {dimensions.height.value} {dimensions.height.unit}
          <span aria-label="by">×</span>
          {dimensions.width.value} {dimensions.width.unit}{' '}
        </>
      );
    } else {
      return (
        <>
          {dimensions.height.value} <span aria-label="by">×</span>{' '}
          {dimensions.width.value} {dimensions.width.unit}
        </>
      );
    }
  }

  if (dimensions.width.value) {
    return (
      <>
        {dimensions.width.value} {dimensions.width.unit}
      </>
    );
  }

  if (dimensions.height.value) {
    return (
      <>
        {dimensions.height.value} {dimensions.height.unit}
      </>
    );
  }

  return <i>[No dimension]</i>;
}

export default ArtworkDimensions;
