export default function adaptRequest(request, queryConfig, renewFacets = true) {
  // What the Spinque API documentation calls "parameters" are not technically
  // implemented as URL parameters, but as elements of the URL path in the form
  // of "/p/[name]/[value]". Let's call these name-value pairs "args" for lack
  // of a better word.
  // See also  https://docs.spinque.com/2.5/rest/basic.html.
  let queryArgs = {};

  if (request.searchTerm) {
    queryArgs.q = request.searchTerm;
  }

  // Construct the argument part of the path.
  let argsString = '';
  if (Object.keys(queryArgs).length) {
    argsString = Object.keys(queryArgs)
      .map(key => `/p/${encodeURI(key)}/${encodeURI(queryArgs[key])}`)
      .join('');
  }

  // Gather additional URL query parameters.
  let queryParams = {};
  if (process.env.REACT_APP_SPINQUE_API_ENVIRONMENT) {
    queryParams.config = process.env.REACT_APP_SPINQUE_API_ENVIRONMENT;
  }

  // Add paging query parameters.
  if (request.resultsPerPage) {
    queryParams.count = request.resultsPerPage;
    if (request.current > 1) {
      queryParams.offset = (request.current - 1) * request.resultsPerPage;
    }
  }

  // Construct the query string.
  let queryString = '';
  if (Object.keys(queryParams).length) {
    queryString =
      '?' +
      Object.keys(queryParams)
        .map(key => encodeURI(key) + '=' + encodeURI(queryParams[key]))
        .join('&');
  }

  // Prepare filters.
  const filters = {};

  if (request.filters.length) {
    request.filters.forEach(filter => {
      const field = filter.field;
      const values = filter.values;
      const tuple = values
        .map(value => `1.0(${encodeURIComponent(value)})`)
        .join('%7C');
      filters[field] = `/q/${encodeURIComponent(field)}%3AFILTER/p/value/${tuple}`;
    });
  }

  // Constructs the query sort part.
  let sortPart = '';
  if (request.sortField && request.sortDirection) {
    sortPart = `/q/${encodeURIComponent(request.sortField)}_${encodeURIComponent(request.sortDirection)}`;
  }

  // Constructs the query filter part.
  let filterPart = '';
  // Add all prepared filters to the filterpart for results and statistics query.
  if (request.filters.length) {
    filterPart = Object.keys(filters)
      .map(field => filters[field])
      .join('');
  }

  let paths = [];

  // Build the results query.
  paths.push({
    type: 'results',
    path: `${argsString}${filterPart}${sortPart}/results${queryString}`,
  });

  // Build the statistics query, this won't need the sortPart.
  paths.push({
    type: 'statistics',
    path: `${argsString}${filterPart}/statistics${queryString}`,
  });

  if (renewFacets) {
    // Query for facet options.
    // TODO: Move to config.
    const facets = [
      'creator',
      'support',
      'period',
      'owner',
      'type',
      'subject',
      'material',
      'technique',
      'research_type',
      'owner_country',
    ];

    queryString = '';
    // Request a large number of facet values.
    queryParams = {
      count: 200,
    };

    if (process.env.REACT_APP_SPINQUE_API_ENVIRONMENT) {
      queryParams.config = process.env.REACT_APP_SPINQUE_API_ENVIRONMENT;
    }
    if (Object.keys(queryParams).length) {
      queryString =
        '?' +
        Object.keys(queryParams)
          .map(key => encodeURI(key) + '=' + encodeURI(queryParams[key]))
          .join('&');
    }

    // Add filters to the facet queries. Omit filters for a particular field
    // when requesting that field. This ensures other options to AND with work.
    const facetPaths = facets.map(facet => {
      let filterPart = '';
      if (request.filters.length) {
        filterPart = Object.keys(filters)
          .filter(field => field !== facet)
          .map(field => filters[field])
          .join('');
      }
      return {
        type: facet,
        path: `${argsString}${filterPart}/q/${encodeURIComponent(facet)}/results${queryString}`,
      };
    });

    paths = [...paths, ...facetPaths];
  }

  return paths;
}
