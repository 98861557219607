import { useContext, useEffect, useState } from 'react';
import SpinqueEndpointContext from '../contexts/SpinqueEndpointContext';

/**
 * Fetches data from a spinque query.
 *
 * Queries are passed as paths to the configured Spinque API endpoint from
 * context.
 *
 * @param {(string[]|{path: string, params: string[]}[])} queries
 *   An array of Spinque queries. Each query is either a string (the query path)
 *   or an object containing the path and an array of query parameters in the
 *   form of 'key=value'.
 * @param {Object[]} [initialData]
 *   Initial state of the data objects. When omitted defaults to an array of
 *   empty objects, one for each query passed in the queries param.
 *
 * @returns {{isLoading: boolean, isError: boolean, data: Object[]}}
 *   Object holding loading and error state and data, an array of response data
 *   from state.
 */
const useSpinqueData = (queries, initialData) => {
  const [data, setData] = useState(
    initialData ?? Array(queries.length).fill({})
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { uri, environment } = useContext(SpinqueEndpointContext);
  const queriesJsonString = JSON.stringify(queries);

  useEffect(() => {
    let isActive = true;
    const fetchData = async () => {
      isActive && setIsLoading(true);
      isActive && setIsError(false);
      try {
        const responses = await Promise.all(
          queries.map(query => {
            if (typeof query === 'object' && query !== null) {
              const path = query.path ?? '';
              const params = Array.isArray(query.params)
                ? '&' + query.params.join('&')
                : '';
              return fetchResponseJson(
                `${uri}${path}?config=${environment}${params}`
              );
            } else {
              return fetchResponseJson(`${uri}${query}?config=${environment}`);
            }
          })
        );

        isActive && setData(responses);
      } catch (error) {
        isActive && setData(initialData);
        isActive && setIsError(true);
      }
      isActive && setIsLoading(false);
    };
    // Deliberately ignore the promise. Its setData side-effect is the goal.
    fetchData();
    // Update flag to prevent async actions from settings state on an unmounted
    // component and delayed requests from overwriting later requests.
    return () => {
      isActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri, environment, queriesJsonString]);
  return { data, isLoading, isError };
};

export default useSpinqueData;

/**
 * Fetches json from a url.
 *
 * Helper for useSpinqueData.
 *
 * @param {string} uri
 *   Uri to fetch.
 *
 * @returns {Promise<any>}
 */
async function fetchResponseJson(uri) {
  const result = await fetch(uri);
  return await result.json();
}
