/**
 * Gets the string before the first '-' char.
 * Used for year labels from yyy-mm-dd or yyyy-yyyy
 *
 * @param value
 * @returns {string}
 */
export default function getYearFromDate(value) {
  if (value !== null && typeof value === 'string') {
    const index = value.indexOf('-');
    if (index > -1) {
      return value.substring(0, index);
    }
  }
  return value;
}
