import React from 'react';
import logoRkd from '../assets/images/founders/logo-founder-rkd.svg';
import logoVanGoghMuseum from '../assets/images/founders/logo-founder-van-gogh-museum.png';
import logoKroellerMueller from '../assets/images/founders/logo-founder-kroeller-mueller.png';
import logoRkd2x from '../assets/images/founders/logo-founder-rkd-2x.svg';
import logoVanGoghMuseum2x from '../assets/images/founders/logo-founder-van-gogh-museum-2x.png';
import logoKroellerMueller2x from '../assets/images/founders/logo-founder-kroeller-mueller-2x.png';
import styled from 'styled-components';
import { colors } from '../style/variables/colors';
import { halfGutters } from '../style/variables/sizes';
import mediaQueries from '../style/variables/mediaQueries';
import { Picture } from 'react-responsive-picture';

const StyledFoundingPartners = styled.div`
  h2 {
    margin-bottom: 2rem;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0 -${halfGutters.md};

    ${mediaQueries.lg} {
      flex-direction: row;
    }
  }
  li {
    padding: 0 ${halfGutters.md} ${halfGutters.md};

    ${mediaQueries.lg} {
      flex: 0 0 33.3333%;
      padding: 0 ${halfGutters.md};
    }
  }
`;

/**
 * Displays the FoundingPartners block.
 */
export default function FoundingPartners() {
  const foundingPartners = [
    {
      name: 'RKD — Netherlands Institute for Art History',
      location: 'Den Haag',
      url: 'https://rkd.nl/',
      image: {
        path: logoRkd,
        path2x: logoRkd2x,
        width: 72,
        height: 102,
      },
    },
    {
      name: 'Van Gogh Museum',
      location: 'Amsterdam',
      url: 'https://vangoghmuseum.nl/',
      image: {
        path: logoVanGoghMuseum,
        path2x: logoVanGoghMuseum2x,
        width: 72,
        height: 72,
      },
    },
    {
      name: 'Kröller-Müller Museum',
      location: 'Otterlo',
      url: 'https://krollermuller.nl/',
      image: {
        path: logoKroellerMueller,
        path2x: logoKroellerMueller2x,
        width: 72,
        height: 106,
      },
    },
  ];

  return (
    <StyledFoundingPartners>
      <h2 className="h3">Founding partners</h2>
      <ul className="unstyled-list">
        {foundingPartners.map((partner, i) => (
          <li key={i}>
            <FoundingPartner
              name={partner.name}
              location={partner.location}
              url={partner.url}
              image={partner.image}
            />
          </li>
        ))}
      </ul>
    </StyledFoundingPartners>
  );
}

const StyledFoundingPartner = styled.a`
  height: 100%;
  background-color: ${colors.background};
  border: 1px solid ${colors.border};
  display: flex;
  padding: ${halfGutters.lg} 2rem;
  text-decoration: none;
  flex-direction: column;
  min-width: 190px;

  &:hover {
    text-decoration: underline;
  }

  ${mediaQueries.md_lg} {
    flex-direction: row;
    align-items: center;
  }

  ${mediaQueries.xl} {
    flex-direction: row;
    align-items: center;
  }

  .founding-partner__name {
    margin: 0;
  }
  .founding-partner__location {
    font-size: 14px;
  }
  .founding-partner__visual {
    order: -1;
    margin: 0 0 1rem;
    flex-shrink: 0;
    ${mediaQueries.md_lg} {
      margin: 0 1rem 0 0;
    }
    ${mediaQueries.xl} {
      margin: 0 1rem 0 0;
    }
  }
`;

/**
 * Displays one founding partner.
 *
 * @param {string} name
 *   Name of the founding partner.
 * @param {string} location
 *   City, location of the founding partner.
 * @param {string} url
 *   URL of the founding partner.
 * @param {object} image
 *   Image object with {path, width, height}.
 *
 * @returns {*}
 */
function FoundingPartner({ name, location, url, image }) {
  return (
    <StyledFoundingPartner
      className="founding-partner"
      rel="noopener noreferrer"
      href={url}
      target="_blank"
    >
      <div className="founding-partner__textual">
        <h3 className="founding-partner__name h4">{name}</h3>
        <span className="founding-partner__location">{location}</span>
      </div>
      <div className="founding-partner__visual">
        <Picture
          src={`${image.path}, ${image.path2x} 2x`}
          width={image.width}
          height={image.height}
          alt={`Logo of ${name}`}
        />
      </div>
    </StyledFoundingPartner>
  );
}
