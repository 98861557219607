/**
 * Construct a IIIF base URL on the fallback IIIF server if the provided image
 * id is not a valid IIIF base url.
 *
 * @param item
 * @returns {string|null|*}
 */
export default function getIIIFRepresentationUri(item) {
  const id = item.representation?.[0]?.['@id'] || item.id;

  if (!id) return null;

  // Attribute conforms_to can have multiple locations that is
  // caused by differences in the item's (representation)
  // jmespath query.
  const conforms_to =
    item?.conforms_to?.[0]?.['@id'] ||
    item?.representation?.[0]?.conforms_to?.[0]?.['@id'];

  if (conforms_to !== 'http://iiif.io/api/image') {
    return `${process.env.REACT_APP_IIIF_FALLBACK_BASE_URI}${encodeURIComponent(
      id
    )}`;
  } else {
    return id;
  }
}
