import { search as jmespath } from 'jmespath';
import { getPreferredValue } from './linkedArtHelpers';
import translateIri from './translateIri';
import splitAndSwapName from '../Display/splitAndSwapName';
import getPeriodFromTimespan from './Period';
import getIIIFRepresentationUri from '../Display/ArtworkDetails/getIIIFRepresentationUri.js';

/**
 * Utility class to provide easy access of Tuple contents.
 *
 * @todo Decide if we want to use the delete-create caching pattern for getters
 *   (see "Smart / self-overwriting / lazy getters" at
 *   https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/get#Examples).
 */
class ArtworkTuple {
  /**
   * Constructs a Tuple object which can be used to query the data inside.
   *
   * @param {object} json
   */
  constructor(json) {
    // @todo Validate incoming data.
    this.json = json;

    this.linkedArt = json.attributes;
    this.hasOwner = false;
    this.isDlf70 = false;

    if (this.linkedArt.hasOwnProperty('current_owner')) {
      this.hasOwner = true;
    }

    if (
      this.linkedArt.hasOwnProperty('dataset') &&
      this.linkedArt.dataset['@id'] ===
        'https://vangoghworldwide.org/data/dataset/delafaille'
    ) {
      this.isDlf70 = true;
    }
  }

  /**
   * Gets the Artwork ID.
   *
   * @returns {string} The ID.
   */
  get id() {
    let id = '';
    const artworkUri = this.artworkUri;
    const lastSeparatorIndex = artworkUri.lastIndexOf('/');
    if (lastSeparatorIndex > -1) {
      id = artworkUri.substring(lastSeparatorIndex + 1);
    }
    return id;
  }

  /**
   * Gets the internal Artwork path to the route defined in src/index.js.
   *
   * @returns {string} The path.
   */
  get path() {
    return `/artwork/${encodeURIComponent(this.id)}`;
  }

  /**
   * Gets the artwork identifier uri.
   *
   * @returns {string} The uri identifying the artwork.
   */
  get artworkUri() {
    let artworkUri = '';
    if (this.isDlf70) {
      artworkUri = this.json.id;
    } else {
      artworkUri = this.linkedArt.sameAs[0]['@id'];
    }
    return artworkUri;
  }

  /**
   * Determines whether the artwork is a DLF70 dataset.
   *
   * @returns {boolean}
   *   TRUE if the dataset is DLF70, FALSE otherwise.
   */
  get isDlfDataset() {
    return this.isDlf70;
  }

  /**
   * Gets the Tuple title in the most preferred available language.
   *
   * @returns {string} The title.
   */
  get title() {
    let title = '';
    const jmesPathQuery =
      'identified_by[?type == `"Name"`].{title:content, language:language[0]."@id", language_label:language[0]._label, classified_label:classified_as[0]._label}';
    const nameIdentifiers = jmespath(this.linkedArt, jmesPathQuery) || [];
    const languageOrder = [
      // English.
      'http://vocab.getty.edu/aat/300388277',
      // Dutch.
      'http://vocab.getty.edu/aat/300388256',
    ];

    for (let i = 0; i < languageOrder.length; i++) {
      const languageId = languageOrder[i];

      // Try to find the preferred title first.
      const foundPreferred = nameIdentifiers.find(item => {
        return item.language === languageId && item.classified_label === 'preferred terms';
      });

      if (foundPreferred) {
        title = foundPreferred.title;
        break;
      }

      // Fallback to original title finder if no preferred is present.
      const found = nameIdentifiers.find(item => {
        return item.language === languageId;
      });

      if (found) {
        title = found.title;
        break;
      }
    }

    if (!title) {
      return this.legacyTitle;
    }
    return title;
  }

  /**
   * Gets other titles that differ from the canonical title.
   *
   * @return [{*}]
   */
  get otherTitles() {
    const jmesPathQuery =
      'identified_by[?type == `"Name"`].{title:content, language:language[0]."@id", language_label:language[0]._label}';
    const nameIdentifiers = jmespath(this.linkedArt, jmesPathQuery) || [];
    const title = this.title;
    return nameIdentifiers.filter(item => item.title !== title);
  }

  /**
   * Gets the title via the legacy way.
   *
   * @returns {string} The title.
   */
  get legacyTitle() {
    const jmesPathQuery = 'identified_by[?type == `"Name"`]';
    const nameIdentifiers = jmespath(this.linkedArt, jmesPathQuery) || [];
    return getPreferredValue(nameIdentifiers);
  }

  /**
   * Gets the Tuple title in the most preferred available language.
   *
   * @returns {string} The title.
   */
  get objectCategory() {
    let category = '';
    const categories = jmespath(this.linkedArt, 'classified_as') || [];
    if (categories.length > 0) {
      category = getPreferredValue(categories[0]);
    }
    return category;
  }

  /**
   * Gets the F and JH identifiers.
   *
   * @returns {{f: string, jh: string}}
   */
  get identifiers() {
    const rawIdentifiers =
      jmespath(
        this.linkedArt,
        'identified_by[?type == `"Identifier"`].{value: content, type: classified_as[]."@id"}'
      ) || [];

    const identifiers = { f: '', jh: '' };

    rawIdentifiers.forEach(rawIdentifier => {
      if (Array.isArray(rawIdentifier.type)) {
        if (
          rawIdentifier.type.indexOf(
            'https://vangoghworldwide.org/data/concept/f_number'
          ) > -1
        ) {
          identifiers.f = rawIdentifier.value;
        } else if (
          rawIdentifier.type.indexOf(
            'https://vangoghworldwide.org/data/concept/jh_number'
          ) > -1
        ) {
          identifiers.jh = rawIdentifier.value;
        }
      }
    });

    return identifiers;
  }

  /**
   * Gets the Inventory Identifier used by the current owner.
   *
   * @returns {string}
   */
  get ownerInventoryId() {
    let ownerInventoryId = '';
    // The id is only available in the owner dataset.
    if (!this.isDlf70) {
      ownerInventoryId =
        jmespath(
          this.linkedArt,
          'identified_by[?type == `"Identifier"`] | [?classified_as[?"@id" == `"http://vocab.getty.edu/aat/300312355"`]].content | [0]'
        ) || '';
    }

    return ownerInventoryId;
  }

  /**
   * Gets the artworks owner.
   */
  get owner() {
    const rawOwner = jmespath(this.linkedArt, 'current_owner[]') || [];

    let owner = { id: '', label: '' };

    if (rawOwner.length > 0) {
      owner.id = rawOwner[0]['@id'];
      owner.label = getPreferredValue(rawOwner[0]);
      if (!owner.label) {
        owner.label = owner.id;
      }
    }
    return owner;
  }

  /**
   * Gets the artwork creator.
   * @returns {{id: string, label: string}}
   */
  get creator() {
    const rawCreator =
      jmespath(this.linkedArt, 'produced_by.carried_out_by[]') || [];

    const creator = { id: '', label: '' };
    if (rawCreator.length > 0) {
      creator.id = rawCreator[0].id;
      const name = splitAndSwapName(getPreferredValue(rawCreator[0]));
      if (name) {
        creator.label = name;
      } else {
        creator.label = creator.id;
      }
    } else {
      const previousAttributions = this.previousAttributions;
      if (previousAttributions.length) {
        creator.label = previousAttributions[0];
        creator.id = '';
      }
      const questionableAttributions = this.questionableAttributions;
      if (questionableAttributions.length) {
        creator.label = questionableAttributions[0];
        creator.id = '';
      }
    }

    return creator;
  }

  /**
   * Gets previous attributions.
   *
   * For now, only one line is returned.
   *
   * @returns {string[]}
   */
  get previousAttributions() {
    const previousAttribution =
      jmespath(
        this.linkedArt,
        'produced_by.assigned_by[0].classified_as[?"@id" == `"https://vangoghworldwide.org/data/concept/previous_attribution"`] | [0]._label'
      ) || '';
    if (previousAttribution) {
      return [previousAttribution];
    }
    return [];
  }

  /**
   * Gets questionable attributions.
   *
   * For now, only one line is returned.
   *
   * @returns {string[]}
   */
  get questionableAttributions() {
    const questionableAttribution =
      jmespath(
        this.linkedArt,
        'produced_by.assigned_by[0].classified_as[?"@id" == `"https://vangoghworldwide.org/data/concept/questionable_attribution"`] | [0]._label'
      ) || '';
    if (questionableAttribution) {
      return [questionableAttribution];
    }
    return [];
  }

  /**
   * Gets the named creative period.
   *
   * @see Period.js
   *
   * @returns {string}
   *   The period label or empty string if not found.
   */
  get period() {
    let period = null;
    const productionDates = this.productionDates;
    if (productionDates.length > 0) {
      period = getPeriodFromTimespan(
        productionDates[0].start,
        productionDates[0].end
      );
    }
    return period ? period.label : '';
  }

  /**
   * Gets the production dates.
   *
   * @returns {{start, end, textual: [string]}[]|[]}
   *   An array of timespans.
   */
  get productionDates() {
    return (
      jmespath(
        this.linkedArt,
        'produced_by.timespan[].{start: begin_of_the_begin, end: end_of_the_end, textual: identified_by[?type == `"Name"`] | [::-1].content }'
      ) || []
    );
  }

  /**
   * Gets the location where the artwork was made.
   *
   * @returns {string}
   *   Location.
   */
  get location() {
    const rawLocation =
      jmespath(this.linkedArt, 'produced_by.took_place_at[0]') || '';
    return getPreferredValue(rawLocation);
  }

  /**
   * Gets the material to create the work.
   *
   * eg "oil paint" or "black chalk".
   *
   * @returns {string[]}
   *   An array of materials.
   */
  get material() {
    const rawMaterials = jmespath(this.linkedArt, 'made_of[]') || [];
    return rawMaterials.map(rawMaterial => getPreferredValue(rawMaterial));
  }

  /**
   * Gets the materials and technique description for the work.
   *
   * Eg. "oil paint on canvas".
   *
   * @returns {string}
   *   A description.
   */
  get materialsTechniqueDescription() {
    const expr = 'referred_to_by[]';
    const data = jmespath(this.linkedArt, expr) || [];
    for (const item of data) {
      if (
        item?.classified_as?.[0]?.['@id'] ===
        'http://vocab.getty.edu/aat/300435429'
      ) {
        return getPreferredValue(item);
      }
    }
    return '';
  }

  /**
   * Gets the materials of the support.
   *
   * eg "canvas" or "panel".
   *
   * @returns {string[]}
   *   An array of supports.
   */
  get support() {
    const rawSupports =
      jmespath(
        this.linkedArt,
        'part | [?classified_as[?"@id" == `"http://vocab.getty.edu/aat/300014844"`]].made_of[]'
      ) || [];

    return rawSupports.map(rawSupport => {
      return getPreferredValue(rawSupport);
    });
  }

  /**
   * Gets techniques used to create the work.
   *
   * eg "painting" or "drawing".
   *
   * @returns {string[]}
   *   An array of techniques.
   */
  get technique() {
    const rawTechniques =
      jmespath(this.linkedArt, 'produced_by.technique[]') || [];
    return rawTechniques.map(rawTechnique => {
      return getPreferredValue(rawTechnique);
    });
  }

  /**
   * Gets available object dimensions.
   *
   * @returns {{width: {unit: string, value: string}, height: {unit: string, value: string}}}
   */
  get dimensions() {
    const rawDimensions =
      jmespath(
        this.linkedArt,
        'dimension[].{value: value, dimension: classified_as[0]."@id", unit: unit._label, id: unit."@id"}'
      ) || [];
    const dimensions = {
      width: { value: '', unit: '', unitID: '' },
      height: { value: '', unit: '', unitID: '' },
    };
    rawDimensions.forEach(rawDimension => {
      const translatedDimension = translateIri(rawDimension.dimension);
      if (translatedDimension) {
        dimensions[translatedDimension] = {
          value: rawDimension.value,
          unit: rawDimension.unit,
          unitID: rawDimension.unitID,
        };
      }
    });

    return dimensions;
  }

  get representation() {
    const representations = this.representations;
    return representations.length ? representations[0] : { uri: '', alt: '' };
  }

  get representations() {
    const rawRepresentations =
      jmespath(
        this.linkedArt,
        'representation[].{id: "@id", types: classified_as[]."@id", type_labels: classified_as[]._label, conforms_to: conforms_to, uri: ""}'
      ) || [];

    // Filter images on type.
    const filterType = 'http://vocab.getty.edu/aat/300215302';
    const filteredRepresentations = rawRepresentations.filter(
      rawRepresentation => rawRepresentation.types?.indexOf(filterType) > -1
    );
    return filteredRepresentations.length
      ? filteredRepresentations.map(representation => {
          const uri = getIIIFRepresentationUri(representation);

          // Filter out the most generic type label.
          const typeLabels = representation.type_labels.filter(
            label => label !== 'digital images'
          );

          let description = '';
          if (typeLabels.length) {
            const types = typeLabels.join(', ');
            description = `Photograph (${types}) of the artwork '${this.title}'`;
          } else {
            description = `Photograph of the artwork '${this.title}'`;
          }
          return {
            uri,
            label: typeLabels.length ? typeLabels[0] : 'digital image',
            description,
            isIiif: true,
          };
        })
      : [];
  }

  /**
   * Gets an attribution line for the dataset.
   *
   * @returns {string}
   */
  get sourceAttribution() {
    if (this.isDlf70) {
      return 'RKD - Nederlands Instituut voor Kunstgeschiedenis';
    }

    return this.owner.label;
  }

  /**
   * Gets a credit line for the dataset.
   *
   * @returns {string}
   */
  get creditLine() {
    // The kind of processing we need to do is very hard (if not impossible)
    // with jmespath, so we use a very simple query and loop over it ourselves.
    const expr = 'referred_to_by[]';
    const data = jmespath(this.linkedArt, expr) || [];
    const classificationIds = [
      // Acknowledgments.
      'http://vocab.getty.edu/aat/300026687',
      // Credit line.
      'http://vocab.getty.edu/aat/300435418',
    ];
    let content = [];
    for (const item of data) {
      if (
        item?.content &&
        classificationIds.includes(item?.classified_as?.[0]?.['@id'])
      ) {
        content.push(item.content);
      }
    }
    return content !== [] ? content : '';
  }

  /**
   * Gets the provenance description for the work.
   *
   * @returns {string}
   *   A description.
   */
  get provenanceDescription() {
    const expr = 'referred_to_by[]';
    const data = jmespath(this.linkedArt, expr) || [];
    for (const item of data) {
      if (
        item?.classified_as?.[0]?.['@id'] ===
        'http://vocab.getty.edu/aat/300435438'
      ) {
        return getPreferredValue(item);
      }
    }
    return '';
  }

  /**
   * Gets the bibliography description for the work.
   *
   * @returns {string}
   *   A description.
   */
  get bibliographyDescription() {
    const expr = 'referred_to_by[]';
    const data = jmespath(this.linkedArt, expr) || [];
    for (const item of data) {
      if (
        item?.classified_as?.[0]?.['@id'] ===
        'http://vocab.getty.edu/aat/300026497'
      ) {
        return getPreferredValue(item);
      }
    }
    return '';
  }

  /**
   * Gets the signature value of the work.
   *
   * @returns {string|boolean}
   *   A description.
   */
  get signature() {
    const expr = 'part[]';
    const data = jmespath(this.linkedArt, expr) || [];
    for (const item of data) {
      if (
        item?.carries?.[0]?.classified_as?.[0]?.['@id'] ===
        'http://vocab.getty.edu/aat/300028705'
      ) {
        return getPreferredValue(item.carries[0]);
      }
    }
    // Return false to indicate the artwork is unsigned.
    return false;
  }

  /**
   * Gets the inscriptions etc. of the work.
   *
   * @returns {[]}
   *   A description.
   */
  get inscriptions() {
    const classificationIds = [
      // inscription
      'http://vocab.getty.edu/aat/300028702',
      // sticker
      'http://vocab.getty.edu/aat/300207379',
      // watermark
      'http://vocab.getty.edu/aat/300028749',
      // label
      'http://vocab.getty.edu/aat/300028730',
      // stamp
      'http://vocab.getty.edu/aat/300262844',
    ];

    const expr = 'part[]';
    const rawData = jmespath(this.linkedArt, expr) || [];
    const data = [];
    for (const item of rawData) {
      if (
        typeof item?.carries?.[0]?.classified_as?.[0]?.['@id'] === 'string' &&
        classificationIds.includes(item.carries[0].classified_as[0]['@id'])
      ) {
        data.push({
          label: getPreferredValue(item.carries[0].classified_as[0]),
          value: getPreferredValue(item.carries[0]),
          renderType: 'inscription',
        });
      }
    }
    return data;
  }

  /**
   * Gets the subject_of (the collection url) of the work.
   *
   * @returns {string}
   *   The collection url.
   */
  get subjectOf() {
    const classificationId = 'http://vocab.getty.edu/aat/300264578';
    const expr = 'subject_of[]';
    const rawData = jmespath(this.linkedArt, expr) || [];

    for (const item of rawData) {
      if (typeof item?.classified_as?.[0]?.['@id'] === 'string' &&
        item?.classified_as?.[0]?.['@id'] === classificationId
      ) {
        return getPreferredValue(item?.access_point[0]?.['@id']);
      }
    }

    return '';
  }
}

export default ArtworkTuple;
