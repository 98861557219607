import React from 'react';
import BasicPage from '../components/BasicPage';

const PrivacyPolicy = () => {
  return (
    <BasicPage title="Privacy policy">
      <p>
        For Van Gogh Worldwide, and all of the affiliated organisations, the
        careful handling of data of visitors to our platform is of the utmost
        importance. The Van Gogh Worldwide platform is geared towards
        safeguarding your privacy as far as possible. Users do not need to
        register.
      </p>
      <p>
        Information on browsing habits is used exclusively to support technical
        decisions, improve the functionality of the platform and for statistical
        analysis, never to identify individuals.
      </p>
      <h2>Cookies</h2>
      <p>Analytical cookies</p>
      <p>
        A cookie is a small text file that is saved to your computer, tablet or
        smartphone when you visit a website for the first time. The website
        www.vangoghworldwide.org uses analytical cookies. Analytical cookies
        measure all data and are then forwarded to Google Analytics. These
        cookies measure, for example, the number of visitors on the website at
        any given moment, the amount of time the visitor spends there and which
        pages are viewed. Google Analytics cookies are completely anonymised.
      </p>
      <p>
        We use Google Analytics to gain an impression of the effectiveness of
        our website. Your name is not registered, but your IP address is. This
        information may be transferred to Google's servers in the USA. Google
        and all of its subsidiaries are bound by the Privacy Shield. We do not
        require consent to place these analytical cookies because doing so does
        not affect your privacy. You can, however, refuse cookies using your
        browser settings. You can also delete all cookie information that your
        browser has previously stored via your browser preferences.
      </p>
      <p>
        We sometimes post links to third-party websites on our website and it is
        important to note that when visiting these third-party websites, the
        privacy statement of the website in question applies rather than that of
        the Van Gogh Worldwide platform.
      </p>
      <p>
        We regularly update our privacy policy and may from time to time make
        revisions. Any future change in our privacy policy will be announced on
        this page. Your information is subject to the privacy policy which is
        applicable at the moment you visit our website. If we announce a new
        policy, any information newly provided by you will be subject to the
        policy that applies at that point in time.
      </p>
      <p>
        If you have any questions regarding our privacy policy, please contact{' '}
        <a href="mailto:info@vangoghworldwide.org">
          info@vangoghworldwide.org
        </a>
      </p>
    </BasicPage>
  );
};

export default PrivacyPolicy;
