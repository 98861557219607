import React from 'react';
import { withSearch } from '@elastic/react-search-ui';
import Pluralize from 'react-pluralize';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../assets/images/icon-close.svg';

const StyledSearchSummary = styled.div`
  .search-summary__search_term {
    margin-left: 8px;
    padding-left: 16px;

    button {
      font-size: 1rem;
    }
  }
`;

const SearchSummary = ({
  isLoading,
  totalResults,
  resultSearchTerm,
  setSearchTerm,
  fixedFilterLabel = null,
  additionalResultsCount = 0,
}) => {
  const searchTermRemove = () => {
    setSearchTerm('', { shouldClearFilters: false });
  };

  const grandTotalResults = totalResults + additionalResultsCount;

  return (
    <StyledSearchSummary className="search-summary">
      {isLoading ? (
        'Loading...'
      ) : grandTotalResults ? (
        <>
          <b>
            <Pluralize singular={'result'} count={grandTotalResults} />
          </b>{' '}
          found
          {resultSearchTerm && (
            <>
              {' for '}
              <label className="button--black rounded search-summary__search_term">
                <CloseIcon aria-hidden="true" width="10" height="10" />
                <button
                  onClick={searchTermRemove}
                  aria-label={`clear search term ${resultSearchTerm}`}
                >
                  {resultSearchTerm}
                </button>
              </label>
            </>
          )}
          {fixedFilterLabel && (
            <>
              {' in '}
              <em>{fixedFilterLabel}</em>
            </>
          )}
        </>
      ) : (
        'No results found.'
      )}
    </StyledSearchSummary>
  );
};

export default withSearch(
  ({ isLoading, totalResults, resultSearchTerm, setSearchTerm }) => ({
    isLoading,
    totalResults,
    resultSearchTerm,
    setSearchTerm,
  })
)(SearchSummary);
