import { search as jmespath } from 'jmespath';

/**
 * Utility class to provide access to owners and their geolocation.
 *
 */
class OwnersGeo {
  constructor(jsonObject) {
    this.json = jsonObject;
  }

  /**
   * Returns an array of owner & geolocation items.
   *
   * @returns {[*]}
   */
  get items() {
    const query =
      '[].{id: tuple[0].id, count: probability, attributes: tuple[0].attributes}';

    const rawItems = jmespath(this.json?.items, query) || [];

    const items = rawItems
      .filter(
        item =>
          item?.attributes &&
          item.attributes.hasOwnProperty('carried_out') &&
          item.attributes.carried_out.length > 0 &&
          item.attributes.carried_out[0]?.took_place_at[0]?.defined_by
      )
      .map(item => {

        // Format is Point(longitude latitude);
        const point = item.attributes.carried_out[0]?.took_place_at[0]?.defined_by;

        const latLon = point.substring(7, point.length - 2);
        const [lng, lat] = latLon.split(' ');

        // getPreferredValue(item.attributes) returns the wrong label (skos/core#prefLabel)
        // and we want ['http://schema.org/name'] (see #52546).
        // We can safely use ['nl'] here because it is the only key in ['http://schema.org/name'].
        return {
          label: item.attributes?._label ?? 'Unkown',
          lat: lat,
          lng: lng,
          count: item.count,
          id: item.id,
        }
      });

    return this.cacheAsStaticProperty('items', items);
  }

  /**
   * Replaces the given propertyName with a static value property.
   *
   * See
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/get#Examples
   *
   * @param {string} propertyName
   *   Name of the property to replace.
   * @param {*} propertyValue
   *   Value of the property.
   * @returns {*}
   *   Returns the given value.
   */
  cacheAsStaticProperty(propertyName, propertyValue) {
    delete this[propertyName];
    Object.defineProperty(this, propertyName, { value: propertyValue });
    return propertyValue;
  }
}

export default OwnersGeo;
