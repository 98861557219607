import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '../assets/images/icon-arrow-prev.svg';
import styled from 'styled-components';
import { colors } from '../style/variables/colors';

// Use a label element to wrap the icon and button in a clickable element.
const StyledButton = styled.label`
  color: ${colors.inkAlternate};
  height: 17px;
  // Display flex to force the button to play nice.
  display: flex;

  > * {
    cursor: pointer;
  }

  svg {
    height: 17px;
    padding-top: 3px;
  }
  button {
    color: inherit;
  }
`;

/**
 * Renders a back button.
 *
 * @param history
 *   Router history.
 * @param children
 *   Element children.
 * @returns
 *   JSX button element.
 */
const BackButton = ({ children }) => {
  const navigate = useNavigate();

  const onClickHandler = useCallback(
    e => {
      e.preventDefault();
      navigate(-1)
    },
    [navigate]
  );

  return (
    <StyledButton className="go-back">
      <ArrowIcon aria-hidden="true" />
      <button onClick={onClickHandler}>{children}</button>
    </StyledButton>
  );
};

export default BackButton;
