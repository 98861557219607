import React from 'react';
import { withSearch } from '@elastic/react-search-ui';
import styled from 'styled-components';
import { colors } from '../style/variables/colors';
import mediaQueries from '../style/variables/mediaQueries';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../assets/images/icon-search.svg';
import useIsOnMainSearchPage from '../hooks/useIsOnMainSearchPage';

const StyledSearchBox = styled.form`
  border: 1px solid ${colors.border};
  display: flex;
  padding: 5px;
  height: 44px;
  border-radius: 2px;

  ${mediaQueries.md} {
    height: 48px;
  }

  .search-box__input-wrapper {
    flex-grow: 1;
    margin-right: 5px;

    input {
      width: 100%;
      height: 100%;
      padding: 5px 15px;
      border: none;
    }
  }

  .search-box__submit {
    width: 32px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    ${mediaQueries.md} {
      width: 44px;
    }
  }
`;

function SearchBox({ searchTerm, setSearchTerm }) {
  const navigate = useNavigate();
  const isOnMainSearchPage = useIsOnMainSearchPage();

  const handleInput = event => {
    setSearchTerm(event.target.value, {
      refresh: false,
      autocompleteSuggestions: false,
      autocompleteResults: false,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (isOnMainSearchPage) {
      setSearchTerm(searchTerm, {});
    } else {
      navigate(`/search/?q=${searchTerm}`);
    }
  };

  return (
    <StyledSearchBox className="search-box" onSubmit={handleSubmit}>
      <div className="search-box__input-wrapper">
        <label htmlFor="search-term" className="visually-hidden">
          Search term:
        </label>
        <input
          id="search-term"
          type="text"
          name="search-term"
          placeholder="Search for artwork title, collection number, F number etc."
          value={searchTerm}
          onChange={handleInput}
        />
      </div>
      <label className="search-box__submit button--black rounded">
        <input type="submit" value="Search" className="visually-hidden" />
        <SearchIcon aria-hidden="true" />
      </label>
    </StyledSearchBox>
  );
}

export default withSearch(({ searchTerm, setSearchTerm }) => ({
  searchTerm,
  setSearchTerm,
}))(SearchBox);
