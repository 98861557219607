import adaptRequest from './requestAdapter';
import adaptResponse from './responseAdapter';
import request from './request';

class VgwConnector {
  constructor() {
    this.request = request.bind(this);
  }

  onResultClick({ query, documentId, tags }) {
    // Not supported (yet).
  }

  onAutocompleteResultClick({ query, documentId, tags }) {
    // Not supported (yet).
  }

  onSearch(state, queryConfig) {
    let facet_cache_key = `${state.searchTerm}:`;

    if (state.filters.length) {
      facet_cache_key += JSON.stringify(state.filters);
    }

    const existingFacets = getFacetsFromCache(facet_cache_key);

    // Get an array of paths, one for each request type.
    const paths = adaptRequest(state, queryConfig, existingFacets === false);

    // If there is no searchTerm, we must call the artworks endpoint.
    const strategy = state.searchTerm ? '/e/search' : '/e/artworks';

    // Get request promises for all paths.
    const requests = paths.map(path => this.request('GET', path, strategy));

    return Promise.all(requests).then(function(responses) {
      // Build an object with request types as keys and their respective
      // responses as values.
      const responsesObject = { facets: {} };
      let storeFacets = false;
      responses.forEach(response => {
        if (response.type === 'results' || response.type === 'statistics') {
          responsesObject[response.type] = response.data;
        } else {
          storeFacets = true;
          responsesObject.facets[response.type] = response.data;
        }
      });

      if (storeFacets) {
        getFacetsFromCache(facet_cache_key, responsesObject.facets);
      } else {
        responsesObject.facets = existingFacets;
      }

      return adaptResponse(responsesObject, state);
    });
  }

  async onAutocomplete({ searchTerm }, queryConfig) {
    // if (queryConfig.results) {
    //   const options = adaptRequest({ searchTerm }, queryConfig.results);
    //
    //   return this.request('GET', 'autocomplete-result', options).then(json => ({
    //     autocompletedResults: adaptResponse(json).results,
    //   }));
    // }
    // if (queryConfig.suggestions) {
    //   const options = adaptRequest({ searchTerm }, queryConfig.results);
    //
    //   return this.request('GET', 'autocomplete-suggest', options).then(
    //     json => ({
    //       autocompletedResults: adaptResponse(json).results,
    //     })
    //   );
    // }
  }
}

export default VgwConnector;

/**
 * Caches facets.
 *
 * @param {string} key
 *   Key associated with the cached information.
 * @param {*} facets
 *   The facets to store, or null to request from cache.
 *
 * @returns {boolean|*}
 *   False if the cache is empty or stored with another key, the facets
 *   otherwise.
 */
function getFacetsFromCache(key, facets = null) {
  if (facets !== null) {
    getFacetsFromCache.key = key;
    getFacetsFromCache.cache = facets;
  }
  if (getFacetsFromCache.key !== key) {
    return false;
  }
  return typeof getFacetsFromCache.cache !== 'undefined'
    ? getFacetsFromCache.cache
    : false;
}
