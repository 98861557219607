/**
 * Possible language keys, ordered by preference.
 *
 * @type {string[]}
 */
export const languageOrder = ['default', 'en', 'en-US', 'nl'];

/**
 * Gets the translations from data and returns the most preferred option.
 *
 * @param {object} data - A piece of Linked Art data.
 * @returns {string} The value.
 */
export function getPreferredValue(data) {
  // If the data is already a string, just return it.
  if (typeof data === 'string') {
    return data;
  }
  // Safeguard against unexpected null(ish) values.
  if (!data || typeof data !== 'object') {
    return '';
  }
  const values = getValues(data);
  return findPreferredLanguageValue(values);
}

/**
 * Gets the preferred values from an array and joins them into a string.
 *
 * @param {[]} data - An array of data items as expected by getPreferredValue.
 * @param {string} glue - the glue to join the values.
 * @returns {string}
 */
export function joinPreferredValues(data = [], glue = ', ') {
  return data
    .map(function(item) {
      return getPreferredValue(item);
    })
    .join(glue);
}

/**
 * Gets values from the data en gathers them in an object keyed by language.
 *
 * @param {(object|object[]|string)} data - A piece of Linked Art data.
 * @returns {object} Values in the form of { languageCode: value }.
 */
export function getValues(data) {
  // The data should not be a string, but if it is, pretend that it's a string
  // in the preferred language.
  if (typeof data === 'string') {
    return { [languageOrder[0]]: data };
  }

  let values = {};

  // The data can be an array, in which each element contains a single
  // language version of the information. In that case, loop over the array to
  // extract and merge the values.
  if (Array.isArray(data)) {
    for (const arrayItem of data) {
      values = { ...values, ...getValues(arrayItem) };
    }
  } else {
    const content =
      data.content ||
      data['_label'] ||
      data['http://www.w3.org/2004/02/skos/core#prefLabel'] ||
      data['http://www.w3.org/2000/01/rdf-schema#label'] ||
      data['http://schema.org/name'];
    if (content) {
      if (typeof content === 'string') {
        values['default'] = content;
      } else {
        values = content;
      }
    }
  }

  return values;
}

/**
 * Finds the most preferred value in an object with values keyed by language.
 *
 * @param {object} values - Values in the form of { languageCode: value }.
 * @returns {string} - The value in the most preferred available language.
 */
export function findPreferredLanguageValue(values) {
  for (const language of languageOrder) {
    if (values.hasOwnProperty(language)) {
      // For invalid language tags (> 1 tag for the same object), return only the first.
      if (Array.isArray(values[language])) {
        return values[language][0];
      }
      return values[language];
    }
  }
  return '';
}
