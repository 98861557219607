import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { gtag } from 'ga-gtag';

/**
 * Registers a listener on history.
 */
function Analytics() {
  const location = useLocation();

  useEffect(() => {
    // Record pageview for given location.
    gtag(
      'event',
      'page_view',
      {
        'page_location': location.pathname + location.search + location.hash,
      }
    )
 }, [location]);
}

export default Analytics;
