import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledPagesMenu = styled.nav`
  padding-bottom: 2rem;

  h2 {
    margin-bottom: 2rem;
  }
  a {
    line-height: 2;
  }
`;

export default function PagesMenu() {
  return (
    <StyledPagesMenu className="pages-menu">
      <h2 className="h3">Van Gogh Worldwide</h2>
      <ul className="unstyled-list">
        <li>
          <NavLink to="/about">About</NavLink>
        </li>
        <li>
          <NavLink to="/news">News</NavLink>
        </li>
        <li>
          <NavLink to="/contributing-partners">Contributing partners</NavLink>
        </li>
        <li>
          <NavLink to="/disclaimer">Disclaimer</NavLink>
        </li>
        <li>
          <NavLink to="/contact">Contact</NavLink>
        </li>
        <li>
          <NavLink to="/faq">FAQ</NavLink>
        </li>
        <li>
          <NavLink to="/privacy-policy">Privacy policy</NavLink>
        </li>
      </ul>
    </StyledPagesMenu>
  );
}
