import React from 'react';
import ArtworkDimensions from './ArtworkDimensions';
import ExternalLink from './ExternalLink';
import LinkToSearch from './LinkToSearch';
import { Link } from 'react-router-dom';

/**
 * Check for unavailable values. This does not include `false`, which means that
 * the value is a meaningful "no" (eg. an artwork is not signed).
 *
 * @param value
 * @returns {boolean}
 */
function rowValueHasData(value) {
  return typeof value !== 'undefined' && value !== '' && value.length !== 0;
}

/**
 *
 * @param value
 */
function ArtworkDetailsCreator(value) {
  let label;
  if (!rowValueHasData(value.label)) {
    label = value.id;
  } else {
    label = value.label;
  }
  return <div>{label}</div>;
}

/**
 * @param value
 */
function ArtworkDetailsDate(value) {
  return <div>{value}</div>;
}

function ArtworkDetailsLocation(value) {
  return <div>{value.constructor === Array ? value.join(', ') : value}</div>;
}

/**
 * @param value
 */
function ArtworkDetailsPeriod(value) {
  return <div>{value}</div>;
}

/**
 * @param value
 */
function ArtworkDetailsTitle(value) {
  return <div>{value}</div>;
}

/**
 * @param value
 */
function ArtworkDetailsOtherTitle(value) {
  return value.map((value, i) => (
    <div key={i}>
      {value.title} {value.language_label && <i>[{value.language_label}]</i>}
    </div>
  ));
}

/**
 * @param value
 */
function ArtworkDetailsCatalogueNumbers(value) {
  return (
    <ul className="unstyled-list--inline piped">
      {value.map(identifier => (
        <li key={identifier[0]}>{identifier[1]}</li>
      ))}
    </ul>
  );
}

/**
 * @param value
 */
function ArtworkDetailsMaterial(value) {
  return (
    <ul className="unstyled-list">
      {value.map((value, i) => (
        <li key={i}>{value}</li>
      ))}
    </ul>
  );
}

/**
 * @param value
 */
function ArtworkDetailsSupport(value) {
  return (
    <ul className="unstyled-list">
      {value.map((value, i) => (
        <li key={i}>{value}</li>
      ))}
    </ul>
  );
}

/**
 * @param value
 */
function ArtworkDetailsTechnique(value) {
  return (
    <>
      <ul className="unstyled-list">
        {value.technique.length > 0 &&
          value.technique.map((value, i) => <li key={i}>{value}</li>)}
      </ul>
      {value.description && <p className="boxed">{value.description}</p>}
    </>
  );
}

/**
 * @param value
 */
function ArtworkDetailsDLFMaterial(value) {
  return (
    <ul className="unstyled-list">
      {value.map((value, i) => (
        <li key={i}>{value}</li>
      ))}
    </ul>
  );
}

/**
 * @param value
 */
function ArtworkDetailsDimensions(value) {
  return <ArtworkDimensions {...value} />;
}

/**
 * @param value
 */
function ArtworkDetailsSignature(value) {
  return <div>{value || <i>[Unknown]</i>}</div>;
}

/**
 * @param value
 */
function ArtworkDetailsLetters(letter) {
  return (
    <>
      <p>
        <ExternalLink href={letter.url}>
          From <i>{letter.sender}</i> to <i>{letter.recipient}</i>
        </ExternalLink>
      </p>
      <ul className="unstyled-list--inline piped">
        {letter.locationCreated && <li>{letter.locationCreated}</li>}
        {letter.dateSent && <li>{letter.dateSent}</li>}
        {letter.id && <li>Letter {letter.id}</li>}
      </ul>
    </>
  );
}

/**
 * @param value
 */
function ArtworkDetailsProvenance(value) {
  return (
    <>
      <p>
        {value.labelPath ? (
          <Link to={value.labelPath}>{value.label}</Link>
        ) : (
          value.label
        )}
      </p>
      <ul className="unstyled-list--inline piped">
        {value.type && <li>{value.type}</li>}
        {value.auctionLabel ? (
          <li>
            {value.auctionPath ? (
              <LinkToSearch
                basePath={value.auctionPath}
                filters={[
                  {
                    field: 'auction',
                    values: [value.auctionId],
                  },
                ]}
              >
                {value.auctionLabel}
              </LinkToSearch>
            ) : (
              value.auctionLabel
            )}
          </li>
        ) : null}
        {value.timespan && <li>{value.timespan}</li>}
        {value.location && <li>{value.location}</li>}
        {value.dimension && <li>{value.dimension}</li>}
        {value.price && <li>{value.price}</li>}
        {value.activity && <li>{value.activity}</li>}
      </ul>
      {typeof value.description?.content !== 'undefined' && (
        <p className="boxed">
          {value.description.content}
          {typeof value.description?.source !== 'undefined' && (
            <i>[{value.description.source}]</i>
          )}
        </p>
      )}
    </>
  );
}

/**
 * @param value
 */
function ArtworkDetailsExhibition(value) {
  let period;
  if (value.period?.begin) {
    period = value.period.begin;
  }
  if (value.period?.end) {
    period += ' - ' + value.period.end;
  }

  // Pre-set the exhibition filter here, see the useEffect hook in the
  // Exhibition component to understand why.
  return (
    <>
      <p>
        <LinkToSearch
          basePath={value.path}
          filters={[
            {
              field: 'exhibition',
              values: [value.id],
            },
          ]}
        >
          {value.title}
        </LinkToSearch>
      </p>
      <ul className="unstyled-list--inline piped">
        {value.carriedOutBy && (
          <li>
            {value.carriedOutByActorPath ? (
              <Link to={value.carriedOutByActorPath}>{value.carriedOutBy}</Link>
            ) : (
              value.carriedOutBy
            )}
          </li>
        )}
        {value.location && <li>{value.location}</li>}
        {typeof value.period === 'string' && <li>{value.period}</li>}
        {period && <li>{period}</li>}
      </ul>
    </>
  );
}

/**
 * @param value
 */
function ArtworkDetailsPublication(value) {
  if (!value.author) {
    value.author = '(Author unknown)';
  }

  return (
    <>
      <p>{value.url ? <a href={value.url}>{value.title}</a> : value.title}</p>
      <ul className="unstyled-list--inline piped">
        {Object.keys(value.metadata).map(key => (
          <li key={key}>{value.metadata[key]}</li>
        ))}
      </ul>
    </>
  );
}

/**
 * @param value
 */
function ArtworkDetailsInscription(value) {
  return <div>{value}</div>;
}

/**
 * @param data
 */
function ArtworkDetailsTechnicalDocs(data) {
  return (
    <>
      <div className="textual">
        <h3>{data.label}</h3>
        <ul className="unstyled-list--inline piped">
          {Object.keys(data.value).map(key => (
            <li key={key}>{data.value[key]}</li>
          ))}
        </ul>
      </div>
      <div className="visual">
        {data.representation && data.representation.uri && (
          <img src={data.representation.uri} alt={data.representation.alt} />
        )}
      </div>
    </>
  );
}

/**
 * @param data
 */
function ArtworkDetailsRelatedWork(data) {
  // @todo Remove placeholder image code once we have real images to test with.
  if (
    data.representation.uri === '' &&
    process.env.NODE_ENV &&
    process.env.NODE_ENV === 'development'
  ) {
    data.representation.uri = 'http://lorempixel.com/200/300/abstract';
  }

  return (
    <>
      <div className="textual">
        <h3>
          <Link to={data.path}>{data.label}</Link>
        </h3>
        <ul className="unstyled-list--inline piped">
          {Object.keys(data.value).map(key => (
            <li key={key}>{data.value[key]}</li>
          ))}
        </ul>
      </div>
      <div className="visual">
        {data.representation.uri && (
          <img src={data.representation.uri} alt={data.representation.alt} />
        )}
      </div>
    </>
  );
}

/**
 * @param data
 */
const ArtworkDetailsValue = data => {
  let value = data.value;
  switch (data.renderType) {
    case 'creator':
      return ArtworkDetailsCreator(value);
    case 'date':
      return ArtworkDetailsDate(value);
    case 'period':
      return ArtworkDetailsPeriod(value);
    case 'location_made':
      return ArtworkDetailsLocation(value);
    case 'title':
      return ArtworkDetailsTitle(value);
    case 'other_title':
      return ArtworkDetailsOtherTitle(value);
    case 'catalogue_numbers':
      return ArtworkDetailsCatalogueNumbers(value);
    case 'material':
      return ArtworkDetailsMaterial(value);
    case 'dlfMaterial':
      return ArtworkDetailsDLFMaterial(value);
    case 'dimensions':
      return ArtworkDetailsDimensions(value);
    case 'letters':
      return ArtworkDetailsLetters(value);
    case 'provenance':
      return ArtworkDetailsProvenance(value);
    case 'exhibition':
      return ArtworkDetailsExhibition(value);
    case 'publication':
      return ArtworkDetailsPublication(value);
    case 'support':
      return ArtworkDetailsSupport(value);
    case 'technique':
      return ArtworkDetailsTechnique(value);
    case 'signature':
      return ArtworkDetailsSignature(value);
    case 'inscription':
      return ArtworkDetailsInscription(value);
    // Technical docs and related works are different from the rest: they want
    // all data, not just data.value.
    case 'technical_docs':
      return ArtworkDetailsTechnicalDocs(data);
    case 'related_work':
      return ArtworkDetailsRelatedWork(data);
    default:
      break;
  }
};

export default ArtworkDetailsValue;
