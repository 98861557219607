import { useLocation } from 'react-router-dom';

/**
 * Hook to determine whether the component is rendered on the main search page.
 *
 * @returns {boolean}
 *   TRUE if on the main search page, FALSE otherwise.
 */
const useIsOnMainSearchPage = () => {
  const { pathname } = useLocation();
  return pathname === '/search' || pathname.startsWith('/search/');
};

export default useIsOnMainSearchPage;
