import _extends from '@babel/runtime/helpers/extends';
import _objectWithoutProperties from '@babel/runtime/helpers/objectWithoutProperties';
import PropTypes from 'prop-types';
import React from 'react';
import RCPagination from './Pagination';
import enUsLocale from './locale/en_US';
import { appendClassName } from '@elastic/react-search-ui-views/lib/esm/view-helpers';

function PagingView(_ref) {
  var className = _ref.className,
    current = _ref.current,
    resultsPerPage = _ref.resultsPerPage,
    onChange = _ref.onChange,
    totalPages = _ref.totalPages,
    rest = _objectWithoutProperties(_ref, [
      'className',
      'current',
      'resultsPerPage',
      'onChange',
      'totalPages',
    ]);

  const onChangeThenScroll = function(...args) {
    onChange(...args);
    window.setTimeout(() => {
      window.location.hash = '#search-results';
    }, 300);
  };

  return React.createElement(
    RCPagination,
    _extends(
      {
        current: current,
        onChange: onChangeThenScroll,
        pageSize: resultsPerPage,
        total: totalPages * resultsPerPage,
        className: appendClassName('sui-paging', className),
        locale: enUsLocale,
      },
      rest
    )
  );
}

PagingView.propTypes = {
  current: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default PagingView;
