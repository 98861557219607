import React from 'react';
import StyledHorizontalTabs from './StyledHorizontalTabs';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import * as PropTypes from 'prop-types';
import GridContainer from '../layout/GridContainer';

const HorizontalTabs = ({ tabConfig }) => {
  // Generate an array of Tab components.
  const tabs = tabConfig.map((item, i) => (
    <Tab key={`tab-${i}`}>{item.label}</Tab>
  ));

  // Generate an array of TabPanel components with the actual content component
  // inside.
  const tabPanels = tabConfig.map((item, i) => {
    const Component = item.component;
    const componentProps = item.props;
    return (
      <TabPanel key={`tabpanel-${i}`}>
        <Component {...componentProps} key={`tabpanelcontent-${i}`} />
      </TabPanel>
    );
  });

  return (
    <StyledHorizontalTabs>
      <Tabs forceRenderTabPanel={true}>
        <GridContainer>
          <div className="grid-col">
            <TabList>{tabs}</TabList>
          </div>
        </GridContainer>
        {/* Components in tabPanels are supposed to take care of their own grid alignment. */}
        {tabPanels}
      </Tabs>
    </StyledHorizontalTabs>
  );
};

HorizontalTabs.propTypes = {
  tabConfig: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      component: PropTypes.elementType,
      props: PropTypes.object,
    })
  ),
};

export default HorizontalTabs;
