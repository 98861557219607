import { search as jmespath } from 'jmespath';
import getYearFromDate from '../Display/getYearFromDate';
import ExhibitionTuple from './ExhibitionTuple';

/**
 * Utility class to provide easy access to exhibitions contents.
 *
 */
class Exhibitions {
  constructor(jsonObject) {
    this.json = jsonObject;
  }

  /**
   * Returns an array of exhibition items.
   *
   * @returns {[*]}
   */
  get items() {
    const query = '[].tuple[0]';
    const rawItems = jmespath(this.json?.items, query) || [];

    const exhibitions = rawItems.map(item => {
      const exhibition = new ExhibitionTuple(item);

      return {
        label: getYearFromDate(item.attributes.timespan[0].begin_of_the_begin),
        sortValue: item.attributes.timespan[0].begin_of_the_begin,
        value: {
          title: exhibition.title,
          id: exhibition.id,
          carriedOutBy: exhibition.carriedOutBy,
          carriedOutByActorPath: exhibition.carriedOutByActorPath,
          location: exhibition.location,
          period: exhibition.period,
          path: exhibition.path,
        },
        renderType: 'exhibition',
      };
    });

    exhibitions.sort((a, b) => {
      if (a.sortValue === b.sortValue) {
        return 0;
      }
      return a.sortValue < b.sortValue ? -1 : 1;
    });

    return this.cacheAsStaticProperty('items', exhibitions);
  }

  /**
   * Replaces the given propertyName with a static value property.
   *
   * See
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/get#Examples
   *
   * @param {string} propertyName
   *   Name of the property to replace.
   * @param {*} propertyValue
   *   Value of the property.
   * @returns {*}
   *   Returns the given value.
   */
  cacheAsStaticProperty(propertyName, propertyValue) {
    delete this[propertyName];
    Object.defineProperty(this, propertyName, { value: propertyValue });
    return propertyValue;
  }
}

export default Exhibitions;
