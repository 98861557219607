import React, { Component } from 'react';
import mirador from 'mirador';

class Mirador extends Component {
  constructor(props) {
    super(props);
    this.miradorInstance = null;
  }

  /**
   * Instantiate the Mirador viewer and set event handlers.
   */
  componentDidMount() {
    const { config, plugins, manifest } = this.props;

    this.miradorInstance = mirador.viewer(config, plugins);
    // Load the provided manifest as a new window in the viewer.
    if (manifest) {
      this.miradorInstance.store.dispatch(
        mirador.actions.addWindow({
          manifestId: manifest,
        })
      );
    }
  }

  render() {
    const { config } = this.props;
    return (
      <div id={config.id}>
        <p>Loading</p>
      </div>
    );
  }
}

export default Mirador;
