import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import mediaQueries from '../style/variables/mediaQueries';
import { colors } from '../style/variables/colors';
import ViewModeListMinimal from '../components/ViewModeListMinimal';
import getSortedNewsItems from "../utils/Display/getSortedNewsItems";

const StyledNewsList = styled.div`
  h2 {
    color: ${colors.inkAlternate};
  }

  ol {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    ${mediaQueries.max_md} {
      flex-direction: column;
    }

    border-top: 1px solid ${colors.border};
    border-bottom: 1px solid ${colors.border};
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    li {
      flex: 1 1 100%;

      a {
        text-decoration: none;
      }
    }
  }

  .actions {
    margin-top: 0.5rem;
    text-align: right;
  }
`;

const NewsList = ({ heading }) => {
  const maxNewsItems = 3;
  const newsItems = getSortedNewsItems();

  return (
    <StyledNewsList>
      <h2>{heading}</h2>
      <ol className="unstyled-list">
        {Object.keys(newsItems).slice(0, maxNewsItems).map((key) => {
          const item = newsItems[key]
          return (
            <li
              key={key}
            >
              <a href={`news/${key}`}>
                <ViewModeListMinimal
                  title={item.title}
                  date={item.display_date}
                  summary={item.summary}
                />
              </a>
            </li>
          );
        })}
      </ol>
      <div className="actions">
        <NavLink to="/news">More news</NavLink>
      </div>
    </StyledNewsList>
  );
};

export default NewsList;
