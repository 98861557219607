/**
 * @file Stores size-related variables for use in styled components.
 *
 * Variables in this file can be used in styled components like this:
 * @example
 * import styled from 'styled-components';
 * import { gutters } from '../style/variables/sizes';
 * const StyledFoo = styled.div`
 *   margin-right: ${gutters.xl};
 * `;
 *
 * Important: When changing values, you must check src/style/styleguide.css
 * for corresponding values and update them manually. Unfortunately, plain CSS
 * files are not able to incorporate JS variables like styled components can.
 */

const gutters = {
  xs: '20px',
  sm: '20px',
  md: '40px',
  lg: '40px',
  xl: '40px',
};

const halfGutters = {
  xs: '10px',
  sm: '10px',
  md: '20px',
  lg: '20px',
  xl: '20px',
};

const sizes = {
  artworkImagesPadding: '24px',
};

export { gutters, halfGutters };
export default sizes;
