import { getPreferredValue } from './linkedArtHelpers';
import getMostPreciseDate from '../Display/getMostPreciseDate';
import getYearFromDate from '../Display/getYearFromDate';

/**
 * Utility class to provide easy access of Exhibition data.
 */
class ExhibitionTuple {
  /**
   * Constructs an ExhibitionTuple object which can be used to query the
   * data inside.
   *
   * @param {object} json
   */
  constructor(json) {
    this.json = json;
  }

  /**
   * Gets the Exhibition ID.
   *
   * @returns {string} The ID.
   */
  get id() {
    return this.json.id;
  }

  /**
   * Gets the internal Exhibition path to the route defined in src/index.js.
   *
   * @returns {string} The path.
   */
  get path() {
    return `/exhibition/${encodeURIComponent(this.id)}`;
  }

  /**
   * Gets the Exhibition title.
   *
   * @returns {string} The title.
   */
  get title() {
    return getPreferredValue(this.json?.attributes?.identified_by?.[0] ?? '');
  }

  /**
   * Gets the name of the organiser (actor) of the exhibition.
   *
   * @returns {string} The organiser name.
   */
  get carriedOutBy() {
    return getPreferredValue(this.json?.attributes?.carried_out_by?.[0] ?? '');
  }

  /**
   * Gets the name of the organiser (actor) of the exhibition.
   *
   * @returns {string} The actor path of the organiser.
   */
  get carriedOutByActorPath() {
    const actorId = this.json?.attributes?.carried_out_by?.[0]?.['@id'];
    if (actorId) {
      return `/actor/${encodeURIComponent(actorId)}`;
    }
    return '';
  }

  /**
   * Gets the location where the exhibition took place.
   *
   * @returns {string} A geographic name.
   */
  get location() {
    return getPreferredValue(this.json?.attributes?.took_place_at?.[0] ?? '');
  }

  /**
   * Gets the exhibition timespan.
   *
   * @returns {string} A human-readable timespan.
   */
  get period() {
    return getMostPreciseDate(this.json?.attributes?.timespan);
  }

  /**
   * Gets the exhibition start date.
   *
   * @returns {string|null} A date, or null if not available.
   */
  get startDate() {
    return this.json?.attributes?.timespan?.[0]?.begin_of_the_begin || null;
  }

  /**
   * Gets the exhibition end date.
   *
   * @returns {string|null} A date, or null if not available.
   */
  get endDate() {
    return this.json?.attributes?.timespan?.[0]?.end_of_the_end || null;
  }

  get yearLabel() {
    return getYearFromDate(
      this.json?.attributes?.timespan[0]?.begin_of_the_begin
    );
  }

  /**
   * Replaces the given propertyName with a static value property.
   *
   * See
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/get#Examples
   *
   * @param {string} propertyName
   *   Name of the property to replace.
   * @param {*} propertyValue
   *   Value of the property.
   * @returns {*}
   *   Returns the given value.
   */
  cacheAsStaticProperty(propertyName, propertyValue) {
    delete this[propertyName];
    Object.defineProperty(this, propertyName, { value: propertyValue });
    return propertyValue;
  }
}

export default ExhibitionTuple;
