export default function adaptResponse(responses, state) {
  const results = responses.results.items;
  const totalPages = Math.ceil(
    responses.statistics.total / state.resultsPerPage
  );
  const totalResults = responses.statistics.total;

  const facets = {};

  Object.keys(responses.facets).forEach(facetname => {
    const facet = responses.facets[facetname];
    const data = [];
    for (let i = 0; i < facet.items.length; i++) {
      data.push({
        value: facet.items[i].tuple[0].id,
        label: facet.items[i].tuple[1],
        count: facet.items[i].probability,
        tuple: facet.items[i].tuple,
      });
    }
    facets[facetname] = [
      {
        data,
        field: facetname,
        type: 'value',
      },
    ];
  });

  return {
    results,
    totalPages,
    totalResults,
    ...(Object.keys(facets).length > 0 && { facets }),
  };
}
