/**
 * @file Stores standard media queries for use in styled components.
 *
 * Media queries in this file can be used in styled components like this:
 * @example
 * import styled from 'styled-components';
 * import mediaQueries from '../style/variables/mediaQueries';
 * const StyledFoo = styled.div`
 * ${mediaQueries.lg} {
 *   width: 50%;
 * }
 * `;
 *
 * Important: When changing values, you must check src/style/styleguide.css
 * for corresponding values and update them manually. Unfortunately, plain CSS
 * files are not able to incorporate JS variables like styled components can.
 */

const mediaQueries = {
  sm: '@media screen and (min-width: 480px)',
  md: '@media screen and (min-width: 768px)',
  lg: '@media screen and (min-width: 1000px)',
  xl: '@media screen and (min-width: 1360px)',
  sm_md: '@media screen and (min-width: 480px) and (max-width: 767px)',
  md_lg: '@media screen and (min-width: 768px) and (max-width: 999px)',
  lg_xl: '@media screen and (min-width: 1000px) and (max-width: 1359px)',
  max_sm: '@media screen and (max-width: 479px)',
  max_md: '@media screen and (max-width: 767px)',
  max_lg: '@media screen and (max-width: 999px)',
  max_xl: '@media screen and (max-width: 1359px)',
};

export default mediaQueries;
