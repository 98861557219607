/**
 * Sort period facet values based on timespan.
 *
 * @param facetValues
 *  An array of facet values.
 * @returns {*}
 *   Sorted facet values.
 */
export function sortPeriodFacetValues(facetValues) {
  return facetValues.sort((a, b) => {
    const atimespan = a.tuple[0].attributes.timespan[0];
    const btimespan = b.tuple[0].attributes.timespan[0];
    if (atimespan.begin_of_the_begin === btimespan.begin_of_the_begin) {
      return 0;
    }
    return atimespan.begin_of_the_begin < btimespan.begin_of_the_begin ? -1 : 1;
  });
}

/**
 * Sort subject facet values based on a defined order.
 *
 * @param facetValues
 *  An array of facet values.
 * @returns {*}
 *   Sorted facet values.
 */
export function sortSubjectFacetValues(facetValues) {
  const prefferedOrder = [
    // Landscapes.
    'http://vocab.getty.edu/aat/300015636',
    // Cityscapes.
    'http://vocab.getty.edu/aat/300015571',
    // Seascapes.
    'http://vocab.getty.edu/aat/300117546',
    // Self portrait.
    'http://vocab.getty.edu/aat/300124534',
    // Portrait.
    'http://vocab.getty.edu/aat/300015637',
    // Figures.
    'http://vocab.getty.edu/aat/300189808',
    // Nudes.
    'http://vocab.getty.edu/aat/300189568',
    // Still-life.
    'http://vocab.getty.edu/aat/300015638',
  ];

  return facetValues.sort((a, b) => {
    const aIndex = prefferedOrder.indexOf(a.value);
    const bIndex = prefferedOrder.indexOf(b.value);

    // Elements not known in the order array should come last, but
    // we do not care about their order.
    if (aIndex === bIndex) {
      return 0;
    }

    if (aIndex === -1) {
      return 1;
    }

    if (bIndex === -1) {
      return -1;
    }

    return aIndex < bIndex ? -1 : 1;
  });
}
