import React, { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withSearch } from '@elastic/react-search-ui';
import { stringify } from 'qs';

/**
 * Builds a link to a page which contains a Search UI component. Make sure that
 * useIsOnAnySearchPage() returns `true` for the path you link to.
 */
const LinkToSearch = ({
  prevSearchTerm,
  setSearchTerm,
  setSort,
  reset,
  addFilter,
  newSearchTerm,
  filters,
  children,
  basePath = '/search',
  className,
  ...rest
}) => {
  const { pathname } = useLocation();
  const linksToCurrentPage = pathname === basePath;

  const onClick = useCallback(
    event => {
      event.preventDefault();
      // Reset search to force an update of the sort field.
      reset();
      setSearchTerm(newSearchTerm ?? '');
      if (filters) {
        filters.forEach(filter => {
          filter.values.forEach(value => {
            addFilter(filter.field, value, 'all');
          });
        });
      }
    },
    [newSearchTerm, reset, setSearchTerm, addFilter, filters]
  );

  const qs = {};

  if (newSearchTerm) {
    qs.q = newSearchTerm;
  }

  if (filters) {
    qs.filters = [];
    filters.forEach(filter => {
      qs.filters.push({
        field: filter.field,
        values: filter.values,
        type: 'all',
      });
    });
  }

  const path = `${basePath}${stringify(qs, {
    arrayFormat: 'indices',
    addQueryPrefix: true,
  })}`;

  return linksToCurrentPage ? (
    <a
      href={path}
      onClick={onClick}
      className={`link-to-search--on-search-page ${className}`}
      {...rest}
    >
      {children}
    </a>
  ) : (
    <Link
      to={path}
      className={`link-to-search--on-other-page ${className}`}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default withSearch(
  ({ searchTerm, setSearchTerm, setSort, reset, addFilter }) => ({
    prevSearchTerm: searchTerm,
    setSearchTerm,
    setSort,
    reset,
    addFilter,
  })
)(LinkToSearch);
