class Representations {
  constructor() {
    this.representations = [];
  }

  /**
   *
   * @param {string} uri
   *   Uri of the represenation.
   * @param {string} label
   *   Label of the representation (eg X-Ray, etc).
   * @param {string} description
   *   Description of the respresentation.
   * @param {bool} isIiif
   *   Whether the uri points to a IIIF image.
   */
  addRepresentation(uri, label = '', description = '', isIiif = false) {
    this.representations.push({
      uri,
      label,
      description,
      isIiif,
    });
  }

  /**
   * Gets an all representations.
   * @returns {[{uri, label, description, isIiif}]} | []
   *   An array of representations.
   */
  get all() {
    return this.representations;
  }
}

export default Representations;
