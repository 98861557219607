import React from 'react';
import { ReactComponent as InstituteIcon } from '../assets/images/icon-institute.svg';
import { ReactComponent as UserIcon } from '../assets/images/icon-user.svg';
import ExternalLink from './ExternalLink';
import ContentHeader from './ContentHeader';

const ActorHeader = ({ actor }) => {
  const details = [];

  actor.birth &&
    details.push({
      label: 'Born',
      data: actor.birth,
    });
  actor.death &&
    details.push({
      label: 'Deceased',
      data: actor.death,
    });

  actor.classifiedAs &&
    details.push({
      label: 'Description',
      data: actor.classifiedAs,
  });

  // Disabled until the correct data is available in the API.
  // const additionalTypeDescription = actor.getDescriptionByRemarkType(
  //   'additionalType'
  // );
  // additionalTypeDescription &&
  //   details.push({
  //     label: 'Qualification',
  //     data: additionalTypeDescription,
  //   });

  // Disabled until we get this data figured out.
  // actor.periodOfActivity &&
  //   details.push({
  //     label: 'Period of activity',
  //     data: actor.periodOfActivity.map((item, i) => (
  //       <React.Fragment key={`active-period-${i}`}>
  //         {item.period}, {item.location}
  //         <p className="boxed">{item.description}</p>
  //       </React.Fragment>
  //     )),
  //   });

  const IconComponent = actor.type === 'person' ? UserIcon : InstituteIcon;

  return (
    <ContentHeader
      title={actor.title}
      details={details}
      iconComponent={IconComponent}
    >
      {actor.rkdArtistsUrl && (
        <ExternalLink href={actor.rkdArtistsUrl}>
          More at RKDartists&
        </ExternalLink>
      )}
    </ContentHeader>
  );
};

export default ActorHeader;
