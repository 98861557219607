import React from 'react';
import BasicPage from '../components/BasicPage';

const Disclaimer = () => {
  return (
    <BasicPage title="Disclaimer">
      <p>
        All users with access to Van Gogh Worldwide, for whatever purpose, agree
        to the following: Van Gogh Worldwide is not liable, nor responsible for
        the content of any off-site pages or pages linked to or derived from the
        Van Gogh Worldwide website. The information on these websites has been
        inserted with the utmost care and is provided ‘as is’, without any
        guarantee, in any form whatsoever, explicitly or implicitly, including
        but not limited to guarantees concerning merchantability, uses,
        non-infringement, accuracy and the information being up-to-date.
      </p>
      <p>
        Van Gogh Worldwide reserves the right to make changes to the content of
        these websites and to (temporarily) discontinue the websites at any time
        without prior notice. Van Gogh Worldwide and its associated companies
        are not liable for any (direct, indirect, and/or consequential) damage
        (by reason of) (and/or) computer viruses resulting from access to, the
        use and trustworthiness of (the information on) these websites or any
        website linked to these, except in the case of gross negligence.
      </p>
      <p>
        All intellectual property rights relating to the information on these
        websites are vested in the joint enterprise of the three founding
        partners and its associated companies or have been incorporated with the
        permission of the holder of the intellectual property rights concerned.
      </p>
      <p>
        Images reproduced on Van Gogh Worldwide are not downloadable but if
        needed can be requested from the institution that houses the work.
      </p>
      <p>
        Van Gogh Worldwide does not provide technical research reports, but does
        indicate if these are available at the institutions that house the
        works, where they can be requested according to the terms of that
        organisation.
      </p>
    </BasicPage>
  );
};

export default Disclaimer;
