import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../style/variables/colors';
import ArtworkImages from './ArtworkImages';
import ArtworkImagesThumbnails from './ArtworkImagesThumbnails';
import ArtworkImagesActions from './ArtworkImagesActions';
import sizes from '../style/variables/sizes';
import getRenderedRepresentations from '../utils/Display/getRenderedRepresentations';
import getManifestUri from '../utils/Display/getManifestUri';

const StyledArtworkImagesContainer = styled.div`
  background-color: ${colors.backgroundAlternate};
  padding: ${sizes.artworkImagesPadding};
`;

const ArtworkImagesContainer = ({ title, description, representations }) => {
  const [activeImage, setActiveImage] = useState(0);
  const images = getRenderedRepresentations(representations.all);

  if (!images || images.length === 0) {
    return null;
  }

  /**
   * For now we simply use the same images for the main display and thumbnails.
   * @todo Figure out if we need to apply lazy loading and/or use scaled down images for the thumbnails.
   */
  return (
    <StyledArtworkImagesContainer>
      <ArtworkImages images={images} activeImage={activeImage} />
      <ArtworkImagesActions
        viewerUri={`/viewer?manifest=${getManifestUri(
          title,
          description,
          representations.all
        )}`}
        helpUri="https://iiif.io/explainers/using_iiif_resources/#iiif-manifest"
      />
      {images.length > 1 && (
        <ArtworkImagesThumbnails
          images={images}
          activeImage={activeImage}
          setActiveImage={setActiveImage}
        />
      )}
    </StyledArtworkImagesContainer>
  );
};

export default ArtworkImagesContainer;
