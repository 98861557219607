import React from 'react';
import contentImage from '../assets/images/about-page/vangoghmuseum-s0106V1962-800.jpg';
import BasicPage from '../components/BasicPage';

const About = () => {
  return (
    <BasicPage title="About">
      <p>
        Van Gogh Worldwide is a free digital platform providing art-historical
        and technical data about the work of Vincent van Gogh (1853–1890). The
        artist produced a total of approximately 2000 artworks, and the aim of
        Van Gogh Worldwide is to present data for these in an accessible way,
        via a user-friendly website.
      </p>

      <h2>Partners</h2>
      <p>
        Van Gogh Worldwide is a joint initiative of the RKD – Netherlands
        Institute for Art History, the Van Gogh Museum and the Kröller-Müller
        Museum. The platform has been constructed in collaboration with a large
        number of partners including museums, private collectors and research
        institutions, especially the Cultural Heritage Laboratory of the
        Cultural Heritage Agency of the Netherlands (RCE). The website is made
        possible by the three founding partners, supported by the
        Vincent van Gogh Foundation and the Mondriaan Fund.
      </p>

      <p>
        <img src={contentImage} alt="Wheatfield under Thunderclouds F778" />
      </p>

      <h1>Mission and scope</h1>
      <p>
        Van Gogh Worldwide offers continuously updated information about the
        works of Vincent van Gogh as published in J.-B de la Faille, The works
        of Vincent van Gogh. His Paintings and Drawings, Amsterdam 1970
        (here abbreviated to De la Faille 1970). Van Gogh Worldwide aims to be
        objective and will include all opinions on the works of art that have
        been published in scholarly literature.
      </p>
      <p>
        Please note the following:
      </p>
      <ol>
        <li>
          Artworks about which information was first scholarly published after
          1970 (the date of the book) are also included
        </li>
        <li>
          Several works in De la Faille 1970 are no longer recognized as
          authentic, and if this is scholarly published, these works can be
          found categorized as ‘Previously attributed to Vincent van Gogh.’ If
          this status is not fully recognized, the work will be categorized as
          ‘questionable’
        </li>
        <li>
          Van Gogh Worldwide does not include the ‘Rejected Works’ given in
          De la Faille 1970, pp. 587-97.
        </li>
      </ol>

      <h2>Information given</h2>
      <ol>
        <li>
          Information covers both art-historical and art-technical aspects of
          the artwork.
        </li>
        <li>
          The data are provided by the owners of the artworks in question, and
          they are also responsible for its management, accuracy or
          completeness.
        </li>
        <li>
          Basic catalogue information from De la Faille 1970 is also given for
          every work included.
        </li>
        <li>
          All data hosted on the website www.vangoghworldwide.org is made
          available in the N-triples format as open data. It can be accessed
          and retrieved through the Dataset Registry of the Digital Heritage
          Network, known as the "Datasetregister van het Netwerk Digitaal
          Erfgoed." This can be done by searching the registry for the
          publisher "Van Gogh worldwide consortium, represented by the
          Netherlands Institute of Art History." This registry is hosted by the
          National Archives (Nationaal Archief). The collections are grouped
          and filed by provider. These collections are not currently available
          as SPARQL endpoints. Responsibility for the data quality lies with
          the data source. The data is freely available for use under various
          open licenses.
        </li>
      </ol>
    </BasicPage>
  );
};

export default About;
