import React from 'react';
import styled from 'styled-components';
import { colors } from '../style/variables/colors';
import BasicPage from '../components/BasicPage';

const data = [
  {
    q: 'What information can I find at Van Gogh Worldwide?',
    a: 'On Van Gogh Worldwide you will find art-historical data, references, and technical data about the works of Vincent van Gogh.',
  },
  {
    q: 'How can I search?',
    a: 'You can search by typing your criteria in the search box, or you can use the filters at the left of the screen.',
  },
  {
    q: 'What is the added value of Van Gogh Worldwide?',
    a: 'It is a single portal which leads you to a wealth of research data about Van Gogh’s works all over the world (at the moment of release, only those in the Netherlands).',
  },
  {
    q: 'Where does the information on the platform come from?',
    a: 'All data are provided by the owners of the artworks (museums, institutions, private collectors) through linked open data. Additional data about these artworks can be provided by the knowledge institutes: the Van Gogh Museum, the RKD and the RCE (Cultural Heritage Agency of the Netherlands).',
  },
  {
    q: 'I think I own an artwork by Van Gogh. Can I have it appraised by Van Gogh Worldwide?  ',
    a: 'No, VGW does not authenticate works of art or make any representation on the value of works of art.',
  },
  {
    q: 'Can I join the platform?',
    a: 'Participation is by invitation of Van Gogh Worldwide only.',
  },
  {
    q: 'I am the owner of a work by Van Gogh, published in De la Faille 1970, but my work is not yet included in Van Gogh Worldwide, can I join?',
    a: 'In this case, please contact the Van Gogh Museum: Research@vangoghmuseum.nl.',
  },
  {
    q: 'Who is the owner of Van Gogh Worldwide?',
    a: 'The three founding partners are the owners of the platform. The data is owned by the participants.',
  },
];

const StyledLi = styled.li`
  .faq-question {
    font-weight: 600;
    margin-bottom: 10px;
  }

  .faq-answer {
    color: ${colors.inkAlternate};
    margin-top: 0;
    margin-bottom: 25px;
  }
`;

const FAQ = () => {
  return (
    <BasicPage title="FAQ">
      <ul className="unstyled-list">
        {data.map((item, i) => (
          <StyledLi key={i}>
            <p className="faq-question">{item.q}</p>
            <p className="faq-answer">{item.a}</p>
          </StyledLi>
        ))}
      </ul>
    </BasicPage>
  );
};

export default FAQ;
