import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ViewIcon } from '../assets/images/icon-imageviewer.svg';
import { ReactComponent as IIIFLogo } from '../assets/images/iiif-logo.svg';
import { ReactComponent as HelpIcon } from '../assets/images/icon-questionmark.svg';
// import { ReactComponent as DownloadIcon } from '../assets/images/icon-download.svg';

const StyledArtworkImagesActions = styled.ul`
  margin: 0 -1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .artwork-images-action {
    padding: 1rem;

    // Give the first and last child an identical flex-basis value (60px + left
    // and right padding) to make sure the middle items are aligned exactly in
    // the center.
    &:first-child {
      flex: 0 1 92px;
      margin-right: auto;
    }
    &:last-child {
      flex: 0 1 92px;
      margin-left: auto;
      justify-content: flex-end;
    }
  }
`;

/**
 * Actions that can be performed with images.
 *
 * @returns{*}
 *   An element with links and/or buttons.
 */
const ArtworkImagesActions = ({ viewerUri, helpUri }) => {
  return (
    <StyledArtworkImagesActions className="unstyled-list--inline">
      {/* @todo Enable link and finish image downloading behavior.
      <a
        className="icon-link--icon-after"
        href="#"
        title="Download image"
      >
        <span>Download</span>
        <DownloadIcon />
      </a>*/}
      <li className="artwork-images-action">
        <a
          className="artwork-images-action--iiif button"
          target="_blank"
          rel="noopener noreferrer"
          href={viewerUri}
          title="Visit to open viewer, or drag and drop onto an existing viewer"
        >
          <IIIFLogo width="20" height="16" />
        </a>
      </li>
      <li className="artwork-images-action">
        <a
          className="icon-link--icon-before button--white button--border"
          target="_blank"
          rel="noopener noreferrer"
          href={viewerUri}
          title="Visit to open viewer, or drag and drop onto an existing viewer"
        >
          <ViewIcon aria-hidden="true" />
          <span>Open in image viewer</span>
        </a>
      </li>
      <li className="artwork-images-action">
        <a
          className="icon-link--icon-before button"
          target="_blank"
          rel="noopener noreferrer"
          href={helpUri}
        >
          <HelpIcon aria-hidden="true" />
          Help
        </a>
      </li>
    </StyledArtworkImagesActions>
  );
};

export default ArtworkImagesActions;
