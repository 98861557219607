/**
 * @file Stores color-related variables for use in styled components.
 *
 * Variables in this file can be used in styled components like this:
 * @example
 * import styled from 'styled-components';
 * import { colors } from '../style/variables/colors';
 * const StyledFoo = styled.div`
 *   color: ${colors.ink};
 * `;
 *
 * Important: When changing values, you must check src/style/styleguide.css
 * for corresponding values and update them manually. Unfortunately, plain CSS
 * files are not able to incorporate JS variables like styled components can.
 */

// The palette object contains predefined colors codes.
const palette = {
  black: '#000000',
  white: '#ffffff',
  yellow: '#fecb00',
  offwhite: '#f5f5f5',
  lightgrey: '#cccccc',
  darkgrey: '#6d6d6d',
  grey: '#999999',
};

// The colors object describes how the colors from the palette are applied.
const colors = {
  ink: palette.black,
  inkAlternate: palette.darkgrey,
  inkDisabled: palette.grey,
  inkPlaceholder: palette.grey,
  background: palette.white,
  backgroundAlternate: palette.offwhite,
  border: palette.lightgrey,

  link: palette.black,
  linkHover: palette.darkgrey,

  buttonLightInk: palette.black,
  buttonLightInkDisabled: palette.grey,
  buttonLightBg: palette.white,
  buttonLightBgHover: palette.offwhite,
  buttonLightBgFocus: palette.lightgrey,
  buttonLightBgPress: palette.grey,

  buttonDarkInk: palette.white,
  buttonDarkInkDisabled: palette.grey,
  buttonDarkBg: palette.black,
  buttonDarkBgDisabled: palette.offwhite,
  buttonDarkBgHover: palette.darkgrey,
  buttonDarkBgFocus: palette.grey,
  buttonDarkBgPress: palette.lightgrey,

  filterCount: palette.black,
  filterCountBg: palette.yellow,

  mapMarkerBg: palette.yellow,

  tabHighlight: palette.yellow,

  timelineDot: palette.black,
  timelineLine: palette.lightgrey,

  bannerBg: palette.black,
  bannerColor: palette.white,
};

export { palette, colors };
