import ArtworkTuple from './ArtworkTuple';
import getDisplayDates from '../Display/getDisplayDates';
import getIdentifiers from '../Display/getIdentifiers';
import getRenderedRepresentation from '../Display/getRenderedRepresentation';

/**
 * Utility class to provide easy access to technical research contents.
 *
 */
class RelatedArtworks {
  constructor(jsonObject) {
    this.json = jsonObject;
  }

  /**
   * Returns an array of technical research items.
   *
   * @returns {[*]}
   */
  get items() {
    const items = [];
    for (const item of this.json.items) {
      const tuple = new ArtworkTuple(item.tuple[0]);
      const productionDatesString = getDisplayDates(tuple.productionDates).join(
        ', '
      );
      const representation = tuple.representation.uri
        ? getRenderedRepresentation(tuple.representation)
        : tuple.representation;

      items.push({
        label: tuple.title,
        path: tuple.path,
        representation: representation,
        value: {
          type: tuple.objectCategory,
          locationDate: `${productionDatesString}, ${tuple.location}`,
          owner: tuple.owner.label,
          identifiers: getIdentifiers(tuple.identifiers, false).join(', '),
        },
        renderType: 'related_work',
      });
    }

    return this.cacheAsStaticProperty('items', items);
  }

  /**
   * Replaces the given propertyName with a static value property.
   *
   * See
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/get#Examples
   *
   * @param {string} propertyName
   *   Name of the property to replace.
   * @param {*} propertyValue
   *   Value of the property.
   * @returns {*}
   *   Returns the given value.
   */
  cacheAsStaticProperty(propertyName, propertyValue) {
    delete this[propertyName];
    Object.defineProperty(this, propertyName, { value: propertyValue });
    return propertyValue;
  }
}

export default RelatedArtworks;
