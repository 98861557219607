import React from 'react';
import ArtworkList from './ArtworkList';
import useSpinqueData from '../hooks/useSpinqueData';

/**
 * Adds a dynamic count to an ArtworkList.
 *
 * @param string field
 *   Facet field name, such as type, period.
 * @param array data
 *   An array of data for ArtworkList.
 * @param {*} rest
 *   Additional parameters.
 * @returns {ArtworkList}
 *   Artworklist with the caption.subtitle replaced with a dynamic count.
 */
export default function ArtworkListWithFacetCount({ field, data, ...rest }) {
  const {
    data: [facet],
  } = useSpinqueData([`/e/artworks/q/${field}/results`]);

  if (facet && facet?.items?.[0]) {
    // Iterate over our data and map facets by id, then add counts.
    // We can only support counts on one filter, an actual search
    // would be needed for combinations.
    data = Object.entries(data).map(([key, item]) => {
      const matchingFacetItem = facet.items.find(
        facetItem => facetItem.tuple[0].id === item.filters[0]['values'][0]
      );

      if (matchingFacetItem) {
        return {
          ...item,
          caption: {
            ...item.caption,
            subtitle:
              matchingFacetItem.probability === 1
                ? '1 item'
                : `${new Intl.NumberFormat('en-US').format(
                    matchingFacetItem.probability
                  )} items`,
          },
        };
      }
      return item;
    });
  }

  return <ArtworkList data={data} {...rest} />;
}
