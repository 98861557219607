import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import mediaQueries from '../style/variables/mediaQueries';
import { gutters, halfGutters } from '../style/variables/sizes';
import { colors } from '../style/variables/colors';
import getIdentifiers from '../utils/Display/getIdentifiers';
import getDisplayDates from '../utils/Display/getDisplayDates';
import getRenderedRepresentation from '../utils/Display/getRenderedRepresentation';

const StyledResultGridCell = styled.li`
  list-style: none;
  width: 100%;
  margin-bottom: ${gutters.xs};
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  cursor: pointer;

  ${mediaQueries.sm} {
    margin-bottom: ${gutters.sm};
  }
  ${mediaQueries.md} {
    margin-bottom: ${gutters.md};
  }
  ${mediaQueries.lg} {
    margin-bottom: ${gutters.lg};
  }
  ${mediaQueries.xl} {
    margin-bottom: ${gutters.xl};
  }

  &.with-gutters {
    position: absolute;
    padding: 0 ${halfGutters.xs};

    ${mediaQueries.sm} {
      width: 50%;
      padding: 0 ${halfGutters.sm};
    }
    ${mediaQueries.md} {
      width: 33.333%;
      padding: 0 ${halfGutters.md};
    }
    ${mediaQueries.lg} {
      width: 33.333%;
      padding: 0 ${halfGutters.lg};
    }
    ${mediaQueries.xl} {
      width: 25%;
      padding: 0 ${halfGutters.xl};
    }
  }

  .property {
    font-size: 0.875rem;
    line-height: 22px;
  }

  .image {
    order: -4;
    margin-bottom: 0.5rem;

    // Reserve some space for images while they are loading.
    img.loading {
      min-height: 200px;
      object-fit: contain;
    }
  }

  .previous-attributions,
  .questionable-attributions {
    order: -3;
    height: 52px;
    margin: -76px 16px 24px;
    background-color: ${colors.background};
    text-align: center;
    padding: 4px;
  }

  .title {
    order: 0;
    margin: 0.25rem 0;
    a {
      color: ${colors.ink};
      font-size: 1rem;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
  }

  .delafaille-dataset {
    order: -2;
    background-color: ${colors.backgroundAlternate};
    padding: 5px 1rem;
    width: auto;
    margin: 0.5rem auto 0.5rem 0;
  }

  &:hover .title a {
    text-decoration: underline;
  }

  .object-category {
    order: -1;
  }
`;

function Property({ children, className }) {
  return children && <div className={`property ${className}`}>{children}</div>;
}

function Dating({ linkedData }) {
  const item = [];
  const productionDate = getDisplayDates(linkedData.productionDates).join(', ');
  if (productionDate) {
    item.push(productionDate);
  }

  const locationMade = linkedData.location;
  if (locationMade) {
    item.push(locationMade);
  }

  return (
    item.length > 0 && <Property className="date">{item.join(', ')}</Property>
  );
}

function PreviousAttributions({ attributions }) {
  return (
    attributions.length > 0 && (
      <Property className="previous-attributions">{attributions[0]}</Property>
    )
  );
}

function QuestionableAttributions({ attributions }) {
  return (
    attributions.length > 0 && (
      <Property className="questionable-attributions">
        {attributions[0]}
      </Property>
    )
  );
}

function DeLaFailleIndicator({ isDlf70 }) {
  return (
    isDlf70 && (
      <Property className="delafaille-dataset">De la Faille 1970 data</Property>
    )
  );
}

function Representation({ linkedData }) {
  // We reserve some space for images while they are loading and remove the
  // .loading class when done. It would be much better if we could explicitly
  // add the correct height and width attributes to the <img> element, but
  // unfortunately that information is currently not provided by the API.
  const handleOnLoad = e => {
    e.target.classList.remove('loading');
  };
  const renderableRepresentation = linkedData.representation.uri
    ? getRenderedRepresentation(linkedData.representation)
    : null;

  return (
    <Property className="image">
      {renderableRepresentation && (
        <img
          src={renderableRepresentation.uri}
          alt={renderableRepresentation.alt}
          className="loading"
          onLoad={handleOnLoad}
        />
      )}
    </Property>
  );
}

function CatalogueNumber({ linkedData }) {
  const identifiers = getIdentifiers(linkedData.identifiers);

  return (
    <Property className="catalogue-number">
      {identifiers.length && (
        <ul className="unstyled-list--inline piped">
          {identifiers.map(id => (
            <li key={id[0]}>{id[1]}</li>
          ))}
        </ul>
      )}
    </Property>
  );
}

const ResultGridCell = ({
  artwork,
  className = 'with-gutters',
  overrideComponents = {},
}) => {
  const navigate = useNavigate();
  const path = artwork.path;

  function clickCell(e) {
    e.preventDefault();
    if (e.target.tagName !== 'A') {
      navigate(
        e.currentTarget.querySelectorAll('[data-result-target]')[0].dataset
          .resultTarget
      );
    }
  }

  // Allow child components of ResultGridCell to be overridden by components
  // that (re-)use this component.
  const defaultComponents = {
    representation: Representation,
    deLaFailleIndicator: DeLaFailleIndicator,
    previousAttributions: PreviousAttributions,
    questionableAttributions: QuestionableAttributions,
    dating: Dating,
    catalogueNumber: CatalogueNumber,
  };
  const components = { ...defaultComponents, ...overrideComponents };

  const RepresentationComponent = components.representation;
  const DeLaFailleIndicatorComponent = components.deLaFailleIndicator;
  const PreviousAttributionsComponent = components.previousAttributions;
  const QuestionableAttributionsComponent = components.questionableAttributions;
  const DatingComponent = components.dating;
  const CatalogueNumberComponent = components.catalogueNumber;

  return (
    <StyledResultGridCell className={className} onClick={clickCell}>
      <h3 className="title">
        <Link to={path} data-result-target={path}>
          {artwork.title}
        </Link>
      </h3>
      <RepresentationComponent linkedData={artwork} path={path} />
      <DeLaFailleIndicatorComponent isDlf70={artwork.isDlf70} />
      <Property className="object-category">{artwork.objectCategory}</Property>
      <PreviousAttributionsComponent
        attributions={artwork.previousAttributions}
      />
      <QuestionableAttributionsComponent
        attributions={artwork.questionableAttributions}
      />
      <DatingComponent linkedData={artwork} />
      <Property className="collection">{artwork.owner.label}</Property>
      <CatalogueNumberComponent linkedData={artwork} />
    </StyledResultGridCell>
  );
};

export default ResultGridCell;
export { Property };
