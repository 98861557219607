import styled from 'styled-components';
import GridContainer from '../layout/GridContainer';
import mediaQueries from '../style/variables/mediaQueries';
import BackButton from './BackButton';
import React from 'react';

const StyledContentHeader = styled(GridContainer)`
  padding-top: 3rem;

  .back-nav {
    margin-bottom: 2rem;
  }

  .textual {
    display: flex;
    flex-direction: column;

    .type-subtitle {
      order: -1;
    }

    th {
      padding-right: 2rem;
      width: 7rem;
      white-space: nowrap;
    }
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 142px;
    width: 142px;
    border-radius: 50%;
    svg {
      height: 3rem;
      width: 3rem;
    }

    ${mediaQueries.max_md} {
      display: none;
    }

    ${mediaQueries.lg} {
      height: 215px;
      width: 215px;

      svg {
        height: 5rem;
        width: 5rem;
      }
    }
  }
`;

const ContentHeader = ({
  title,
  subtitle,
  iconComponent,
  details,
  children,
}) => {
  const IconComponent = iconComponent;
  return (
    <StyledContentHeader forwardedAs="header">
      <nav className="back-nav grid-col">
        <BackButton>Back</BackButton>
      </nav>
      <div className="visual grid-col--md-3">
        <div className="icon-wrapper background-alternate">
          <IconComponent aria-hidden="true" width="3rem" height="3rem" />
        </div>
      </div>
      <div className="textual grid-col--md-9">
        <h1>{title}</h1>
        {subtitle && <p className="type-subtitle h3">{subtitle}</p>}
        <table>
          <tbody>
            {details.map((detail, i) => (
              <tr key={i}>
                <th>{detail.label}</th>
                <td>{detail.data}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {children}
      </div>
    </StyledContentHeader>
  );
};

export default ContentHeader;
