import getMostPreciseDate from './getMostPreciseDate';

/**
 * Gathers the most appropriate display dates for the given timespans.
 *
 * Note: returns an array of dates.
 *
 * @param timeSpans
 * @returns {[string]}
 */
export default function getDisplayDates(timeSpans) {
  let dates = [];
  if (!Array.isArray(timeSpans) || timeSpans.length === 0) {
    return dates;
  }
  // We currently only deal with one timespan.
  const timeSpan = timeSpans[0];
  if (Array.isArray(timeSpan.textual) && timeSpan.textual.length > 0) {
    dates = timeSpan.textual;
  } else {
    // Fallback to display a date range from start and end.
    dates = [getMostPreciseDate(timeSpans)];
  }
  return dates;
}
