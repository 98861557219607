import React from 'react';
import { useParams } from 'react-router-dom';
import useSpinqueData from '../hooks/useSpinqueData';
import { Helmet } from 'react-helmet/es/Helmet';
import LoadingPlaceholder from '../components/LoadingPlaceholder';
import ArtworkTuple from '../utils/Spinque/ArtworkTuple';
import ArtworkBasicInformation from '../components/ArtworkBasicInformation';
import ArtworkDetails from '../components/ArtworkDetails';
import GridContainer from '../layout/GridContainer';
import ArtworkImagesContainer from '../components/ArtworkImagesContainer';
import BackButton from '../components/BackButton';
import styled from 'styled-components';
import getContentDeLaFaille from '../utils/Display/ArtworkDetails/getContentDeLaFaille';
import getContentObjectDetails from '../utils/Display/ArtworkDetails/getContentObjectDetails';
import getContentLetters from '../utils/Display/ArtworkDetails/getContentLetters';
import { colors } from '../style/variables/colors';
import { halfGutters } from '../style/variables/sizes';
import slugify from 'slugify';
import mediaQueries from '../style/variables/mediaQueries';
import hasAtLeastOneDataItem from '../utils/Display/hasAtLeastOneDataItem';
import TechnicalResearch from '../utils/Spinque/TechnicalResearch';
import Literature from '../utils/Spinque/Literature';
import Exhibitions from '../utils/Spinque/Exhibitions';
import RelatedArtworks from '../utils/Spinque/RelatedArtworks';
import Representations from '../utils/Spinque/Representations';
import getRenderedRepresentation from '../utils/Display/getRenderedRepresentation';
import Provenance from '../utils/Spinque/Provenance';
import LetterTuple from '../utils/Spinque/LetterTuple';
import Survey from "../components/Survey";

function renderTechnicalResearchItem(item) {
  if (!item.representation || !item.representation.uri) {
    return item;
  }
  return {
    ...item,
    representation: getRenderedRepresentation(item.representation),
  };
}

const ArtworkContainer = () => {
  const id = encodeURIComponent(useParams().id);
  const {
    data: [
      artwork,
      letters,
      research,
      researchRce,
      literature,
      exhibitions,
      siblings,
      provenance,
    ],
    isLoading,
    isError,
  } = useSpinqueData([
    `/q/artwork/p/id/${id}/results`,
    {
      path: `/q/artwork/p/id/${id}/e/letters/results`,
      params: ['count=200'],
    },
    {
      path: `/q/artwork/p/id/${id}/e/technical/results`,
      params: ['count=200'],
    },
    {
      path: `/q/artwork/p/id/${id}/e/technical_rce/results`,
      params: ['count=200'],
    },
    {
      path: `/q/artwork/p/id/${id}/e/literature/results`,
      params: ['count=200'],
    },
    {
      path: `/q/artwork/p/id/${id}/e/used_for_exhibition/results`,
      params: ['count=200'],
    },
    {
      path: `/q/artwork/p/id/${id}/e/siblings/results`,
      params: ['count=200'],
    },
    {
      path: `/q/artwork/p/id/${id}/e/provenance/results`,
      params: ['count=200'],
    },
  ]);

  const hasData = typeof artwork?.items !== 'undefined';
  const foundArtwork = typeof artwork?.items?.[0]?.tuple?.[0] !== 'undefined';
  const foundExtraDataSet =
    typeof artwork?.items?.[1]?.tuple?.[0] !== 'undefined';
  const foundLetters = typeof letters?.items?.[0]?.tuple?.[0] !== 'undefined';
  const artworkRepresentations = new Representations();

  let deLaFailleLinkedData = null;
  let artworkLinkedData = null;

  // This array is going to contain the data that will be rendered as the
  // sections with artwork details. The rendered order of sections is determined
  // by the order in which new elements are pushed onto this array. (I consider
  // this a rather ugly way to control the order but we can improve it later.)
  let detailedArtworkData = [];

  if (hasData && foundArtwork) {
    // When multiple datasets are given, the DLF70 dataset is the first.
    if (foundExtraDataSet) {
      deLaFailleLinkedData = new ArtworkTuple(artwork.items[0].tuple[0]);
      artworkLinkedData = new ArtworkTuple(artwork.items[1].tuple[0]);
    } else {
      artworkLinkedData = new ArtworkTuple(artwork.items[0].tuple[0]);
    }

    // Artworks can have multiple representations from multiple data sources.
    artworkLinkedData.representations.forEach(representation => {
      artworkRepresentations.addRepresentation(
        representation.uri,
        representation.label,
        representation.description,
        representation.isIiif
      );
    });

    const source = artworkLinkedData.sourceAttribution;

    const lettersLinkedData = foundLetters
      ? letters.items.map(letterData => {
          return new LetterTuple(letterData.tuple[0]);
        })
      : [];

    if (!artworkLinkedData.isDlf70) {
      detailedArtworkData.push({
        heading: { title: 'Object details', source },
        tabularContent: getContentObjectDetails(artworkLinkedData),
        collapsible: 'max-lg',
      });
    }

    // Only show the De la Faille section on artworks that have additional datasets.
    let dlfTabularContent = null;
    let dlfSource = null;
    let dlf70isCollapsed = 'always';
    if (deLaFailleLinkedData) {
      dlfTabularContent = getContentDeLaFaille(deLaFailleLinkedData);
      dlfSource = deLaFailleLinkedData.sourceAttribution;
    } else if (artworkLinkedData.isDlf70) {
      dlfTabularContent = getContentDeLaFaille(artworkLinkedData);
      dlfSource = artworkLinkedData.sourceAttribution;
      dlf70isCollapsed = 'max-lg';
    }

    if (dlfTabularContent && dlfSource) {
      detailedArtworkData.push({
        heading: {
          title: 'De la Faille 1970',
          source: dlfSource,
        },
        tabularContent: dlfTabularContent,
        collapsible: dlf70isCollapsed,
      });
    }

    // Temporarily disable inscriptions.
    // @see https://redmine.limoengroen.nl/issues/44327
    //const inscriptionsData = artworkLinkedData.inscriptions;
    //if (hasAtLeastOneDataItem(inscriptionsData)) {
    //  detailedArtworkData.push({
    //    heading: { title: 'Inscriptions', source },
    //    tabularContent: inscriptionsData,
    //    collapsible: 'max-lg',
    //  });
    //}

    const letterData = getContentLetters(lettersLinkedData);
    if (hasAtLeastOneDataItem(letterData)) {
      detailedArtworkData.push({
        heading: {
          title: 'Letters',
          source: 'Vincent van Gogh - The Letters (http://vangoghletters.org)',
        },
        tabularContent: letterData,
        collapsible: 'max-lg',
      });
    }

    const provenanceTabularData = new Provenance(provenance).items;
    const hasProvenanceTabularContent = hasAtLeastOneDataItem(
      provenanceTabularData
    );
    if (
      hasProvenanceTabularContent ||
      artworkLinkedData.provenanceDescription
    ) {
      detailedArtworkData.push({
        heading: { title: 'Provenance', source: source },
        tabularContent: provenanceTabularData,
        collapsible: 'max-lg',
        textualContent: {
          label: 'Provenance (description)',
          value: artworkLinkedData.provenanceDescription,
        },
      });
    }

    const exhibitionData = new Exhibitions(exhibitions).items;
    if (hasAtLeastOneDataItem(exhibitionData)) {
      detailedArtworkData.push({
        heading: { title: 'Exhibitions', source },
        tabularContent: exhibitionData,
        collapsible: 'max-lg',
      });
    }

    const publicationData = new Literature(literature).items;
    if (
      hasAtLeastOneDataItem(publicationData) ||
      artworkLinkedData.bibliographyDescription
    ) {
      detailedArtworkData.push({
        heading: { title: 'Bibliography', source },
        tabularContent: publicationData,
        collapsible: 'max-lg',
        textualContent: {
          label: 'Bibliography (description)',
          value: artworkLinkedData.bibliographyDescription,
        },
      });
    }

    const researchData = new TechnicalResearch(research);
    if (hasAtLeastOneDataItem(researchData.items)) {
      const items = researchData.items.map(renderTechnicalResearchItem);

      // Also add representations from Technical research.
      researchData.representations.forEach(representation => {
        artworkRepresentations.addRepresentation(
          representation.uri,
          representation.label,
          representation.description,
          representation.isIiif
        );
      });
      detailedArtworkData.push({
        heading: {
          title: 'Technical documentation',
          source: source,
        },
        listContent: items,
        collapsible: 'max-lg',
      });
    }

    // Add research data by the Cultural Heritage Agency as a seperate item.
    // To be merge in the future with technical research data. This requires a
    // solution for the per item, or per section attribution.
    const researchRceData = new TechnicalResearch(researchRce);
    if (hasAtLeastOneDataItem(researchRceData.items)) {
      const items = researchRceData.items.map(renderTechnicalResearchItem);

      // Also add representations from Technical research via RCE.
      researchRceData.representations.forEach(representation => {
        artworkRepresentations.addRepresentation(
          representation.uri,
          representation.label,
          representation.description,
          representation.isIiif
        );
      });

      detailedArtworkData.push({
        heading: {
          title: 'Additional technical documentation',
          source: 'Cultural Heritage Agency',
        },
        listContent: items,
        collapsible: 'max-lg',
      });
    }

    const relatedData = new RelatedArtworks(siblings).items;
    if (hasAtLeastOneDataItem(relatedData)) {
      detailedArtworkData.push({
        heading: { title: 'Related works', source },
        listContent: relatedData,
        listClass: 'list-content__list--with-separator',
        collapsible: 'max-lg',
      });
    }
  }

  return artworkLinkedData ? (
    <Artwork
      basicArtworkData={artworkLinkedData}
      detailedArtworkData={detailedArtworkData}
      representations={artworkRepresentations}
    />
  ) : (
    <GridContainer>
      <Helmet>
        <title>{'Loading artwork'}</title>
      </Helmet>
      <div className="grid-col">
        {isLoading && <LoadingPlaceholder />}
        {!isLoading && isError && <p>An error occurred.</p>}
        {!isLoading && !isError && !foundArtwork && (
          <p>The artwork is unknown.</p>
        )}
      </div>
    </GridContainer>
  );
};

const ArtworkBasicsWrapper = styled(GridContainer)`
  padding-top: 2rem;
  padding-bottom: 3rem;
  ${mediaQueries.md} {
    padding-top: 3rem;
    padding-bottom: 4rem;
    display: grid;
    grid-template-columns: 5fr 7fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
  }

  .artwork-header {
    display: flex;
    flex-direction: column;
  }
  .artwork-title {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    ${mediaQueries.md} {
      margin-top: 3rem;
    }
  }

  .artwork-images {
    ${mediaQueries.md} {
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 2;
    }
  }

  .go-back {
    order: -1;
    width: max-content;
  }

  .artwork-basics {
    margin-top: 2rem;
    ${mediaQueries.md} {
      margin-top: 0;
    }
  }
`;

const ArtworkDetailsWrapper = styled(GridContainer)`
  position: relative;

  .artwork-details-navigation {
    display: none;
    ${mediaQueries.lg} {
      display: block;
      position: sticky;
      top: 0;
      padding: 24px ${halfGutters.lg};
    }

    h2 {
      font-size: 1.125rem;
      padding-bottom: 1rem;
      margin: 0 ${halfGutters.lg};
      border-bottom: 1px solid ${colors.border};
    }

    li {
      margin: 12px 0;
    }

    a {
      display: block;
      padding: 4px ${halfGutters.lg};
      line-height: 2;
      color: ${colors.ink};
      text-decoration: none;

      &:hover,
      &.active {
        border-radius: 2px;
        background-color: rgba(0, 0, 0, 0.1);
        color: ${colors.ink};
      }
      &:visited,
      &:active {
        color: ${colors.ink};
      }
    }
  }

  .artwork-details-sections {
    > * + * {
      margin-top: 8px;

      ${mediaQueries.lg} {
        margin-top: 40px;
      }
    }
    // Textual content is always connected to the preceding element.
    > .textual-content {
      margin-top: 0;
    }
  }
`;

const Artwork = ({
  basicArtworkData,
  detailedArtworkData,
  representations,
}) => {
  return (
    <article>
      <Helmet>
        <title>{basicArtworkData.title}</title>
      </Helmet>
      <ArtworkBasicsWrapper className="artwork-basics-wrapper">
        <header className="grid-col artwork-header">
          <h1 className="artwork-title">{basicArtworkData.title}</h1>
          <BackButton>Go back</BackButton>
        </header>
        <div className="grid-col artwork-images">
          <ArtworkImagesContainer
            title={basicArtworkData.title}
            description={`Available representations of the artwork ${basicArtworkData.title}`}
            representations={representations}
          />
        </div>
        <div className="grid-col artwork-basics">
          <ArtworkBasicInformation linkedData={basicArtworkData} />
        </div>
      </ArtworkBasicsWrapper>
      <Survey/>
      <ArtworkDetailsWrapper className="artwork-details-wrapper">
        <div className="grid-col--lg-3">
          <nav
            className="artwork-details-navigation background-alternate"
            aria-labelledby="artwork-details-navigation__header"
          >
            <h2 id="artwork-details-navigation__header">Contents</h2>
            <ul className="unstyled-list">
              {detailedArtworkData.map((data, i) => (
                <li key={i}>
                  <a href={`#${slugify(data.heading.title)}`}>
                    {data.heading.title}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="grid-col--lg-9 artwork-details-sections">
          {detailedArtworkData.map((data, i) => (
            <ArtworkDetails
              key={i}
              data={data}
              id={slugify(data.heading.title)}
            />
          ))}
        </div>
      </ArtworkDetailsWrapper>
    </article>
  );
};

export default ArtworkContainer;
