import React from 'react';
import styled from 'styled-components';
import mediaQueries from '../style/variables/mediaQueries';
import { halfGutters } from '../style/variables/sizes';

const StyledGridContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1360px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 ${halfGutters.xs};

  &.hidden {
    display: none;
  }

  ${mediaQueries.sm} {
    padding: 0 ${halfGutters.sm};
  }
  ${mediaQueries.md} {
    padding: 0 ${halfGutters.md};
  }
  ${mediaQueries.lg} {
    padding: 0 ${halfGutters.lg};
  }
  ${mediaQueries.xl} {
    padding: 0 ${halfGutters.xl};
  }

  // Any 'grid-col' or 'grid-col--*' class will apply padding to align it to the
  // page margins. It will assume full width unless specified otherwise.
  > [class^='grid-col'],
  > [class*=' grid-col'] {
    width: 100%;
    padding: 0 ${halfGutters.xs};
    ${mediaQueries.sm} {
      padding: 0 ${halfGutters.sm};
    }
    ${mediaQueries.md} {
      padding: 0 ${halfGutters.md};
    }
    ${mediaQueries.lg} {
      padding: 0 ${halfGutters.lg};
    }
    ${mediaQueries.xl} {
      padding: 0 ${halfGutters.xl};
    }
  }

  // Use 'grid-col--fluid' for elements that need to respect gutters but are not
  // aligned to the grid columns.
  .grid-col--fluid {
    width: auto;
  }

  ${mediaQueries.sm} {
    .grid-col--sm-5 {
      width: 41.6667%;
    }
    .grid-col--sm-6 {
      width: 50%;
    }
    .grid-col--sm-7 {
      width: 58.3333%;
    }
  }

  ${mediaQueries.md} {
    .grid-col--md-2 {
      width: 16.6667%;
    }
    .grid-col--md-3 {
      width: 25%;
    }
    .grid-col--md-4 {
      width: 33.3333%;
    }
    .grid-col--md-8 {
      width: 66.6667%;
    }
    .grid-col--md-9 {
      width: 75%;
    }
  }

  ${mediaQueries.lg} {
    .grid-col--lg-1 {
      width: 8.3333%;
    }
    .grid-col--lg-2 {
      width: 16.6667%;
    }
    .grid-col--lg-3 {
      width: 25%;
    }
    .grid-col--lg-5 {
      width: 41.6667%;
    }
    .grid-col--lg-6 {
      width: 50%;
    }
    .grid-col--lg-7 {
      width: 58.3333%;
    }
    .grid-col--lg-8 {
      width: 66.6667%;
    }
    .grid-col--lg-9 {
      width: 75%;
    }
    .grid-col--lg-10 {
      width: 83.3333%;
    }
  }

  ${mediaQueries.xl} {
    .grid-col--xl-2 {
      width: 16.6667%;
    }
    .grid-col--xl-10 {
      width: 83.3333%;
    }
  }
`;

const GridContainer = ({ className, children, as }) => {
  return (
    <StyledGridContainer
      as={as}
      className={`grid-container ${className ?? ''}`}
    >
      {children}
    </StyledGridContainer>
  );
};

export default GridContainer;
