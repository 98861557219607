import React from 'react';
import { useLocation } from 'react-router-dom';
import Mirador from '../components/Mirador';

export default function Viewer() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const manifest = query.get('manifest');

  return (
    <Mirador
      config={{
        id: 'vangoghworldwide-viewer',
        thumbnailNavigation: {
          defaultPosition: 'far-bottom',
        },
      }}
      manifest={manifest}
    />
  );
}
