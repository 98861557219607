const map = {
  'http://vangoghworldwide.nl/data/identifier/delafaille': 'f',
  'http://vangoghworldwide.nl/data/identifier/jh': 'jh',
  'http://vangoghworldwide.nl/data/identifier/janhulsker': 'jh',
  'http://vangoghmuseum.nl/data/term/4200': 'painting',
  'http://vocab.getty.edu/aat/300055644': 'height',
  'http://vocab.getty.edu/aat/300055647': 'width',
  'http://vocab.getty.edu/aat/300379099': 'm',
  'http://vocab.getty.edu/aat/300379098': 'cm',
  'http://vocab.getty.edu/aat/300379097': 'mm',
  'http://vangoghmuseum.nl/data/person/38391': 'Vincent van Gogh',
  'http://vocab.getty.edu/ulan/500275558': 'Van Gogh Museum',
  'http://vocab.getty.edu/aat/300379242': 'days',
};

/**
 * Translates an iri to a readable, but still machinename value.
 * @param iri
 *   An iri from the Getty or Van Gogh Worldwide vocabularies.
 * @returns {*}
 *   A machine readable name eg 'width' or false if not found.
 */
const translateIri = iri => (map.hasOwnProperty(iri) ? map[iri] : false);

export default translateIri;
