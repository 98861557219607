/**
 * Category constants. Since these are integers, the artworksByCategory object
 * will be ordered ascending by these values.
 *
 * @type {number}
 */
export const CATEGORY_PAINTINGS = 0;
export const CATEGORY_DRAWINGS = 1;
export const CATEGORY_PRINTS = 2;

/**
 * The directory where all category images are stored.
 * @type {string}
 */
const artworksByCategoryPath =
  process.env.PUBLIC_URL + '/home-artworks-by-category/';

/**
 * Image info per category.
 * - One array for each category, containing at least one image info object.
 * - Each image info object contains a filename (without extension), the
 *   extension and an alt text.
 * - Each image must be available in standard-res and hi-res (2x) versions,
 *   named [name].[ext] and [name]@2x.[ext] respectively.
 *
 * Each month, a different image will be selected for each category. The first
 * item of a category array is used in January, the second in February, and so
 * on. If the array contains less than 12 images, it will wrap around.
 *
 * @typedef {{ext: string, altText: string, name: string}} imageinfo
 * @type {Object.<number, imageinfo[]>}
 */
const imagesByCategory = {
  [CATEGORY_PAINTINGS]: [
    {
      name: 'paintings-s0047V1962_gb',
      ext: 'jpg',
      altText: 'The Bedroom',
    },
  ],
  [CATEGORY_DRAWINGS]: [
    {
      name: 'drawings-d0446V1962_gb',
      ext: 'jpg',
      altText: 'Old Vineyard with Peasant Woman',
    },
  ],
  [CATEGORY_PRINTS]: [
    { name: 'prints-p0002V1962_gb', ext: 'jpg', altText: 'Sorrow' },
  ],
};

/**
 * Returns image information for the current month.
 * @param category A category number as defined by the category constants.
 * @returns {{altText: string, src: string}}
 */
function getCurrentImageInfo(category) {
  const monthNumber = new Date().getMonth();
  const images = imagesByCategory[category];
  // By calculating the modulo of the month number and the number of images for
  // the given category, we can iterate over the available images, changing them
  // every month.
  const remainder = monthNumber % images.length;
  const image = images[remainder];
  return {
    src: `${artworksByCategoryPath}${image.name}.${image.ext}, ${artworksByCategoryPath}${image.name}@2x.${image.ext} 2x`,
    altText: image.altText,
  };
}

/**
 * A list of data to build the artworks list with.
 *
 * @typedef {{altText: string, src: string}} imagedata
 * @typedef {{subtitle: string, title: string}} captiondata
 * @typedef {{field: string, values: [string]}} filterdata
 * @typedef {{image: imagedata, caption: captiondata, filters: [filterdata]}} artworkdata
 * @type {Object.<number, artworkdata>}
 */
export const artworksByCategory = {
  [CATEGORY_PAINTINGS]: {
    image: getCurrentImageInfo(CATEGORY_PAINTINGS),
    caption: {
      title: 'Paintings',
      subtitle: '\u200b',
    },
    filters: [
      {
        field: 'type',
        values: ['http://vocab.getty.edu/aat/300033618'],
      },
    ],
  },
  [CATEGORY_DRAWINGS]: {
    image: getCurrentImageInfo(CATEGORY_DRAWINGS),
    caption: {
      title: 'Drawings',
      subtitle: '\u200b',
    },
    filters: [
      {
        field: 'type',
        values: ['http://vocab.getty.edu/aat/300033973'],
      },
    ],
  },
  [CATEGORY_PRINTS]: {
    image: getCurrentImageInfo(CATEGORY_PRINTS),
    caption: {
      title: 'Prints',
      subtitle: '\u200b',
    },
    filters: [
      {
        field: 'type',
        values: ['http://vocab.getty.edu/aat/300041273'],
      },
    ],
  },
};
