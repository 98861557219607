export default async function request(method, path, strategy) {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  const uri = process.env.REACT_APP_SPINQUE_API_ENDPOINT;

  const response = await fetch(`${uri}${strategy}${path.path}`, {
    method: method,
    headers: headers,
  });

  let json;
  try {
    json = await response.json();
  } catch (error) {
    // Nothing to do here, certain responses won't have json
  }

  if (response.status >= 200 && response.status < 300) {
    return { type: path.type, data: json };
  } else {
    const message = json && json.error ? json.error : response.status;
    throw new Error(message);
  }
}
