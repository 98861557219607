import React from 'react';
import styled from 'styled-components';
import GridContainer from '../layout/GridContainer';
import { Helmet } from 'react-helmet/es/Helmet';
import ViewModeList from '../components/ViewModeList';
import mediaQueries from '../style/variables/mediaQueries';
import { colors } from '../style/variables/colors';
import BackButton from '../components/BackButton';
import getSortedNewsItems from "../utils/Display/getSortedNewsItems";

const StyledContent = styled.div`
  display: flex;
  margin: 3rem 0;

  .overview--aside {
    ${mediaQueries.max_md} {
      margin-bottom: 24px;
    }
  }

  .overview--main {
    > ol {
      > li {
        border-top: 1px solid ${colors.border};
        padding-top: 1rem;
        padding-bottom: 1.25rem;

        &:last-child {
          border-bottom: 1px solid ${colors.border};
        }

        a {
          text-decoration: none;
        }
      }
    }
  }
`;

const News = () => {
  const newsItems = getSortedNewsItems();
  return (
    <>
      <Helmet>
        <title>News</title>
      </Helmet>
      <StyledContent>
        <GridContainer>
          <div className="overview--aside grid-col--md-2 grid-col--lg-2">
            <BackButton>Back</BackButton>
          </div>
          <div className="overview--main grid-col--md-8 grid-col--lg-8">
            <h1>News</h1>
            <ol className="unstyled-list">
              {Object.keys(newsItems).map((key) => {
                const item = newsItems[key]
                return (
                  <li
                    key={key}
                  >
                    <a href={`news/${key}`}>
                      <ViewModeList
                        title={item.title}
                        date={item.display_date}
                        summary={item.summary}
                      />
                    </a>
                  </li>
                );
              })}
            </ol>
          </div>
        </GridContainer>
      </StyledContent>
    </>
  );
};

export default News;
