import styled from 'styled-components';
import { palette } from '../style/variables/colors';

const Intro = styled.div`
  font-size: 1.5rem;
  line-height: 1.333;
  font-weight: 700;
  color: ${palette.darkgrey};

  margin-top: 2rem;
  margin-bottom: 2rem;

  p {
    margin: 0;
  }

  a {
    color: currentColor;
  }
`;

export default Intro;
