import React from 'react';
import styled from 'styled-components';
import { Picture } from 'react-responsive-picture';

const StyledArtworkImages = styled.ul`
  li.hidden {
    display: none;
  }

  img {
    // Scale the image for an optimal fit in a rectangle of 100% parent width
    // and max 440px high, and in such a way that we do not need to set a fixed
    // height on the parent.
    width: 100%;
    max-height: 440px;
    object-fit: contain;
    margin: 0 auto;
  }
`;

/**
 *
 * Displays a list of images.
 *
 * @param Array images
 *   Image data [{uri, srcset, thumbUri, thumbSrcset, alt}]
 * @returns{*}
 *   A styled list of images or null
 */
const ArtworkImages = ({ images, activeImage }) => {
  if (!images || images.length === 0) {
    return null;
  }

  return (
    <StyledArtworkImages className="unstyled-list">
      {images.map((image, i) => {
        const src = image.srcset.join(', ');
        return (
          <li key={i} className={i === activeImage ? '' : 'hidden'}>
            <Picture src={src} alt={image.alt} />
          </li>
        );
      })}
    </StyledArtworkImages>
  );
};

export default ArtworkImages;
