import React from 'react';
import {withSearch} from '@elastic/react-search-ui';
import ResultGridCell from './ResultGridCell';
import Masonry from 'react-masonry-component';
import styled from 'styled-components';
import mediaQueries from '../style/variables/mediaQueries';
import {halfGutters} from '../style/variables/sizes';
import ArtworkTuple from '../utils/Spinque/ArtworkTuple';

const StyledResultsGrid = styled.div`
  opacity: 1;
  transition: opacity 300ms ease;
  &.loading {
    opacity: 0.3;
  }
 
  .results-grid {
    position: relative;
    padding: 2rem 0 0;
    margin: 0 -${halfGutters.xs};

    ${mediaQueries.sm} {
      margin: 0 -${halfGutters.sm};
    }

    ${mediaQueries.lg} {
      margin: 0 -${halfGutters.lg};
    }

    ${mediaQueries.xl} {
      margin: 0 -${halfGutters.xl};
    }
  }
`;

// Set the masonry.js options. Notes:
// - Since all items have the same width, we can use the itemSelector to also
//   define the (responsive) columnWidth.
// - Enable horizontalOrder to we don't change the order of the search results.
// - We don't use gutter here, but apply padding to the grid items so we can
//   make it responsive.
// - Faster transitionDuration, because the default gets boring very quickly.
const masonryOptions = {
  itemSelector: '.results-grid-item',
  columnWidth: '.results-grid-item',
  horizontalOrder: true,
  transitionDuration: 150,
};

const ResultsGrid = ({results, isLoading}) => {
  return (
      <StyledResultsGrid
          className={`search-results ${isLoading ? 'loading' : ''}`}
      >
        <Masonry
            className="results-grid"
            elementType={'ul'}
            options={masonryOptions}
        >
          {results.map(result => {
            const artwork = new ArtworkTuple(result.tuple[0]);
            return (
                <ResultGridCell
                    className="results-grid-item with-gutters"
                    // In rare cases, artworks occur twice, but still artwork.json.id
                    // should be unique.
                    key={artwork.json.id}
                    result={result}
                    artwork={artwork}
                />
            );
          })}
        </Masonry>
      </StyledResultsGrid>
  );
};

// export default Results
export default withSearch(({results, isLoading}) => ({
  results,
  isLoading,
}))(ResultsGrid);
