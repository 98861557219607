import React from 'react';
import { ReactComponent as AuctionIcon } from '../assets/images/icon-auction.svg';
import { Link } from 'react-router-dom';
import ContentHeader from './ContentHeader';

const AuctionHeader = ({ auction }) => {
  const details = [];
  if (auction.carriedOutBy) {
    details.push({
      label: 'Auction house',
      data: auction.carriedOutByActorPath ? (
        <Link to={auction.carriedOutByActorPath}>{auction.carriedOutBy}</Link>
      ) : (
        auction.carriedOutBy
      ),
    });
  }
  if (auction.location || auction.period) {
    details.push({
      label: 'Took place',
      data: [auction.location, auction.period].filter(x => x).join(', '),
    });
  }

  return (
    <ContentHeader
      title={auction.title}
      subtitle="Auction"
      details={details}
      iconComponent={AuctionIcon}
    />
  );
};

export default AuctionHeader;
