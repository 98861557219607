import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Scroll restoration component based on the solution from
 * https://reacttraining.com/react-router/web/guides/scroll-restoration.
 * Not intended for re-use; just including it once as the first child of the
 * <Router> component should suffice.
 */
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Wait the shortest delay possible before setting the window scroll
    // position. I don't quite understand why this works, but it solves scroll
    // problems when navigating back from an artwork detail page back to a
    // search results page. It seems to allow React to start rendering the DOM.
    window.setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [pathname]);

  return null;
}
