/**
 * Period constants. Since these are integers, the artworksByPeriod object
 * will be ordered ascending by these values.
 *
 * @type {number}
 */
export const PERIOD_EARLY = 0;
export const PERIOD_BORINAGE = 1;
export const PERIOD_ETTEN = 2;
export const PERIOD_THEHAGUE = 3;
export const PERIOD_DRENTHE = 4;
export const PERIOD_NUENEN = 5;
export const PERIOD_ANTWERPPARIS = 6;
export const PERIOD_ARLES = 7;
export const PERIOD_SAINTREMY = 8;
export const PERIOD_AUVERS = 9;

/**
 * The directory where all period images are stored.
 * @type {string}
 */
const artworksByPeriodPath =
  process.env.PUBLIC_URL + '/home-artworks-by-period/';

/**
 * Image info per period.
 * - One array for each period, containing at least one image info object.
 * - Each image info object contains a filename (without extension), the
 *   extension and an alt text.
 * - Each image must be available in standard-res and hi-res (2x) versions,
 *   named [name].[ext] and [name]@2x.[ext] respectively.
 *
 * Each month, a different image will be selected for each period. The first
 * item of a period array is used in January, the second in February, and so on.
 * If the array contains less than 12 images, it will wrap around.
 *
 * @typedef {{ext: string, altText: string, name: string}} imageinfo
 * @type {Object.<number, [imageinfo]>}
 *
 * For now a lot of images have been disabled, so we can launch with a fixed set
 * of images per category. Monthly changing images will be restored in a later
 * sprint. See issue https://redmine.limoengroen.nl/issues/42950.
 * @todo Add new images and re-enable commented ones.
 */
const imagesByPeriod = {
  [PERIOD_EARLY]: [
    {
      name: 'drawings/Early-Period-d0292V1972_gb',
      ext: 'jpg',
      altText: 'View of Royal Road, Ramsgate',
    },
  ],
  [PERIOD_BORINAGE]: [
    {
      name: 'drawings/Borinage-F831-KM-111.966',
      ext: 'jpg',
      altText: 'Miners in the snow',
    },
  ],
  [PERIOD_ETTEN]: [
    {
      name: 'drawings/Etten-F851-KM-111.847',
      ext: 'jpg',
      altText: 'Boy with a sickle',
    },
  ],
  [PERIOD_THEHAGUE]: [
    // {
    //   name: 'drawings/Den-Haag-F939-KM-116.039',
    //   ext: 'jpg',
    //   altText: "Carpenter's yard and laundry",
    // },
    {
      name: 'paintings/Den-Haag-F8-KM-107.592',
      ext: 'jpg',
      altText: 'Girl in a wood',
    },
    // {
    //   name: 'paintings/Den-Haag-F62-KM-109.323',
    //   ext: 'jpg',
    //   altText: 'Still life with straw hat',
    // },
  ],
  [PERIOD_DRENTHE]: [
    {
      name: 'drawings/Drenthe-F1097-KM-121.207',
      ext: 'jpg',
      altText: 'Sod hut (Peat shed at night)',
    },
  ],
  [PERIOD_NUENEN]: [
    // {
    //   name: 'paintings/Nuenen-F78-KM-109.982',
    //   ext: 'jpg',
    //   altText: 'The potato eaters',
    // },
    // {
    //   name: 'drawings/Nuenen-F1299-KM124.090',
    //   ext: 'jpg',
    //   altText: 'Man and woman digging',
    // },
    // {
    //   name: 'paintings/Nuenen-F85-KM-100.285',
    //   ext: 'jpg',
    //   altText: 'Head of a woman wearing a white cap',
    // },
    {
      name: 'drawings/Nuenen-F1323-KM-126.880',
      ext: 'jpg',
      altText: 'Reaper',
    },
  ],
  [PERIOD_ANTWERPPARIS]: [
    // {
    //   name: 'paintings/Parijs-F266-KM-109.824',
    //   ext: 'jpg',
    //   altText: 'The hill of Montmartre',
    // },
    {
      name: 'paintings/Parijs-F378-KM-108.800',
      ext: 'jpg',
      altText: 'Basket of apples',
    },
  ],
  [PERIOD_ARLES]: [
    // {
    // name: 'drawings/Arles-F1444-KM-112.856',
    // ext: 'jpg',
    // altText: "Laundry on 'La Roubine du Roi' canal",
    // },
    {
      name: 'paintings/Arles-F397-KM-111.056',
      ext: 'jpg',
      altText: 'Bridge at Arles (Pont de Langlois)',
    },
    // {
    //   name: 'paintings/Arles-F467-KM-108.565',
    //   ext: 'jpg',
    //   altText: 'Terrace of a café at night (Place du Forum)',
    // },
  ],
  [PERIOD_SAINTREMY]: [
    // {
    //   name: 'paintings/SaintRemy-F585-KM104.278',
    //   ext: 'jpg',
    //   altText: 'Olive grove',
    // },
    {
      name: 'drawings/SaintRemy-F1728-KM-125.482',
      ext: 'jpg',
      altText: 'Enclosed wheat field with sun and cloud',
    },
    // {
    //   name: 'paintings/SaintRemy-F620-KM-103.931',
    //   ext: 'jpg',
    //   altText: 'Cypresses with two figures',
    // },
  ],
  [PERIOD_AUVERS]: [
    {
      name: 'paintings/Auvers-sur-Oise-s0106V1962_gb_z',
      ext: 'jpg',
      altText: 'Wheatfield under Thunderclouds',
    },
  ],
};

/**
 * Returns image information for the current month.
 * @param period A period number as defined by the period constants.
 * @returns {{altText: string, src: string}}
 */
function getCurrentImageInfo(period) {
  const monthNumber = new Date().getMonth();
  const images = imagesByPeriod[period];
  // By calculating the modulo of the month number and the number of images for
  // the given period, we can iterate over the available images, changing them
  // every month.
  const remainder = monthNumber % images.length;
  const image = images[remainder];
  return {
    src: `${artworksByPeriodPath}${image.name}.${image.ext}, ${artworksByPeriodPath}${image.name}@2x.${image.ext} 2x`,
    altText: image.altText,
  };
}

/**
 * A list of data to build the artworks list with.
 *
 * @typedef {{altText: string, src: string}} imagedata
 * @typedef {{subtitle: string, title: string}} captiondata
 * @typedef {{field: string, values: [string]}} filterdata
 * @typedef {{image: imagedata, caption: captiondata, filters: [filterdata]}} artworkdata
 * @type {Object.<number, artworkdata>}
 */
export const artworksByPeriod = {
  [PERIOD_EARLY]: {
    image: getCurrentImageInfo(PERIOD_EARLY),
    caption: { title: 'Early period', subtitle: '-1878' },
    filters: [
      {
        field: 'period',
        values: ['https://vangoghworldwide.org/data/period/early_period'],
      },
    ],
  },
  [PERIOD_BORINAGE]: {
    image: getCurrentImageInfo(PERIOD_BORINAGE),
    caption: { title: 'Borinage/Brussels', subtitle: '1878-81' },
    filters: [
      {
        field: 'period',
        values: ['https://vangoghworldwide.org/data/period/brussel-1878'],
      },
    ],
  },
  [PERIOD_ETTEN]: {
    image: getCurrentImageInfo(PERIOD_ETTEN),
    caption: { title: 'Etten', subtitle: '1881' },
    filters: [
      {
        field: 'period',
        values: ['https://vangoghworldwide.org/data/period/etten-1881'],
      },
    ],
  },
  [PERIOD_THEHAGUE]: {
    image: getCurrentImageInfo(PERIOD_THEHAGUE),
    caption: { title: 'The Hague', subtitle: '1881-83' },
    filters: [
      {
        field: 'period',
        values: ['https://vangoghworldwide.org/data/period/den_haag-1881'],
      },
    ],
  },
  [PERIOD_DRENTHE]: {
    image: getCurrentImageInfo(PERIOD_DRENTHE),
    caption: { title: 'Drenthe', subtitle: '1883' },
    filters: [
      {
        field: 'period',
        values: ['https://vangoghworldwide.org/data/period/drenthe-1883'],
      },
    ],
  },
  [PERIOD_NUENEN]: {
    image: getCurrentImageInfo(PERIOD_NUENEN),
    caption: { title: 'Nuenen', subtitle: '1883-85' },
    filters: [
      {
        field: 'period',
        values: ['https://vangoghworldwide.org/data/period/nuenen-1883'],
      },
    ],
  },
  [PERIOD_ANTWERPPARIS]: {
    image: getCurrentImageInfo(PERIOD_ANTWERPPARIS),
    caption: { title: 'Antwerp/Paris', subtitle: '1885-88' },
    filters: [
      {
        field: 'period',
        values: ['https://vangoghworldwide.org/data/period/antwerp_paris-1885'],
      },
    ],
  },
  [PERIOD_ARLES]: {
    image: getCurrentImageInfo(PERIOD_ARLES),
    caption: { title: 'Arles', subtitle: '1888-89' },
    filters: [
      {
        field: 'period',
        values: ['https://vangoghworldwide.org/data/period/arles-1888'],
      },
    ],
  },
  [PERIOD_SAINTREMY]: {
    image: getCurrentImageInfo(PERIOD_SAINTREMY),
    caption: { title: 'Saint-Rémy', subtitle: '1889-90' },
    filters: [
      {
        field: 'period',
        values: ['https://vangoghworldwide.org/data/period/saint-remy-1889'],
      },
    ],
  },
  [PERIOD_AUVERS]: {
    image: getCurrentImageInfo(PERIOD_AUVERS),
    caption: { title: 'Auvers-sur-Oise', subtitle: '1890' },
    filters: [
      {
        field: 'period',
        values: [
          'https://vangoghworldwide.org/data/period/auvers-sur-oise-1890',
        ],
      },
    ],
  },
};
