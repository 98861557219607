import React from 'react';
import { createRoot } from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet/es/Helmet';
import './style/styleguide.css';
import Home from './routes/Home';
import About from './routes/About';
import Contact from './routes/Contact';
import Disclaimer from './routes/Disclaimer';
import FAQ from './routes/FAQ';
import PrivacyPolicy from './routes/PrivacyPolicy';
import Search from './routes/Search';
import * as serviceWorker from './serviceWorker';
import Artwork from './routes/Artwork';
import JpathHelper from './routes/JpathHelper';
import PageHeader from './components/PageHeader';
import PageFooter from './components/PageFooter';
import Page from './layout/Page';
import PageDoormat from './components/PageDoormat';
import ContributingPartners from './routes/ContributingPartners';
import Viewer from './routes/Viewer';
import ScrollToTop from './utils/ScrollToTop';
import Exhibition from './routes/Exhibition';
import Auction from './routes/Auction';
// Polyfill for legacy Edge.
import 'fast-text-encoding';
import Analytics from './components/Analytics';
import { install as gtagInstall } from 'ga-gtag';
import NotFound from './routes/NotFound';
import Actor from './routes/Actor';
import NewsItem from './routes/NewsItem';
import NewsOverview from './routes/NewsOverview';

// Initialize gtag.js for configured environments.
if (process.env.REACT_APP_GTAG_ID !== '0') {
  gtagInstall(process.env.REACT_APP_GTAG_ID);
}

const app = (
  <React.StrictMode>
  <Router>
    <ScrollToTop />
    {process.env.REACT_APP_GTAG_ID !== '0' && <Analytics />}
    <Page
      header={<PageHeader />}
      doormat={<PageDoormat />}
      footer={<PageFooter />}
    >
      <Helmet
        defaultTitle="Van Gogh Worldwide"
        titleTemplate="%s | Van Gogh Worldwide"
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route
          exact
          path="/contributing-partners"
          element={<ContributingPartners />}
        />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/search" element={<Search />} />
        <Route path="/artwork/:id" element={<Artwork />} />
        <Route path="/exhibition/:id" element={<Exhibition />} />
        <Route path="/auction/:id" element={<Auction />} />
        <Route path="/actor/:id" element={<Actor />} />
        <Route path="/news" element={<NewsOverview />} />
        <Route path="/news/:id" element={<NewsItem />} />
        <Route path="/viewer" element={<Viewer />} />
        {process.env.REACT_APP_JMESPATH_DEBUG === 'true' && (
          <Route path="/dev/path/:id" element={<JpathHelper />} />
        )}
        <Route element={<NotFound />} />
      </Routes>
    </Page>
  </Router>
  </React.StrictMode>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
