import { Component } from 'react';
import { MultiCheckboxFacet } from '@elastic/react-search-ui-views';
import { helpers } from '@elastic/search-ui';
import { accentFold } from '@elastic/react-search-ui/lib/esm/helpers';
import { withSearch } from '@elastic/react-search-ui';
import getFilterLabelDisplay from '../utils/Display/getFilterLabelDisplay';

const { markSelectedFacetValuesFromFilters } = helpers;

export class FacetContainer extends Component {

  static defaultProps = {
    filterType: 'all',
    isFilterable: false,
  };

  constructor({ show = 5, collapsible = false, collapsed = false }) {
    super();
    this.state = {
      more: show,
      searchTerm: '',
      collapsed: collapsible ? collapsed : false,
    };
  }

  /**
   * Always open and fully show collapsible facets with a selected value.
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    // Prevent infinite loops.
    if (prevProps === this.props) {
      return;
    }
    // Only operate on collapsible facets.
    if (!this.props.collapsible) {
      return;
    }

    if (this.props.collapsed && this.state.collapsed) {
      const { facets, filters, field, filterType } = this.props;
      if (field && facets.hasOwnProperty(field)) {
        const facetsForField = facets[field];
        const facet = facetsForField[0];
        if (facet) {
          let facetValues = markSelectedFacetValuesFromFilters(
            facet,
            filters,
            field,
            filterType
          ).data;

          const selectedValues = facetValues
            .filter(fv => fv.selected)
            .map(fv => fv.value);

          if (selectedValues.length) {
            this.setState({
              collapsed: false,
              more: facetValues.length,
            });
          }
        }
      }
    }
  }

  handleClickMore = totalOptions => {
    this.setState(({ more }) => {
      // Disabling complex behavior; just show all.
      //let visibleOptionsCount = more + 10;
      //const showingAll = visibleOptionsCount >= totalOptions;
      //if (showingAll) visibleOptionsCount = totalOptions;
      const visibleOptionsCount = totalOptions;

      this.props.a11yNotify('moreFilters', {
        visibleOptionsCount,
        showingAll: true,
      });

      return { more: visibleOptionsCount };
    });
  };

  handleFacetSearch = searchTerm => {
    this.setState({ searchTerm });
  };

  handleOnCollapseClick = () => {
    this.setState(prevState => ({ collapsed: !prevState.collapsed }));
  };

  render() {
    const { more, searchTerm } = this.state;
    const {
      addFilter,
      className,
      facets,
      field,
      filterType,
      filters,
      label,
      removeFilter,
      setFilter,
      view,
      isFilterable,
      // eslint-disable-next-line no-unused-vars
      a11yNotify,
      collapsible,
      collapsed,
      sortFacetValues,
      rewriteLabel,
      show,
      ...rest
    } = this.props;
    const facetsForField = facets[field];

    if (!facetsForField) return null;

    // By using `[0]`, we are currently assuming only 1 facet per field. This will likely be enforced
    // in future version, so instead of an array, there will only be one facet allowed per field.
    const facet = facetsForField[0];

    let facetValues = markSelectedFacetValuesFromFilters(
      facet,
      filters,
      field,
      filterType
    ).data;

    const valuesShown = show || 5;
    const showSearch =
      isFilterable && facetValues.length > Math.min(valuesShown, 10);

    const selectedValues = facetValues
      .filter(fv => fv.selected)
      .map(fv => fv.value);

    if (!facetValues.length && !selectedValues.length) return null;

    if (sortFacetValues && sortFacetValues instanceof Function) {
      facetValues = sortFacetValues(facetValues);
    }

    if (rewriteLabel && rewriteLabel instanceof Function) {
      facetValues.forEach(
        element => (element.label = rewriteLabel(element.label))
      );
    }

    if (searchTerm.trim()) {
      facetValues = facetValues.filter(option =>
        accentFold(getFilterLabelDisplay(option))
          .toLowerCase()
          .includes(accentFold(searchTerm).toLowerCase())
      );
    }

    const View = view || MultiCheckboxFacet;

    return View({
      className,
      label: label,
      onMoreClick: this.handleClickMore.bind(this, facetValues.length),
      collapsible: collapsible,
      collapsed: this.state.collapsed,
      onCollapseClick: this.handleOnCollapseClick.bind(this),
      onRemove: value => {
        removeFilter(field, value, filterType);
      },
      onChange: value => {
        setFilter(field, value, filterType);
      },
      onSelect: value => {
        addFilter(field, value, filterType);
      },
      options: facetValues.slice(0, more),
      showMore: facetValues.length > more,
      values: selectedValues,
      showSearch,
      onSearch: value => {
        this.handleFacetSearch(value);
      },
      searchPlaceholder: `Filter ${label.toLowerCase()}`,
      ...rest,
    });
  }
}

export default withSearch(
  ({ filters, facets, addFilter, removeFilter, setFilter, a11yNotify }) => ({
    filters,
    facets,
    addFilter,
    removeFilter,
    setFilter,
    a11yNotify,
  })
)(FacetContainer);
