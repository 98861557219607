import React from 'react';
import { withSearch } from '@elastic/react-search-ui';
import ResultTableRow from './ResultTableRow';

const ResultsTable = ({ results }) => {
  return (
    <div className="search-results">
      <table>
        <caption>Search results</caption>
        <thead>
          <tr>
            <th>Image</th>
            <th>Title</th>
            <th>Object Category</th>
            <th>Date</th>
            <th>Period</th>
            <th>Collection</th>
            <th>Cat. No.</th>
          </tr>
        </thead>
        <tbody>
          {results.map(result => (
            <ResultTableRow key={result.tuple[0].id} result={result} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

// export default Results
export default withSearch(({ results }) => ({ results }))(ResultsTable);
