import parseIso from 'date-fns/parseISO';
import {
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameDay,
  isSameMonth,
  isSameYear,
  lastDayOfYear,
  startOfYear,
} from 'date-fns';
import getFormattedDate from './getFormattedDate';
import getNormalizedDate from './getNormalizedDate';

/**
 * Gets the most precise date for display based on the given timespan.
 *
 * Eg if a timespan spans 01 and 31 of the same month, only display month, year
 * as this timespan indicates that a more precise date is not known.
 *
 * @param {[]} timeSpans
 *   An array of timespans. All but the first are ignored.
 *
 * @returns {string}
 *   The most precise date possible.
 */
export default function getMostPreciseDate(timeSpans) {
  if (!Array.isArray(timeSpans) || timeSpans.length === 0) {
    return '';
  }

  // The raw start and end data may be stored under various property names.
  const timeSpan = timeSpans[0];
  let start =
    timeSpan?.start ||
    timeSpan?.begin_of_the_begin ||
    timeSpan?.['http://schema.org/startDate'] ||
    null;
  let end =
    timeSpan?.end ||
    timeSpan?.end_of_the_end ||
    timeSpan?.['http://schema.org/endDate'] ||
    null;

  // Return an empty string if we didn't find any usable data.
  if (start === null && end === null) {
    return '';
  }

  // Make start and end date the same if one of the two is not present.
  if (start === null) {
    start = end;
  }
  if (end === null) {
    end = start;
  }

  // At this point, we have finally discovered and cleaned up the data we need.
  // Now we start comparing and interpreting the start and end date, and format
  // accordingly.
  const startDate = parseIso(getNormalizedDate(start));
  const endDate = parseIso(getNormalizedDate(end));
  let preciseDate = '';

  if (isSameDay(startDate, endDate)) {
    if (isSameDay(startDate, startOfYear(startDate))) {
      // Print only year.
      preciseDate = getFormattedDate(startDate, 'year');
    } else if (isFirstDayOfMonth(startDate)) {
      // Print full month and year.
      preciseDate = getFormattedDate(startDate, 'month');
    } else {
      // Print day, full month, year.
      preciseDate = getFormattedDate(startDate);
    }
  } else if (isSameMonth(startDate, endDate)) {
    if (isFirstDayOfMonth(startDate) && isLastDayOfMonth(endDate)) {
      // Print month full & year
      preciseDate = getFormattedDate(startDate, 'month');
    } else {
      // Print full month  year
      preciseDate = `${getFormattedDate(
        startDate,
        'day',
        'day'
      )} - ${getFormattedDate(endDate)}`;
    }
  } else if (isSameYear(startDate, endDate)) {
    if (
      isSameDay(startDate, startOfYear(startDate)) &&
      isSameDay(endDate, lastDayOfYear(endDate))
    ) {
      // Display year.
      preciseDate = getFormattedDate(startDate, 'year');
    } else {
      // Display sMonth - eMonth Year if sDay and eDay are begin / end of the month indicating uncertainty.
      if (isFirstDayOfMonth(startDate) && isLastDayOfMonth(endDate)) {
        // Display sDay sMonth - eDay eMonth Year
        preciseDate = `${getFormattedDate(
          startDate,
          'month',
          'month'
        )} - ${getFormattedDate(endDate, 'month')}`;
      } else {
        // Display sDay sMonth - eDay eMonth Year
        preciseDate = `${getFormattedDate(
          startDate,
          'day',
          'month'
        )} - ${getFormattedDate(endDate)}`;
      }
    }
  } else {
    if (
      isSameDay(startDate, startOfYear(startDate)) &&
      isSameDay(endDate, lastDayOfYear(endDate))
    ) {
      preciseDate = `${getFormattedDate(
        startDate,
        'year'
      )} - ${getFormattedDate(endDate, 'year')}`;
    } else {
      if (isFirstDayOfMonth(startDate) && isLastDayOfMonth(endDate)) {
        // Display sMonth eYear - eMonth eYear.
        preciseDate = `${getFormattedDate(
          startDate,
          'month'
        )} - ${getFormattedDate(endDate, 'month')}`;
      } else {
        // Display sDay sMonth eYear - eDay eMonth eYear
        preciseDate = `${getFormattedDate(startDate)} - ${getFormattedDate(
          endDate
        )}`;
      }
    }
  }
  return preciseDate;
}
