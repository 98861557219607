import { format } from 'date-fns';

/**
 *
 * @param {Date} date
 * @param {string} precision
 *   One of 'day', 'month', 'year'.
 * @param {string} upTo
 *   One of 'day', 'month', 'year'.
 * @returns {string}
 */
const getFormattedDate = (date, precision = 'day', upTo = 'year') => {
  const parts = {
    day: { level: 1, format: 'dd' },
    month: { level: 2, format: 'MMMM' },
    year: { level: 3, format: 'yyyy' },
  };
  const minLevel = parts[precision].level;
  const maxLevel = parts[upTo].level;

  const formatParts = [];
  for (const [, info] of Object.entries(parts)) {
    if (info.level >= minLevel && info.level <= maxLevel) {
      formatParts.push(info.format);
    }
  }
  const formatString = formatParts.join(' ');
  return format(date, formatString);
};

export default getFormattedDate;
