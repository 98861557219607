import React from 'react';
import styled from 'styled-components';
import { colors } from '../style/variables/colors';
import { withSearch } from '@elastic/react-search-ui';
import { ReactComponent as CloseIcon } from '../assets/images/icon-close.svg';

const StyledActiveFilters = styled.ul`
  display: flex;
  margin: 0 -4px;
  flex-wrap: wrap;

  .active-filters__item {
    margin: 4px;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }
  }

  .active-filters__filter {
    line-height: 22px;
    padding: 6px 12px 8px;
  }

  .underline {
    position: relative;
  }

  .underline:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -3px;
    border-bottom: solid 1px ${colors.ink};
  }
`;

const ActiveFilters = ({ facets, removeFilter, filters = [] }) => {
  // Build a list of value->label mappings of all available facets.
  const facetLabels = {};
  for (const [field, facetInfo] of Object.entries(facets)) {
    if (typeof facetLabels[field] === 'undefined') {
      facetLabels[field] = {};
    }
    for (const facetData of facetInfo[0].data) {
      const facetValue = facetData.value;
      facetLabels[field][facetValue] = facetData.label;
    }
  }

  const filterRemove = (field, value) => {
    removeFilter(field, value);
  };

  const filterRemoveAll = filters => {
    filters.forEach(filter => {
      removeFilter(filter.field, filters.value);
    });
  };

  return (
    filters.length > 0 && (
      <StyledActiveFilters className="unstyled-list--inline active-filters">
        {filters.map(filter => {
          return filter.values.map(value => {
            const field = filter.field;
            const label = facetLabels[field][value]
              ? facetLabels[field][value]
              : null;
            // If there was no label found, that means the filter is no longer
            // relevant for the current result set.
            return (
              label && (
                <li key={value} className="active-filters__item">
                  <label className="button--gray rounded active-filters__filter">
                    <CloseIcon width="10" height="10" aria-hidden="true" />
                    <button
                      onClick={() => filterRemove(field, value)}
                      aria-label={`remove filter "${label}"`}
                    >
                      {label}
                    </button>
                  </label>
                </li>
              )
            );
          });
        })}
        {(filters.length > 1 || filters[0].values.length > 1) && (
          <div className="active-filters__item">
            <label className="button--white rounded active-filters__filter">
              <button onClick={() => filterRemoveAll(filters)}>
                <span className="underline">Remove all filters</span>
              </button>
            </label>
          </div>
        )}
      </StyledActiveFilters>
    )
  );
};

export default withSearch(({ facets, removeFilter }) => ({
  facets,
  removeFilter,
}))(ActiveFilters);
