import React from 'react';
import { getFilterValueDisplay } from '@elastic/react-search-ui-views/lib/esm/view-helpers';
import getFilterLabelDisplay from '../utils/Display/getFilterLabelDisplay';
import styled from 'styled-components';
import { colors, palette } from '../style/variables/colors';
import { halfGutters } from '../style/variables/sizes';
import { ReactComponent as ChevronIcon } from '../assets/images/icon-chevron.svg';

const StyledFieldset = styled.div`
  border-top: 1px solid ${colors.border};

  :last-child {
    border-bottom: 1px solid ${colors.border};
  }

  .sui-facet {
    margin: 0;
    padding: ${halfGutters.lg} 0;
    border: none;

    &__title {
      padding: 0;
      font-weight: bold;
      // float:left stops the fieldset from putting the legend in the border,
      // and other fieldset layout weirdness.
      float: left;
      width: 100%;
      display: flex;
      align-items: center;

      + * {
        clear: both;
        padding-top: ${halfGutters.lg};
      }

      svg {
        margin-left: auto;
        transition: transform 150ms ease;
      }
    }
  }

  .collapsible {
    .sui-facet__title {
      cursor: pointer;
    }
  }

  .collapsed {
    .sui-facet__title svg {
      transform: rotate(180deg);
    }

    .sui-multi-checkbox-facet {
      overflow: hidden;
      height: 0;
      padding: 0;
    }
  }

  .sui-multi-checkbox-facet__option-label {
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .sui-multi-checkbox-facet__option-input-wrapper {
    flex-grow: 1;
    display: flex;

    input {
      flex-shrink: 0;
      margin: 3px 10px 0 0;
    }
  }

  .sui-multi-checkbox-facet__option-count {
    margin-left: 10px;
    color: ${colors.inkAlternate};
  }
  
  .sui-facet-search {
    padding: 16px 0;
  }
  .sui-facet-search__text-input {
    width: 100%;
    padding: 9px 16px;
    font-size 16px;
    border: solid 1px ${palette.grey};
  }
`;

/**
 * A variant of MultiCheckboxFacet that displays labels instead of values.
 *
 * See MultiCheckboxFacet from @elastic/react-search-ui-views.
 */
function MultiCheckboxLabelFacet({
  className,
  label,
  onMoreClick,
  onRemove,
  onSelect,
  options,
  showMore,
  showSearch,
  onSearch,
  searchPlaceholder,
  collapsed,
  collapsible,
  onCollapseClick,
}) {
  return (
    <>
      <StyledFieldset>
        <fieldset
          className={`sui-facet ${className ?? ''} ${
            collapsible ? 'collapsible' : ''
          } ${collapsed ? 'collapsed' : 'expanded'}`}
        >
          <legend
            className="sui-facet__title"
            onClick={collapsible ? onCollapseClick : null}
          >
            {label}
            {collapsible && <ChevronIcon aria-hidden="true" />}
          </legend>

          {showSearch && (
            <div className="sui-facet-search">
              <input
                className="sui-facet-search__text-input"
                type="search"
                placeholder={searchPlaceholder || 'Search'}
                onChange={e => {
                  onSearch(e.target.value);
                }}
              />
            </div>
          )}

          <div className="sui-multi-checkbox-facet">
            {options.length < 1 && <div>No matching options</div>}
            {options.map(option => {
              const checked = option.selected;
              return (
                <label
                  key={`${getFilterValueDisplay(option.value)}`}
                  htmlFor={`example_facet_${label}${getFilterValueDisplay(
                    option.value
                  )}`}
                  className="sui-multi-checkbox-facet__option-label"
                >
                  <div className="sui-multi-checkbox-facet__option-input-wrapper">
                    <input
                      id={`example_facet_${label}${getFilterValueDisplay(
                        option.value
                      )}`}
                      type="checkbox"
                      className="sui-multi-checkbox-facet__checkbox"
                      checked={checked}
                      onChange={() =>
                        checked
                          ? onRemove(option.value)
                          : onSelect(option.value)
                      }
                    />
                    <span className="sui-multi-checkbox-facet__input-text">
                      {getFilterLabelDisplay(option)}
                    </span>
                  </div>
                  <span className="sui-multi-checkbox-facet__option-count">
                    {option.count.toLocaleString('en')}
                  </span>
                </label>
              );
            })}
            {showMore && (
              <button
                type="button"
                className="sui-facet-view-more"
                onClick={onMoreClick}
                aria-label="Show more options"
              >
                + More
              </button>
            )}
          </div>
        </fieldset>
      </StyledFieldset>
    </>
  );
}

export default MultiCheckboxLabelFacet;
