import React, { useEffect } from 'react';
import GridContainer from '../layout/GridContainer';
import HomeHeader from '../components/HomeHeader';
import ArtworkList from '../components/ArtworkList';
import { artworksByPeriod } from '../utils/Display/HomePageLists/artworksByPeriod';
import { artworksByCategory } from '../utils/Display/HomePageLists/artworksByCategory';
import ArtworkListWithFacetCount from '../components/ArtworkListWithFacetCount';
import styled from 'styled-components';
import ContributorMap from '../components/ContributorMap';
import LazyLoad, { forceCheck } from 'react-lazyload';
import NewsList from '../components/NewsList';

const StyledHomePage = styled.div`
  > * + * {
    margin-top: 3rem;
  }
`;

const Home = () => {
  useEffect(() => {
    // Check if the contributor map should be loaded immediately.
    forceCheck();
  });

  return (
    <StyledHomePage className="home-page">
      <HomeHeader />

      <GridContainer>
        <div className="grid-col">
          <ArtworkList heading="Artworks by period" data={artworksByPeriod} />
        </div>
      </GridContainer>

      <GridContainer>
        <div className="grid-col">
          <ArtworkListWithFacetCount
            heading="Artworks by object category"
            data={artworksByCategory}
            field="type"
          />
        </div>
      </GridContainer>
      <GridContainer>
        <div className="grid-col">
          <NewsList heading="News"/>
        </div>
      </GridContainer>
      <GridContainer>
        <div className="grid-col">
          <LazyLoad height={400} once resize>
            <ContributorMap heading="Artworks by contributing institutions" />
          </LazyLoad>
        </div>
      </GridContainer>
    </StyledHomePage>
  );
};

export default Home;
