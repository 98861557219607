import React from 'react';
import styled from 'styled-components';
import LinkToSearch from './LinkToSearch';
import GridContainer from '../layout/GridContainer';

const StyledActorExhibitions = styled(GridContainer)`
  th {
    width: 16.6667%;
  }
`;

const ActorExhibitions = ({ actorExhibitionsItems }) => {
  return actorExhibitionsItems.length > 0 ? (
    <StyledActorExhibitions>
      <div className="grid-col">
        <h2 className="visually-hidden">Exhibitions</h2>
        <ul className="unstyled-list">
          <table>
            <tbody>
              {actorExhibitionsItems.map(exhibition => {
                return (
                  <tr key={exhibition.id}>
                    <th>{exhibition.yearLabel}</th>
                    <td>
                      <LinkToSearch
                        basePath={exhibition.path}
                        filters={[
                          {
                            field: 'exhibition',
                            values: [exhibition.id],
                          },
                        ]}
                      >
                        {exhibition.title}
                      </LinkToSearch>
                      <ul className="unstyled-list--inline piped">
                        {exhibition.location && <li>{exhibition.location}</li>}
                        {exhibition.period && <li>{exhibition.period}</li>}
                      </ul>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ul>
      </div>
    </StyledActorExhibitions>
  ) : null;
};

export default ActorExhibitions;
