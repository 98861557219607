import React from 'react';
import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from '@changey/react-leaflet-markercluster';
import mapMarker from '../assets/images/map-marker.svg';
import 'leaflet/dist/leaflet.css';
import '@changey/react-leaflet-markercluster/dist/styles.min.css';
import useSpinqueData from '../hooks/useSpinqueData';
import LinkToSearch from './LinkToSearch';
import Pluralize from 'react-pluralize';
import { latLngBounds } from 'leaflet';
import styled from 'styled-components';
import { colors } from '../style/variables/colors';
import hasAtLeastOneDataItem from '../utils/Display/hasAtLeastOneDataItem';
import OwnersGeo from '../utils/Spinque/OwnersGeo';

const customMarker = L.icon({
  iconUrl: mapMarker,
  iconSize: [30, 41],
  iconAnchor: [15, 41],
  popupAnchor: [0, -51],
});

const StyledContributorMap = styled.div`
  h2 {
    color: ${colors.inkAlternate};
  }

  // Some overrides and tweaks of default Leaflet styling.
  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip,
  .leaflet-container a {
    color: ${colors.ink};
  }
  .leaflet-popup-content-wrapper {
    border-radius: 0;
    text-align: center;
    font-size: 1rem;
    h3 {
      font-size: 1rem;
      margin-bottom: 0.25rem;
    }
  }
  .marker-cluster-small,
  .marker-cluster-medium,
  .marker-cluster-large {
    background: none;
    div {
      background-color: ${colors.mapMarkerBg};
      font-weight: bold;
      font-size: 14px;
    }
  }
`;

/**
 * Displays a map of contributors to the project.
 */
const ContributorMap = ({ heading }) => {
  const {
    data: [ownersGeoData],
  } = useSpinqueData([
    {
      path: `/e/artworks/q/owner_geo/results`,
      params: ['count=200'],
    },
  ]);

  const ownersGeo = new OwnersGeo(ownersGeoData);
  if (!hasAtLeastOneDataItem(ownersGeo.items)) {
    return null;
  }

  const contributors = ownersGeo.items;

  // Calculate bounds including the centre of The Netherlands.
  const center = [52.092876, 5.10448];
  const bounds = latLngBounds(center);
  contributors.forEach(contributor => {
    bounds.extend([contributor.lat, contributor.lng]);
  });

  return (
    <StyledContributorMap>
      <h2>{heading}</h2>
      <MapContainer
        bounds={bounds}
        style={{ width: '100%', height: '50vh', minHeight: '400px' }}
        center={center}
      >
        <TileLayer
          attribution='<a href="https://www.jawg.io" target="_blank">&copy; Jawg</a> | <a href="https://www.openstreetmap.org" target="_blank">&copy; OpenStreetMap</a>&nbsp;contributors'
          url="https://tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token=o9T6OJwJt5cpepZbRBX0QyGwjgIMhleCPf6iduBarzu06Ry7mBkGJGHFMRjsUllR"
        />
        <MarkerClusterGroup>
          {contributors.map((contributor, idx) => (
            <Marker
              key={idx}
              icon={customMarker}
              position={[contributor.lat, contributor.lng]}
            >
              <Popup>
                <h3>{contributor.label}</h3>
                <LinkToSearch
                  filters={[
                    {
                      field: 'owner',
                      values: [contributor.id],
                    },
                  ]}
                >
                  {contributor.count ? (
                    <>
                      View{' '}
                      <Pluralize
                        singular={'artwork'}
                        count={contributor.count}
                      />
                    </>
                  ) : (
                    'View artworks'
                  )}
                </LinkToSearch>
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    </StyledContributorMap>
  );
};

export default ContributorMap;
