import React from 'react';
import styled from 'styled-components';
import GridContainer from '../layout/GridContainer';
import { colors } from '../style/variables/colors';
import mediaQueries from '../style/variables/mediaQueries';
import { ReactComponent as ErrorIcon } from '../assets/images/banner-error.svg';

const StyledBanner = styled.div`
  background-color: ${colors.bannerBg};
  color: ${colors.bannerColor};
  padding: 15px 0;

  .error-banner-inner {
    display: flex;

    .icon {
      flex-shrink: 0;
      margin-right: 1em;
      position: relative;
      top: 0.2em;

      ${mediaQueries.max_sm} {
        display: none;
      }
    }
  }
`;

const ErrorBanner = () => {
  return (
    <StyledBanner className="error-banner">
      <GridContainer>
        <div className="grid-col--fluid">
          <div className="error-banner-inner">
            <div className="icon">
              <ErrorIcon/>
            </div>
            <div className="text">
              The website is currently experiencing{' '}
              <strong>technical difficulties</strong>. We are working to resolve the
              problem as quickly as we can. We are sorry for the inconvenience.
            </div>
          </div>
        </div>
      </GridContainer>
    </StyledBanner>
  );
};

export default ErrorBanner;
