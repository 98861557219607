import splitAndSwapName from '../Display/splitAndSwapName';
import { getPreferredValue } from './linkedArtHelpers';
import getMostPreciseDate from '../Display/getMostPreciseDate';
import getFormattedDate from '../Display/getFormattedDate';
import parseIso from 'date-fns/parseISO';
import getNormalizedDate from '../Display/getNormalizedDate';

/**
 * Utility class to provide easy access of Actor data.
 */
class ActorTuple {
  /**
   * Constructs an ActorTuple object which can be used to query the data inside.
   *
   * @param {object} json
   */
  constructor(json) {
    this.json = json;
  }

  /**
   * Gets the Actor ID.
   *
   * @returns {string} The ID.
   */
  get id() {
    return this.json.id;
  }

  /**
   * Gets the internal Actor path to the route defined in src/index.js.
   *
   * @returns {string} The path.
   */
  get path() {
    return `/actor/${encodeURIComponent(this.id)}`;
  }

  /**
   * Gets the Actor type.
   *
   * @returns {string|null} The actor type or null if not available.
   */
  get type() {
    const type = this.json?.attributes?.type;

    if (type === 'Person') {
      return 'person';
    }
    else if (type === 'Organization') {
      return 'organization';
    }
    return null;
  }

  /**
   * Gets the Actor title in the most preferred available language.
   *
   * @returns {string} The title.
   */
  get title() {
    const title = splitAndSwapName(
      getPreferredValue(this.json?.attributes ?? '')
    );
    return this.cacheAsStaticProperty('title', title);
  }

  /**
   * Gets the Actor birth date, if available.
   *
   * @returns {string|null} The date or null.
   */
  get birth() {
    let birthDate = this.json?.attributes?.born?.timespan?.begin_of_the_begin;
    birthDate = getNormalizedDate(birthDate);
    return birthDate ? getFormattedDate(parseIso(birthDate)) : null;
  }

  /**
   * Gets the Actor death date, if available.
   *
   * @returns {string|null} The date or null.
   */
  get death() {
    let deathDate = this.json?.attributes?.died?.timespan?.begin_of_the_begin;
    deathDate = getNormalizedDate(deathDate);
    return deathDate ? getFormattedDate(parseIso(deathDate)) : null;
  }

  /**
   * Gets the Actors active period, if available.
   *
   * @returns {object[]|null} An array of objects containing time range, name
   *   and place.
   */
  get periodOfActivity() {
    // @todo figure out how to obtain this data.
    const events = this.json?.attributes?.event ?? [];
    if (events.length) {
      const periods = events.map(event => {
        return {
          sortValue: event['http://schema.org/startDate'],
          description: event['http://schema.org/description'],
          period: getMostPreciseDate([event]),
          location: getPreferredValue({
            content: event['http://schema.org/location'],
          }),
        };
      });
      periods.sort((a, b) =>
        a.sortValue > b.sortValue ? 1 : b.sortValue > a.sortValue ? -1 : 0
      );
      return periods;
    }
    return null;
  }

  /**
   * Gets the Actor RKD artists URL.
   *
   * @returns {string|null} The URL or null is not available.
   */
  get rkdArtistsUrl() {
    if (this.id.startsWith('https://data.rkd.nl/artists/')) {
      return `https://rkd.nl/artists/${this.id.substring(28)}`;
    }
    return null;
  }

  /**
   * Gets the Actor descriptions as an object keyed by remark type.
   *
   * @returns {object|null} The date or null.
   */
  get descriptions() {
    const rawDescription = this.json?.attributes?.[
      'http://schema.org/description'
    ];
    if (!rawDescription) {
      return null;
    }
    const descriptions = {};
    if (typeof rawDescription === 'string') {
      const { remarkType, value } = this.splitRemarkDescripion(rawDescription);
      descriptions[remarkType] = value;
    } else if (typeof rawDescription === 'object') {
      for (const [, string] of Object.entries(rawDescription)) {
        const { remarkType, value } = this.splitRemarkDescripion(string);
        descriptions[remarkType] = value;
      }
    }
    return this.cacheAsStaticProperty('descriptions', descriptions);
  }

  getDescriptionByRemarkType(remarkType) {
    return this?.descriptions?.[remarkType] || null;
  }

  /**
   * Splits a string in two parts if it starts with "remark[...]:", returning
   * the remark type and the value. Otherwise returns the original value with
   * "description" as remark type.
   *
   * @param description
   * @returns {{remarkType: string, value: string}}
   */
  splitRemarkDescripion(description) {
    const pattern = /^remark (.*?): ?(.*)$/;
    const match = description.match(pattern);
    if (match) {
      return { remarkType: match[1], value: match[2] };
    }
    return { remarkType: 'description', value: description };
  }

  /**
   * Builds the new "description' field from info in classifiedAs.
   *
   * @returns {string}
   *   Returns the concatenated description.
   */
  get classifiedAs() {
    const result = [];
    const descriptions = this.json?.attributes?.[
      'classified_as'
    ];


    if (Array.isArray(descriptions)) {
      for(let description of descriptions) {
        if (description?.['_label']) {
          if (description?.['_label']?.en) {
            result.push(description?.['_label']?.en)
          }
        }
      }
    }
    return this.cacheAsStaticProperty('classifiedAs', result.join(', '));
  }

  /**
   * Replaces the given propertyName with a static value property.
   *
   * See
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/get#Examples
   *
   * @param {string} propertyName
   *   Name of the property to replace.
   * @param {*} propertyValue
   *   Value of the property.
   * @returns {*}
   *   Returns the given value.
   */
  cacheAsStaticProperty(propertyName, propertyValue) {
    delete this[propertyName];
    Object.defineProperty(this, propertyName, { value: propertyValue });
    return propertyValue;
  }
}

export default ActorTuple;
