import React, { useEffect, useState } from 'react';
import { SearchProvider } from '@elastic/react-search-ui';
import styled from 'styled-components';
import VgwConnector from '../search-ui/connectors/VgwConnector';
import { colors } from '../style/variables/colors';
import useIsOnAnySearchPage from '../hooks/useIsOnAnySearchPage';
import { useLocation } from 'react-router-dom';
import ErrorBanner from '../components/ErrorBanner';

const connector = new VgwConnector();
// Update the error banner every 10 minutes, or every 600K milliseconds.
const BANNER_INTERVAL = 600000;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .skip-link {
    text-align: center;
  }

  .page__section--main {
    // To position #vangoghworldwide-viewer
    position: relative;
    flex-grow: 1;

    // There is a thin full width border between the page header and main,
    // except when the first child element of main has a background color. We
    // solve this with a absolute positioned pseudo element with z-index -1, so
    // any first child with a background color will cover and hide the border.
    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      left: 0;
      right: 0;
      height: 0;
      border-top: 1px solid ${colors.border};
    }
  }

  .page__section--doormat {
    margin-top: auto;
  }
`;

const Page = ({ header, children, doormat, footer }) => {
  const isOnAnySearchPage = useIsOnAnySearchPage();
  const { pathname } = useLocation();
  const isViewer = pathname === '/viewer';

  const [showBanner, setShowBanner] = useState(false);

  // Update banner state. We need to do all this work in Page because children of
  // SearchProvider will be unmounted and remounted when the path changes.
  // This would cause a check on the banner state for every path change, increasing load on the server.
  // Rerunning the state check on page change would also cause the banner to disappear and reappear on path changes.
  // This would cause frequent changes in page height.
  useEffect(() => {
    let isActive = true;

    const updateBannerState = async () => {
      try {
        const response = await fetchBannerResponse(
          process.env.PUBLIC_URL + '/state/state.json'
        );

        if (typeof response.banner !== "undefined") {
          isActive && setShowBanner(response.banner);
        }
      } catch (error) {
        // Do nothing.
      }
    };

    // Fetch the initial state.
    // Deliberately ignore the promise. Its setShowBanner side-effect is the goal.
    updateBannerState();

    // Update the status every INTERVAL milliseconds.
    const timeoutID = window.setInterval(() => {
      // Deliberately ignore the promise. Its setShowBanner side-effect is the goal.
      updateBannerState();
    }, BANNER_INTERVAL);

    return () => {
      isActive = false;
      window.clearTimeout(timeoutID);
    };
  }, []);

  return (
    <SearchProvider
      key={isOnAnySearchPage ? pathname : 'elsewhere'}
      config={{
        apiConnector: connector,
        alwaysSearchOnInitialLoad: isOnAnySearchPage,
        initialState: {
          resultsPerPage: 12,
        },
        debug: process.env.REACT_APP_SEARCH_UI_DEBUG || false,
        facets: {
          creator: { type: 'value', size: 30 },
          support: { type: 'value', size: 30 },
          period: { type: 'value', size: 30 },
          owner: { type: 'value', size: 30 },
          type: { type: 'value', size: 30 },
          subject: { type: 'value', size: 30 },
          material: { type: 'value', size: 30 },
          technique: { type: 'value', size: 30 },
          research_type: { type: 'value', size: 30 },
          owner_country: { type: 'value', size: 30 },
        },
      }}
    >
      <PageWrapper className="page" id="page">
        <a href="#main-content" className="visually-hidden focusable skip-link">
          Skip to main content
        </a>
        {!isViewer && (
          <header className="page__section page__section--header">
            {header}
          </header>
        )}
        {showBanner && <ErrorBanner />}
        <main className="page__section page__section--main" id="main-content">
          {children}
        </main>
        {!isViewer && (
          <>
            <aside className="page__section page__section--doormat">
              {doormat}
            </aside>
            <footer className="page__section page__section--footer">
              {footer}
            </footer>
          </>
        )}
      </PageWrapper>
    </SearchProvider>
  );
};

export default Page;

/**
 * Fetches json from a uri.
 *
 * Helper for updateBannerState.
 *
 * @param {string} uri
 *   Uri to fetch.
 *
 * @returns {Promise<any>}
 */
async function fetchBannerResponse(uri) {
  const result = await fetch(uri);
  return await result.json();
}
