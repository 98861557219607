import { getFilterValueDisplay } from '@elastic/react-search-ui-views/lib/esm/view-helpers';

/**
 * Displays option label if present, option value otherwise.
 *
 * @param {*} option
 *   A facet option.
 * @returns {string}
 *   The label to diplay
 */
export default function getFilterLabelDisplay(option) {
  return option.label ? option.label : getFilterValueDisplay(option.value);
}
