import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { Paging } from '@elastic/react-search-ui';
import PagingView from './rc-pager/PagingView';
import { ReactComponent as ArrowIconNext } from '../assets/images/icon-arrow-next.svg';
import { ReactComponent as ArrowIconPrev } from '../assets/images/icon-arrow-prev.svg';
import { ReactComponent as EllipsisIcon } from '../assets/images/icon-ellipsis.svg';
import styled from 'styled-components';
import { colors, palette } from '../style/variables/colors';
import mediaQueries from '../style/variables/mediaQueries';
import Pluralize from 'react-pluralize';

const PagerNav = styled.nav`
  ${mediaQueries.md} {
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .paging-summary {
    margin: 1em 0;
    font-size: 14px;
    > * {
      margin: 0;
    }
  }

  .rc-pagination {
    margin: 1em 0;
    ${mediaQueries.md} {
      margin-left: auto;
    }

    li[tabindex] {
      cursor: pointer;
    }
  }

  .rc-pagination-item-active {
    background-color: ${colors.backgroundAlternate};
  }
  .rc-pagination-disabled {
    display: none;
  }

  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    color: ${palette.grey};
  }

  svg {
    margin: 0 5px;
  }

  a {
    display: inline-block;
    line-height: 26px;
    min-width: 26px;
    padding: 0 5px;
    text-align: center;

    &:hover {
      background-color: ${colors.backgroundAlternate};
    }
  }
`;

const ResultsPagerNav = ({
  current,
  totalPages,
  totalResults,
  pagingStart,
  pagingEnd,
}) => {
  const smallPagerThreshold = 512;
  const [useSmallPager, setUseSmallPager] = useState(
    window.innerWidth < smallPagerThreshold
  );

  useEffect(() => {
    const handleResize = debounce(() => {
      setUseSmallPager(window.innerWidth < smallPagerThreshold);
    }, 100);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <PagerNav className="search-pager" aria-labelledby="paging-summary">
      <div id="paging-summary" className="paging-summary">
        <h3>
          <Pluralize singular={'result'} count={totalResults} />
        </h3>
        <p>
          {pagingStart} &ndash; {pagingEnd} shown, page {current} of{' '}
          {totalPages}
        </p>
      </div>
      <Paging
        view={PagingView}
        className="unstyled-list--inline"
        nextIcon={<ArrowIconNext aria-hidden="true" />}
        prevIcon={<ArrowIconPrev aria-hidden="true" />}
        jumpNextIcon={<EllipsisIcon aria-hidden="true" />}
        jumpPrevIcon={<EllipsisIcon aria-hidden="true" />}
        showLessItems={useSmallPager}
      />
    </PagerNav>
  );
};

export default ResultsPagerNav;
