import { search as jmespath } from 'jmespath';
import getYearFromDate from '../Display/getYearFromDate';
import ProvenanceTuple from './ProvenanceTuple';

/**
 * Utility class to provide easy access to provenance contents.
 *
 */
class Provenance {
  constructor(jsonObject) {
    this.json = jsonObject;
  }

  /**
   * Returns an array of provenance items.
   *
   * @returns {[*]}
   */
  get items() {
    const query = '[].tuple[0]';
    const rawItems = jmespath(this.json?.items, query) || [];

    const provenanceItems = rawItems.map(item => {
      const provenance = new ProvenanceTuple(item);

      const startYear = getYearFromDate(provenance.startDate);
      const endYear = getYearFromDate(provenance.endDate);
      const rowLabel =
        startYear === endYear
          ? startYear
          : [startYear, endYear].filter(value => value).join(' - ');

      return {
        label: rowLabel,
        value: {
          label: this.getLabel(provenance),
          labelPath: this.getLabelPath(provenance),
          type: provenance.type,
          timespan: provenance.period,
          location: provenance.location,
          auctionLabel: provenance.auctionLabel,
          auctionPath: provenance.auctionPath,
          dimension: provenance.dimension,
          price: provenance.price,
          isAuction: provenance.isAuction,
          auctionId: provenance.auctionId,
        },
        renderType: 'provenance',
      };
    });

    return provenanceItems;
  }

  /**
   * Gets the provenance label.
   *
   * @returns {string}
   */
  getLabel(provenance) {
    return provenance.isAuction ? provenance.auctionActor : provenance.owner;
  }

  /**
   * Gets the path for the provenance label.
   *
   * @returns {string}
   */
  getLabelPath(provenance) {
    return provenance.isAuction
      ? provenance.auctionActorPath
      : provenance.ownerPath;
  }
}

export default Provenance;
