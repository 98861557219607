import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../style/variables/colors';
import { gutters } from '../style/variables/sizes';
import mediaQueries from '../style/variables/mediaQueries';
import GridContainer from '../layout/GridContainer';
import LinkToSearch from './LinkToSearch';
import getHomeHeaderImage from '../utils/Display/HomeHeader/getHomeHeaderImage';

const StyledHeader = styled.header`
  background-color: ${colors.backgroundAlternate};

  .header-inner {
    margin: 0 auto;
    max-width: 1440px;
    display: flex;
  }

  .textual {
    z-index: 1;
    display: flex;
  }

  .textual-inner {
    margin: auto 0;
    padding-top: ${gutters.xs};
    padding-bottom: ${gutters.xs};
    ${mediaQueries.md} {
      // It seems a bit inconsistent to reset the padding top here, but it does
      // its job to reproduce de design.
      padding-top: 0;
    }
  }

  .visual {
    margin-left: -100%;
    width: 100%;
    z-index: 0;
    position: relative;

    &:after {
      content: ' ';
      z-index: 1;
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.2);
    }

    img {
      object-fit: cover;
      min-height: 100%;
    }
  }

  .tagline {
    color: #fff;
    font-size: 2rem;
    font-weight: 900;
    line-height: 1;
    max-width: 700px;
    margin: 0 0 2rem 0;

    ${mediaQueries.md} {
      font-size: 3rem;
    }
  }
`;

const HomeHeader = () => {
  const headerImage = getHomeHeaderImage();

  const [headerImageSource, setHeaderImageSource] = useState(
    'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs='
  );

  import(
    /* webpackPrefetch: 0 */ `../assets/images/home-header/${headerImage.fileName}`
  ).then(module => {
    setHeaderImageSource(module.default);
  });

  return (
    <StyledHeader>
      <div className="header-inner">
        <GridContainer className="textual">
          <div className="textual-inner grid-col">
            <h1 className="visually-hidden">Van Gogh Worldwide</h1>
            <p className="tagline">
              Resource for Vincent&nbsp;van&nbsp;Gogh's artworks
            </p>
            <LinkToSearch className="button--large button--white">
              Explore artworks
            </LinkToSearch>
          </div>
        </GridContainer>
        <div className="visual">
          <img src={headerImageSource} alt={headerImage.altText} />
        </div>
      </div>
    </StyledHeader>
  );
};

export default HomeHeader;
