import { getPreferredValue } from './linkedArtHelpers';
import getMostPreciseDate from '../Display/getMostPreciseDate';

/**
 * Utility class to provide easy access of Provenance data.
 *
 * Unfortunately, provenance data comes in two different structures. For
 * instance the new owner of a provenance event can be found in one of these two
 * places:
 *   - attributes.carried_out_by
 *   - attributes.part[0].transferred_title_to
 * In an ideal world this would have been solved on the API side, but for now we
 * will try one option and fall back on the other.
 *
 * Provenance items that refer to an auction can also have an alternative data
 * structure.
 */
class ProvenanceTuple {
  /**
   * Constructs an ProvenanceTuple object which can be used to query the
   * data inside.
   *
   * @param {object} json
   */
  constructor(json) {
    this.json = json;
  }

  /**
   * Gets the Provenance ID.
   *
   * @returns {string} The ID.
   */
  get id() {
    return this.json.id;
  }

  /**
   * Test if the provenance represents either an offering at an auction event,
   * or an auction event itself.
   *
   * @returns {boolean} True if it is an auction event.
   */
  get isAuction() {
    return this.isItselfAuction || this.isPartOfAuction;
  }

  /**
   * Test if the provenance represents an offering at an auction event.
   *
   * @returns {boolean} True if it is an offering at an auction event.
   */
  get isPartOfAuction() {
    const partOfClassifications =
      this.json?.attributes?.part_of?.[0]?.classified_as ?? [];
    return partOfClassifications.some(
      classification =>
        classification['@id'] === 'http://vocab.getty.edu/aat/300054751'
    );
  }

  /**
   * Test if the provenance represents an actual auction event.
   *
   * There seems to be some inconsistency in the data: auctions are classified
   * on the top level as 'auctions' (http://vocab.getty.edu/aat/300054751) or as
   * 'offered at auction' (http://vocab.getty.edu/aat/300420001). For now we
   * interpret that as being an auction itself.
   * @todo Check if these assumptions are correct.
   *
   * @returns {boolean} True if it represents an auction event itself.
   */
  get isItselfAuction() {
    const classifications = this.json?.attributes?.classified_as ?? [];
    return classifications.some(
      classification =>
        classification['@id'] === 'http://vocab.getty.edu/aat/300420001' ||
        classification['@id'] === 'http://vocab.getty.edu/aat/300054751'
    );
  }

  /**
   * Gets the type of the provenance event.
   *
   * If there is more than onye type, this filters out the standard
   * http://vocab.getty.edu/aat/300055863 concept that seems to be present on
   * every provenance record.
   *
   * @returns {string|null} An string of comma separated types (often only one)
   *   or null if not available.
   */
  get type() {
    const rawClassifications = this.json?.attributes?.classified_as ?? false;
    if (!rawClassifications) {
      return null;
    }
    return rawClassifications.length === 1
      ? getPreferredValue(rawClassifications[0])
      : rawClassifications
          .filter(
            item => item['@id'] !== 'http://vocab.getty.edu/aat/300055863'
          )
          .map(item => getPreferredValue(item))
          .join(', ');
  }

  /**
   * Gets the location where the provenance event took place.
   *
   * @returns {string} A geographic name.
   */
  get location() {
    const defaultLocation = this.json?.attributes?.took_place_at?.[0];
    if (defaultLocation) {
      return getPreferredValue(defaultLocation ?? '');
    } else {
      // Provenance auction
      return getPreferredValue(
        this.json?.attributes?.part_of?.[0]?.took_place_at?.[0] ?? ''
      );
    }
  }

  /**
   * Gets the provenance timespan or date.
   *
   * @returns {string} A human-readable timespan or date.
   */
  get period() {
    const defaultTimespan = this.json?.attributes?.timespan;
    if (defaultTimespan) {
      return getMostPreciseDate(defaultTimespan);
    } else {
      // Provenance auction
      return getMostPreciseDate(this.json?.attributes?.part_of?.[0]?.timespan);
    }
  }

  /**
   * Gets the provenance start date.
   *
   * @returns {string|null} A date, or null if not available.
   */
  get startDate() {
    return (
      this.json?.attributes?.timespan?.[0]?.begin_of_the_begin ||
      this.json?.attributes?.part_of?.[0]?.timespan?.[0]?.begin_of_the_begin ||
      null
    );
  }

  /**
   * Gets the provenance end date.
   *
   * @returns {string|null} A date, or null if not available.
   */
  get endDate() {
    return (
      this.json?.attributes?.timespan?.[0]?.end_of_the_end ||
      this.json?.attributes?.part_of?.[0]?.timespan?.[0]?.end_of_the_end ||
      null
    );
  }

  /**
   * Gets the provenance auction label.
   *
   * @returns {string|null}
   */
  get auctionLabel() {
    if (this.isPartOfAuction) {
      return getPreferredValue(this.json?.attributes?.part_of?.[0] ?? '');
    }
    if (this.isItselfAuction) {
      return getPreferredValue(this.json?.attributes ?? '');
    }
    return null;
  }

  /**
   * Gets the path to the provenance auction page.
   *
   * @returns {string|null} An internal path or null if not available.
   */
  get auctionPath() {
    return this.auctionId
      ? `/auction/${encodeURIComponent(this.auctionId)}`
      : null;
  }

  /**
   * Gets the auction id.
   *
   * @returns {String|null} The ID.
   */
  get auctionId() {
    if (this.isPartOfAuction) {
      return this.json?.attributes?.part_of?.[0]?.['@id'] ?? null;
    }
    if (this.isItselfAuction) {
      return this.json?.id ?? null;
    }
    return null;
  }

  /**
   * Gets the provenance owner.
   *
   * @returns {string}
   */
  get owner() {
    return getPreferredValue(
      this.json?.attributes?.carried_out_by?.[0] ??
        this.json?.attributes?.part?.[0]?.transferred_title_to?.[0] ??
        ''
    );
  }

  /**
   * Gets the path to the provenance actor page.
   *
   * @returns {string|null}
   */
  get ownerPath() {
    const actorId =
      this.json?.attributes?.carried_out_by?.[0]?.['@id'] ??
      this.json?.attributes?.part?.[0]?.transferred_title_to?.[0]?.['@id'];
    return actorId ? `/actor/${encodeURIComponent(actorId)}` : null;
  }

  /**
   * Gets the provenance dimension. Used for provenance auction items.
   *
   * @returns {string}
   */
  get dimension() {
    const firstDimensionElement = this.json?.attributes
      ?.used_specific_object?.[0]?.dimension?.[0];
    if (firstDimensionElement) {
      const dimensionParts = [];
      const currency = getPreferredValue(firstDimensionElement?.currency ?? '');
      currency && dimensionParts.push(currency);
      const monetaryAmount = getPreferredValue(
        firstDimensionElement?.value ?? ''
      );
      monetaryAmount && dimensionParts.push(monetaryAmount);
      return dimensionParts.join(' ');
    }
    return '';
  }

  /**
   * Gets the provenance price, paid at an auction.
   *
   * @returns {string}
   */
  get price() {
    const firstPaidAmountElement = this.json?.attributes?.part?.[0]
      ?.paid_amount?.[0];
    if (firstPaidAmountElement) {
      const currency = getPreferredValue(
        firstPaidAmountElement?.currency ?? ''
      );
      const monetaryAmount = getPreferredValue(
        firstPaidAmountElement?.value ?? ''
      );
      return `${currency} ${monetaryAmount}`;
    }
    return '';
  }

  /**
   * Gets the provenance auction actor. Used for provenance auction items.
   *
   * @returns {string}
   */
  get auctionActor() {
    return getPreferredValue(
      this.json?.attributes?.part_of?.[0]?.carried_out_by?.[0] ?? ''
    );
  }

  /**
   * Gets the path to the provenance auction actor's page.
   *
   * @returns {string}
   */
  get auctionActorPath() {
    const auctionActorId = this.json?.attributes?.part_of?.[0]
      ?.carried_out_by?.[0]?.['@id'];
    return auctionActorId
      ? `/actor/${encodeURIComponent(auctionActorId)}`
      : null;
  }

  /**
   * Replaces the given propertyName with a static value property.
   *
   * See
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/get#Examples
   *
   * @param {string} propertyName
   *   Name of the property to replace.
   * @param {*} propertyValue
   *   Value of the property.
   * @returns {*}
   *   Returns the given value.
   */
  cacheAsStaticProperty(propertyName, propertyValue) {
    delete this[propertyName];
    Object.defineProperty(this, propertyName, { value: propertyValue });
    return propertyValue;
  }
}

export default ProvenanceTuple;
