import React from 'react';
import useSpinqueData from '../hooks/useSpinqueData';
import { useParams } from 'react-router-dom';
import ActorHeader from '../components/ActorHeader';
import { Helmet } from 'react-helmet/es/Helmet';
import ActorTuple from '../utils/Spinque/ActorTuple';
import NotFound from './NotFound';
import ActorDetails from '../components/ActorDetails';

const Actor = () => {
  const id = encodeURIComponent(useParams().id);
  const {
    isLoading,
    isError,
    data: [spinqueData],
  } = useSpinqueData([`/q/actor/p/id/${id}/results`]);
  const actorJson = spinqueData?.items?.[0]?.tuple?.[0] ?? false;

  // Return early if we're not ready to render the component (yet).
  if (isError || (!isLoading && !actorJson)) {
    return <NotFound />;
  } else if (isLoading || !actorJson) {
    return <p>Loading...</p>;
  }

  const actor = new ActorTuple(actorJson);

  return (
    <>
      <Helmet>
        <title>{actor.title}</title>
      </Helmet>
      <article>
        <ActorHeader actor={actor} />
        <ActorDetails actor={actor} />
      </article>
    </>
  );
};

export default Actor;
