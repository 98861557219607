import { search as jmespath } from 'jmespath';
import { get as _get } from 'lodash';
import getMostPreciseDate from '../Display/getMostPreciseDate';
import { getPreferredValue, joinPreferredValues } from './linkedArtHelpers';
import getIIIFRepresentationUri from '../Display/ArtworkDetails/getIIIFRepresentationUri.js';

/**
 * Utility class to provide easy access to technical research contents.
 *
 */
class TechnicalResearch {
  constructor(jsonObject) {
    this.json = jsonObject;
  }

  /**
   * Returns an array of technical research items.
   *
   * @returns {[*]}
   */
  get items() {
    const query = '[].tuple[0].attributes[]';

    const rawItems = jmespath(this.json?.items, query) || [];

    const items = rawItems.map(item => {
      // Build the label.
      const labelParts = [];

      const category = getPreferredValue(_get(item, 'classified_as[0]', ''));
      if (category) {
        labelParts.push(category);
      }

      // Try identified_by[0] first, fall back on
      // produced_by.technique[0].
      const subCategory = getPreferredValue(
        _get(
          item,
          'identified_by[0]',
          _get(item, 'produced_by.technique[0]', '')
        )
      );
      if (subCategory) {
        labelParts.push(subCategory);
      }

      const label = labelParts.join(' – ');

      // Gather other values.
      const value = {};

      // Try produced_by.carried_out_by first, fall back on
      // created_by[0].carried_out_by.
      const rawCarriedOutBy = _get(
        item,
        'produced_by.carried_out_by',
        _get(item, 'created_by[0].carried_out_by', [])
      );

      if (rawCarriedOutBy.length) {
        value.carriedOutBy = joinPreferredValues(rawCarriedOutBy, '; ');
      }

      // Try produced_by.timespan first, fall back on
      // created_by[0].timespan.
      const timespan = _get(
        item,
        'produced_by.timespan',
        _get(item, 'created_by[0].timespan', [])
      );
      const date = getMostPreciseDate(timespan);
      if (date) {
        value.date = date;
      }

      // Get sortable (raw) date from timespan
      let sortDate = null;
      if (timespan?.[0]?.begin_of_the_begin) {
        sortDate = timespan[0].begin_of_the_begin;
      } else if (timespan?.[0]?.end_of_the_end) {
        sortDate = timespan[0].end_of_the_end;
      }

      // Try produced_by.carried_out_by first, fall back on
      // created_by[0].carried_out_by.
      const rawTookPlaceAt = _get(
        item,
        'produced_by.took_place_at',
        _get(item, 'created_by[0].took_place_at', [])
      );

      if (rawTookPlaceAt.length) {
        value.tookPlaceAt = joinPreferredValues(rawTookPlaceAt, '; ');
      }

      let representation = null;
      if (
        item.hasOwnProperty('representation') &&
        Array.isArray(item.representation) &&
        item.representation.length > 0
      ) {
        representation = {
          uri: getIIIFRepresentationUri(item),
          label,
          description: label,
          isIiif: true,
        };
      }

      return {
        label,
        representation: representation,
        value: value,
        sortValue: sortDate,
        renderType: 'technical_docs',
      };
    });

    this.sortItems(items);
    return this.cacheAsStaticProperty('items', items);
  }

  get representations() {
    const query = '[].tuple[0].attributes | [?type == `"HumanMadeObject"`]';

    const rawItems = jmespath(this.json?.items, query) || [];

    const representations = rawItems.map(item => {
      return {
        uri: getIIIFRepresentationUri(item),
        label: getPreferredValue(item.produced_by?.technique?.[0]),
        description: getPreferredValue(item.produced_by?.technique?.[0]),
        isIiif: true,
      };
    });

    const resultItems = representations.filter(item => item.uri !== null);

    return this.cacheAsStaticProperty('representations', resultItems);
  }

  sortItems(items) {
    items.sort((a, b) => {
      const aValue = a.sortValue;
      const bValue = b.sortValue;

      if (aValue === bValue) {
        return 0;
      }

      // null should sort before dates.
      if (aValue === null && bValue !== null) {
        return -1;
      }
      if (bValue === null && aValue !== null) {
        return 1;
      }

      return aValue < bValue ? -1 : 1;
    });
  }

  /**
   * Replaces the given propertyName with a static value property.
   *
   * See
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/get#Examples
   *
   * @param {string} propertyName
   *   Name of the property to replace.
   * @param {*} propertyValue
   *   Value of the property.
   * @returns {*}
   *   Returns the given value.
   */
  cacheAsStaticProperty(propertyName, propertyValue) {
    delete this[propertyName];
    Object.defineProperty(this, propertyName, { value: propertyValue });
    return propertyValue;
  }
}

export default TechnicalResearch;
