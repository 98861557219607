import getIdentifiers from '../getIdentifiers';
import getDisplayDates from '../getDisplayDates';

export default function getContentObjectDetails(linkedData) {
  return [
    {
      label: 'Artist',
      value: linkedData.creator,
      renderType: 'creator',
    },
    {
      label: 'Title',
      value: linkedData.title,
      renderType: 'title',
    },
    {
      label: 'Other (former) title',
      value: linkedData.otherTitles,
      renderType: 'other_title',
    },
    {
      label: 'Date',
      value: getDisplayDates(linkedData.productionDates).join(', '),
      renderType: 'date',
    },
    {
      label: 'Location made',
      value: linkedData.location,
      renderType: 'location_made',
    },
    {
      label: 'Material',
      value: linkedData.material,
      renderType: 'material',
    },
    {
      label: 'Support',
      value: linkedData.support,
      renderType: 'support',
    },
    {
      label: 'Technique',
      value: {
        technique: linkedData.technique,
        description: linkedData.materialsTechniqueDescription,
      },
      renderType: 'technique',
    },
    {
      label: 'Dimensions',
      value: linkedData.dimensions,
      renderType: 'dimensions',
    },
    {
      label: 'Signature',
      value: linkedData.signature,
      renderType: 'signature',
    },
    {
      label: 'Catalogue number(s)',
      value: getIdentifiers(linkedData.identifiers),
      renderType: 'catalogue_numbers',
    },
  ];
}
