import React from 'react';
import GridContainer from '../layout/GridContainer';
import SearchSummary from './SearchSummary';
import SearchActorResults from './SearchActorResults';
import SearchUI from './SearchUI';
import styled from 'styled-components';
import mediaQueries from '../style/variables/mediaQueries';
import useSpinqueData from '../hooks/useSpinqueData';
import ActorTuple from '../utils/Spinque/ActorTuple';
import { withSearch } from '@elastic/react-search-ui';
import HorizontalTabs from './HorizontalTabs';

const StyledCurrentSearch = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  ${mediaQueries.lg} {
    height: 100px;
  }
`;

const SearchResults = ({ resultSearchTerm, totalResults }) => {
  const {
    data: [actorSearchData],
  } = useSpinqueData([
    {
      path: `/e/actor_search/p/q/${resultSearchTerm}/results`,
      params: ['count=200'],
    },
  ]);
  const actorResults = (actorSearchData?.items ?? []).map(item => {
    return new ActorTuple(item.tuple[0]);
  });

  // Base config to generate other objects and arrays from.
  const tabConfig = [
    {
      name: 'artworks',
      label: `Artworks (${totalResults})`,
      component: SearchUI,
      isOptional: false,
    },
  ];

  if (actorResults.length > 0) {
    tabConfig.push({
      name: 'actors',
      label: `Persons and Institutions (${actorResults.length})`,
      component: SearchActorResults,
      props: { actorResults, resultSearchTerm },
    });
  }

  return (
    <>
      <div className="background-alternate">
        <GridContainer>
          <StyledCurrentSearch className="grid-col current-search">
            <SearchSummary additionalResultsCount={actorResults.length} />
          </StyledCurrentSearch>
        </GridContainer>
      </div>
      <HorizontalTabs tabConfig={tabConfig} />
    </>
  );
};

export default withSearch(({ resultSearchTerm, totalResults }) => ({
  resultSearchTerm,
  totalResults,
}))(SearchResults);
