import { search as jmespath } from 'jmespath';
import { get as _get } from 'lodash';
import getMostPreciseDate from '../Display/getMostPreciseDate';
import { getPreferredValue, joinPreferredValues } from './linkedArtHelpers';
import getYearFromDate from '../Display/getYearFromDate';

/**
 * Utility class to provide easy access to literature contents.
 *
 */
class Literature {
  constructor(jsonObject) {
    this.json = jsonObject;
  }

  /**
   * Returns an array of literature items.
   *
   * @returns {[*]}
   */
  get items() {
    const query = '[].tuple[0].attributes[]';

    const rawItems = jmespath(this.json?.items, query) || [];
    const publications = rawItems.map(item => {
      // Literature items come in three different formats:
      // - literature - a linguistic object with a creation activity, used for
      //   books, scientific publications, news articles (etc).
      // - literature part - a linguistic object part_of another work, used for
      //   articles within a book, journal or newspaper (etc).
      // - literature pages - a linguistic object, part_of another work that
      //   cannot be referred to as an article by itself, but instead is
      //   defined as a set of pages via a pagination statement within
      //   another work.

      let title = '';
      // @todo Where can we find the URL, if applicable?
      let url = null;
      let timeSpans;
      let date = '';
      let authors = [];
      let pages = '';
      const partOf = [];

      if (item.hasOwnProperty('part_of')) {
        // Literature part or literature pages.
        title = _get(item, "part_of[0].identified_by[0]['content']", '');
        timeSpans = _get(item, 'part_of[0].used_for[0].timespan', null) ||
          _get(item, 'part_of[0].part_of[0].used_for[0].timespan', null) ||
          _get(item, 'used_for[0].timespan', []);
        date = getMostPreciseDate(timeSpans);
        authors = _get(item, 'part_of[0].created_by[0].carried_out_by', null) ||
          _get(item, 'created_by[0].carried_out_by', []);
        let rawMagazine = _get(item, 'part_of[0].part_of[0].identified_by[0]', false);

        // Literature (part) is sometimes differently structured. See #60707.
        const identifiedBy = _get(item, "identified_by[0]['content']", false);
        const classifiedAs = _get(item, "classified_as[0]['@id']", false);
        if (identifiedBy && classifiedAs) {
          title = _get(item, "identified_by[0]['content']", '');
          if (!rawMagazine) {
            rawMagazine = _get(item, "part_of[0].identified_by[0]['content']", false);
          }
        }

        // Find out more about the publication this is a part of. When we find a
        // value, push it to the partOf array.
        const magazine = getPreferredValue(rawMagazine);
        magazine && partOf.push(magazine);
        const issue = getPreferredValue(
          _get(item, 'part_of[0].referred_to_by[0]', false)
        );
        issue && partOf.push(issue);
        const query =
          'referred_to_by[?classified_as[?"@id"  == `"http://vocab.getty.edu/aat/300435440"`]].content | [0]';
        pages = jmespath(item, query) || false;
      } else {
        // Literature.
        title = _get(item, "identified_by[0]['content']", '');
        timeSpans = _get(item, 'used_for[0].timespan', []);
        date = getMostPreciseDate(timeSpans);
        authors = _get(item, 'created_by[0].carried_out_by', []);
      }

      const metadata = {
        author: joinPreferredValues(authors, '; '),
        year: date,
        partOf: partOf.join(' '),
        pages: pages ? pages : '',
      };
      // Remove empty properties.
      Object.keys(metadata).forEach(
        key =>
          (metadata[key] == null || metadata[key] === '') &&
          delete metadata[key]
      );

      return {
        label:
          getYearFromDate(timeSpans?.[0]?.begin_of_the_begin) ??
          '(Year unknown)',
        sortValue: timeSpans?.[0]?.begin_of_the_begin || 0,
        value: {
          title,
          url,
          metadata,
        },
        renderType: 'publication',
      };
    });

    publications.sort(this.sort);

    return this.cacheAsStaticProperty('items', publications);
  }

  sort(a, b) {
    const aValue = a.sortValue;
    const bValue = b.sortValue;
    if (aValue === bValue) {
      return 0;
    }

    // Null should sort before [s.a.] and dates.
    if (aValue === null && bValue !== null) {
      return -1;
    }

    if (bValue === null && aValue !== null) {
      return 1;
    }

    // (Year unknown) should sort after null, but before [s.a.] and dates
    if (aValue === 0 && bValue !== 0) {
      return -1;
    }

    if (bValue === 0 && aValue !== 0) {
      return 1;
    }

    // [s.a.] should sort after null, but before dates.
    if (aValue[0] === '[' && bValue[0] !== '[') {
      return -1;
    }

    if (bValue[0] === '[' && aValue[0] !== '[') {
      return 1;
    }

    return aValue < bValue ? -1 : 1;
  }

  /**
   * Replaces the given propertyName with a static value property.
   *
   * See
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/get#Examples
   *
   * @param {string} propertyName
   *   Name of the property to replace.
   * @param {*} propertyValue
   *   Value of the property.
   * @returns {*}
   *   Returns the given value.
   */
  cacheAsStaticProperty(propertyName, propertyValue) {
    delete this[propertyName];
    Object.defineProperty(this, propertyName, { value: propertyValue });
    return propertyValue;
  }
}

export default Literature;
