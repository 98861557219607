import React from 'react';
import LinkToSearch from './LinkToSearch';
import styled from 'styled-components';
import { colors } from '../style/variables/colors';
import { Picture } from 'react-responsive-picture';
import HorizontalSlider from './HorizontalSlider';

const StyledArtworkList = styled.div`
  h2 {
    color: ${colors.inkAlternate};
  }

  .artwork-list {
    // Override the default inline-block that Tiny Slider applies (Why?).
    display: block;
    padding: 0;

    &__item {
      height: 220px;
    }

    &__item-inner {
      height: 100%;
      position: relative;
      margin-right: 0.5rem;
    }

    &__item:last-child .artwork-list__item-inner {
      margin-right: 0;
    }

    img {
      // In case the parent width is not wide enough for the image, still force
      // it to be the correct height and accept some cropping on the edges.
      height: 100%;
      object-fit: cover;
    }

    &__item-link {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      flex-direction: column;
      justify-content: flex-end;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 55%,
        rgba(0, 0, 0, 0.6) 80%
      );
      padding: 1rem 1.5rem;
      color: #fff;
    }

    &__item-title {
      font-weight: bold;
    }

    &__item-title {
      font-size: 14px;
    }
  }
`;

const ArtworkList = ({ heading, data }) => {
  return (
    <StyledArtworkList>
      <h2>{heading}</h2>
      <HorizontalSlider
        listTag="ol"
        listClassName="artwork-list"
        buttonStyle="white"
      >
        {Object.entries(data).map(([key, item]) => (
          <li key={key} className="artwork-list__item">
            <div className="artwork-list__item-inner">
              <LinkToSearch
                filters={item.filters}
                className="artwork-list__item-link no-decoration"
              >
                <div className="artwork-list__item-title">
                  {item.caption.title}
                </div>
                <div className="artwork-list__item-subtitle">
                  {item.caption.subtitle}
                </div>
              </LinkToSearch>
              <Picture
                className="artwork-list__image"
                src={item.image.src}
                alt={item.image.altText}
              />
            </div>
          </li>
        ))}
      </HorizontalSlider>
    </StyledArtworkList>
  );
};

export default ArtworkList;
