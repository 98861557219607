import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import mediaQueries from '../style/variables/mediaQueries';
import { colors, palette } from '../style/variables/colors';
import { gutters } from '../style/variables/sizes';
import { useCookies } from 'react-cookie';
import ExternalLink from "./ExternalLink";
import { ReactComponent as CloseIcon } from '../assets/images/icon-close.svg';

const StyledSurveyPopup = styled.div`
  position: fixed;
  z-index: 1;
  background-color: ${palette.white};
  box-shadow: 0px -4px 8px 0px #0000001A;
  border: 1px solid ${colors.border};
  padding-top: 0.5rem;
  padding-bottom: 1rem;

  transform: translateY(150%);
  transition: transform 1s ease 5s;

  &.open {
    transform: translateY(0px);
  }

  // Make sure elements inside can't be accessed when popup is out of view.
  &:not(.open) {
    > * {
      display: none;
    }
  }

  ${mediaQueries.max_md} {
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  ${mediaQueries.md} {
    width: 290px;
    bottom: 20px;
    right: 80px;
  }

  > * {
    ${mediaQueries.max_md} {
      padding-left: ${gutters.xs};
      padding-right: ${gutters.xs};
    }

    ${mediaQueries.md} {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .actions-top {
    text-align: right;
    height: 12px;
  }

  .close-survey {
    width: 44px;
    height: 44px;
    margin: -17px;
  }

  .actions-bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.75rem 1.5rem;

    input[type="checkbox"] {
      margin-right: 10px;

      &:checked {
        background-color: ${palette.yellow};
        border-color: transparent;
      }
    }
  }

  .button--black {
    display: inline-block;
  }
`;

const StyledSurvey = styled.div``;

const Survey = () => {
  let surveyEnabled = false;
  if (process.env.REACT_APP_VGWW_SURVEY_ENABLED === 'true') {
    surveyEnabled = true;
  }
  const surveyCookieName = process.env.REACT_APP_VGWW_SURVEY_COOKIE_NAME;
  const [cookies, setCookie] = useCookies([surveyCookieName]);
  const optOutPrefix = 'opt-out:';
  let initialSurveyPopupVisible = surveyEnabled;
  if (cookies[surveyCookieName]?.includes(optOutPrefix)) {
    initialSurveyPopupVisible = false;
  }
  const [surveyPopupVisible, setSurveyPopupVisible] = useState(initialSurveyPopupVisible);
  const [optOut, setOptOut] = useState(false);

  // Ensure popup is hidden when user has opt-out.
  useEffect(() => {
    if (cookies[surveyCookieName]?.includes(optOutPrefix)) {
      setSurveyPopupVisible(false);
    }
  }, [cookies, surveyCookieName]);

  /**
   * Get the expiry time for the survey cookie.
   *
   * @returns {Date}
   */
  const getExpiryTime = () => {
    const tmpDate = new Date(Date.now());
    tmpDate.setFullYear(tmpDate.getFullYear() + 1);
    return tmpDate;
  }

  /**
   * Function to close the survey popup.
   *
   * @param forceOptOut
   */
  const surveyClose = (forceOptOut = false) => {
    // optOut is used for delayed triggers and forceOptOut for direct triggers.
    // This is required because of the async characteristics of useState.
    if (optOut || forceOptOut === true) {
      setCookie(surveyCookieName, optOutPrefix + '' + Date.now(), {expires: getExpiryTime(), path: '/'});
    }
    setSurveyPopupVisible(false);
  };
  return (
    <StyledSurvey>
      { surveyEnabled && surveyPopupVisible && <SurveyPopup surveyClose={surveyClose} setOptOut={setOptOut}/> }
    </StyledSurvey>
  );
};

const SurveyPopup = ({surveyClose, setOptOut}) => {
  const surveyLink = process.env.REACT_APP_VGWW_SURVEY_LINK;

  /**
   * Handles opt-out for survey based on the checkbox state.
   *
   * @param e
   */
  const handleOnCheckboxChange = (e) => {
    if (e.target.checked) {
      setOptOut(true);
    } else {
      setOptOut(false);
    }
  }

  /**
   * Handle closing the popup on using the survey link.
   */
  const handleSurveyClicked = () => {
    surveyClose(true);
  }

  // Toggle popup.
  const [itemIsOpen, setItemIsOpen] = useState(false);
  window.requestAnimationFrame(function() {
    setItemIsOpen(true);
  });

  return (
    <StyledSurveyPopup className={itemIsOpen ? 'open' : ''}>
      <div className="actions-top">
        <button
          className="button--reset close-survey"
          onClick={surveyClose}
        >
          <span className="visually-hidden">Close survey</span>
          <CloseIcon width="10" height="10" aria-hidden="true" />
        </button>
      </div>
      <div className="content">
        <h2 className="heading h4">Help us make a better website</h2>
        <p>Please fill in the short survey of 3-4 minutes and help us improve Van Gogh Worldwide.</p>
        <div className="actions-bottom">
          <ExternalLink href={surveyLink} onClick={handleSurveyClicked} className="button--black">Go to survey</ExternalLink>
          <div className="form-item">
            <input
              type="checkbox"
              name="vgww-survey-opt-out"
              id="vgww-survey-opt-out"
              onChange={handleOnCheckboxChange}
            />
            <label htmlFor="vgww-survey-opt-out">Don't show again.</label>
          </div>
        </div>
      </div>
    </StyledSurveyPopup>
  );
};



export default Survey;
