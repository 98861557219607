/**
 * Gets an IIIF manifest uri to use with the viewer.
 *
 * This manifest uri is currently a data uri, which works for small
 * manifests.
 * @param label
 * @param description
 * @param representations
 * @returns {string}
 *   A manifest url for use with an IIIF image viewer.
 */
export default function getManifestUri(label, description, representations) {
  const canvasIdBase = 'https://v.lmn.gr/c';

  const manifest = {
    '@type': 'sc:Manifest',
    label,
    description,
    attribution: '[]',
    metadata: { label: 'description', value: 'Digital images' },
    viewingDirection: 'left-to-right',
    viewingHint: 'individuals',
    sequences: [
      {
        '@type': 'sc:Sequence',
        label: '[Sequence]',
        canvases: [],
      },
    ],
  };

  representations.forEach((representation, idx) => {
    const canvas = buildCanvas(`${canvasIdBase}/${idx}`, representation);
    manifest.sequences[0].canvases.push(canvas);
  });

  return convertToDataUri('application/json', JSON.stringify(manifest));
}

/**
 * Generates a base64 encoded data uri from data.
 *
 * @param {string} mimetype
 *   The mimetype to use.
 * @param {string} data
 *   Data in an 8-bit string.
 * @returns {string}
 *   The data uri.
 */
function convertToDataUri(mimetype, data) {
  // Convert the UTF-16 string to a binary string.
  const byteArray = new TextEncoder().encode(data);
  const binaryString = String.fromCharCode(...byteArray);
  return encodeURIComponent(`data:${mimetype};base64,${btoa(binaryString)}`);
}

/**
 * Builds a manifest canvas element.
 *
 * @param {string} name
 *   Canvas name.
 * @param {object} representation
 *   A representation object.
 * @returns {object}
 *   A unique canvas object containing the representation as a resource.
 */
function buildCanvas(name, representation) {
  const canvasWidth = 5117;
  const canvasHeight = 4180;

  const canvas = {
    '@id': name,
    '@type': 'sc:Canvas',
    label: representation.label,
    height: canvasHeight,
    width: canvasWidth,
    images: [
      {
        '@type': 'oa:Annotation',
        motivation: 'sc:painting',
        resource: {
          '@id': representation.uri,
          '@type': 'dctypes:Image',
          format: 'image/jpeg',
        },
        on: name,
      },
    ],
  };
  if (representation.isIiif) {
    canvas.images[0].resource[
      '@id'
    ] = `${representation.uri}/full/full/0/default.jpg`;
    canvas.images[0].resource.service = {
      '@context': 'http://iiif.io/api/image/2/context.json',
      '@id': representation.uri,
      profile: 'http://iiif.io/api/image/2/level2.json',
    };
  }
  return canvas;
}
