import React, { useCallback, useEffect, useRef, useState } from 'react';
// Tiny Slider must be explicitly imported from its /src/ folder, see also
// https://github.com/ganlanyuan/tiny-slider/issues/353#issuecomment-447000852.
import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/dist/tiny-slider.css';
import styled from 'styled-components';
import { ReactComponent as ArrowPrevIcon } from '../assets/images/icon-arrow-prev.svg';
import { ReactComponent as ArrowNextIcon } from '../assets/images/icon-arrow-next.svg';

const StyledHorizontalSlider = styled.div`
  position: relative;

  .controls {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;

    &__control-wrapper {
      position: relative;
      z-index: 1;
      height: 100%;
      padding: 0 1rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      transition: opacity 300ms ease;

      &.disabled {
        opacity: 0;
        pointer-events: none;
      }
    }

    &.feather-edges {
      .controls__control-wrapper--prev {
        // Easing gradient from https://larsenwork.com/easing-gradients/.
        background: linear-gradient(
          to left,
          hsla(0, 0%, 100%, 0) 0%,
          hsla(0, 0%, 100%, 0.014) 7.4%,
          hsla(0, 0%, 100%, 0.052) 15.4%,
          hsla(0, 0%, 100%, 0.108) 23.8%,
          hsla(0, 0%, 100%, 0.18) 32.6%,
          hsla(0, 0%, 100%, 0.264) 41.5%,
          hsla(0, 0%, 100%, 0.356) 50.3%,
          hsla(0, 0%, 100%, 0.454) 58.9%,
          hsla(0, 0%, 100%, 0.553) 67.2%,
          hsla(0, 0%, 100%, 0.651) 74.9%,
          hsla(0, 0%, 100%, 0.743) 81.9%,
          hsla(0, 0%, 100%, 0.826) 87.9%,
          hsla(0, 0%, 100%, 0.897) 93%,
          hsla(0, 0%, 100%, 0.952) 96.8%,
          hsla(0, 0%, 100%, 0.987) 99.2%,
          hsl(0, 0%, 100%) 100%
        );
      }
      .controls__control-wrapper--next {
        background: linear-gradient(
          to right,
          hsla(0, 0%, 100%, 0) 0%,
          hsla(0, 0%, 100%, 0.014) 7.4%,
          hsla(0, 0%, 100%, 0.052) 15.4%,
          hsla(0, 0%, 100%, 0.108) 23.8%,
          hsla(0, 0%, 100%, 0.18) 32.6%,
          hsla(0, 0%, 100%, 0.264) 41.5%,
          hsla(0, 0%, 100%, 0.356) 50.3%,
          hsla(0, 0%, 100%, 0.454) 58.9%,
          hsla(0, 0%, 100%, 0.553) 67.2%,
          hsla(0, 0%, 100%, 0.651) 74.9%,
          hsla(0, 0%, 100%, 0.743) 81.9%,
          hsla(0, 0%, 100%, 0.826) 87.9%,
          hsla(0, 0%, 100%, 0.897) 93%,
          hsla(0, 0%, 100%, 0.952) 96.8%,
          hsla(0, 0%, 100%, 0.987) 99.2%,
          hsl(0, 0%, 100%) 100%
        );
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: calc(3rem + 4px);
      height: calc(3rem + 4px);
      cursor: pointer;

      &:focus {
        outline: none;
        background-color: inherit;
      }

      &.button--white {
        border: 2px solid #000;
        &:focus {
          box-shadow: 0 0 5px 1px rgba(255, 255, 255, 0.9);
          background-color: #fff;
        }
      }
      &.button--black {
        border: 2px solid #fff;
        &:focus {
          box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.9);
          background-color: #000;
        }
      }
    }
    &__button-next {
      margin-left: auto;
    }
  }
`;

const HorizontalSlider = ({
  children,
  listTag = 'ul',
  listClassName = '',
  buttonStyle = 'white',
  featherEdges = false,
  tinySliderOptions = {},
}) => {
  const [slider, setSlider] = useState(null);
  const [prevControlDisabled, setPrevControlDisabled] = useState(true);
  const [nextControlDisabled, setNextControlDisabled] = useState(true);
  const sliderList = useRef(null);
  const prevControl = useRef(null);
  const nextControl = useRef(null);

  const onTransitionStart = useCallback(() => {
    // When transition starts, reveal controls that were disabled.
    if (prevControlDisabled) {
      setPrevControlDisabled(false);
    }
    if (nextControlDisabled) {
      setNextControlDisabled(false);
    }
  }, [prevControlDisabled, nextControlDisabled]);

  const onTransitionEnd = info => {
    // Check if we have reached the start of the list. This is pretty easy just
    // looking at the active element.
    if (info.index === 0) {
      setPrevControlDisabled(true);
    }

    // Check if we have reached the end of scrolling by comparing the right
    // position of the last child with that of the parent element.
    const lastChildRect = info.container.lastChild.getBoundingClientRect();
    const parentRect = info.container.offsetParent.getBoundingClientRect();
    if (lastChildRect.right <= parentRect.right) {
      setNextControlDisabled(true);
    }
  };

  const onPrevClick = () => {
    slider.goTo('prev');
  };

  const onNextClick = () => {
    slider.goTo('next');
  };

  useEffect(() => {
    if (children.length && !slider) {
      const defaultOptions = {
        container: sliderList.current,
        autoWidth: true,
        // We do not use the built-in controls, but instead create our own
        // because the default implementation has a11y issues
        // (see https://github.com/ganlanyuan/tiny-slider/issues/378).
        controls: false,
        nav: false,
        loop: false,
        onInit: info => {
          // Enable next button if the last slide is outside the container.
          const lastChildRect = info?.container?.lastChild
            ? info.container.lastChild.getBoundingClientRect()
            : 0;
          const parentRect = info?.container?.offsetParent
            ? info.container.offsetParent.getBoundingClientRect()
            : 0;
          if (lastChildRect.right > parentRect.right) {
            setNextControlDisabled(false);
          }
        },
      };
      const instance = tns({ ...defaultOptions, ...tinySliderOptions });
      instance.events.on('transitionStart', onTransitionStart);
      instance.events.on('transitionEnd', onTransitionEnd);
      setSlider(instance);
    }
  }, [slider, children, tinySliderOptions, onTransitionStart]);

  const Tag = listTag;

  return (
    <StyledHorizontalSlider>
      <div className={`controls ${featherEdges ? 'feather-edges' : ''}`}>
        <div
          ref={prevControl}
          className={`controls__control-wrapper controls__control-wrapper--prev controls__control-wrapper--${buttonStyle} ${
            prevControlDisabled ? 'disabled' : ''
          }`}
          aria-hidden={prevControlDisabled}
        >
          <button
            onClick={onPrevClick}
            className={`controls__button controls__button-prev button--${buttonStyle}`}
          >
            <ArrowPrevIcon aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
        </div>
        <div
          ref={nextControl}
          className={`controls__control-wrapper controls__control-wrapper--next controls__control-wrapper--${buttonStyle} ${
            nextControlDisabled ? 'disabled' : ''
          }`}
          aria-hidden={nextControlDisabled}
        >
          <button
            onClick={onNextClick}
            className={`controls__button controls__button-next button--${buttonStyle}`}
          >
            <span className="visually-hidden">Next</span>
            <ArrowNextIcon aria-hidden="true" />
          </button>
        </div>
      </div>
      <Tag ref={sliderList} className={listClassName}>
        {children}
      </Tag>
    </StyledHorizontalSlider>
  );
};

export default HorizontalSlider;
