import React from 'react';
import { ReactComponent as InstituteIcon } from '../assets/images/icon-institute.svg';
import { Link } from 'react-router-dom';
import ContentHeader from './ContentHeader';

const ExhibitionHeader = ({ exhibition }) => {
  const details = [];
  if (exhibition.carriedOutBy) {
    details.push({
      label: 'Institution',
      data: exhibition.carriedOutByActorPath ? (
        <Link to={exhibition.carriedOutByActorPath}>
          {exhibition.carriedOutBy}
        </Link>
      ) : (
        exhibition.carriedOutBy
      ),
    });
  }
  if (exhibition.period) {
    details.push({ label: 'Date', data: exhibition.period });
  }

  return (
    <ContentHeader
      title={exhibition.title}
      subtitle="Exhibition"
      details={details}
      iconComponent={InstituteIcon}
    />
  );
};

export default ExhibitionHeader;
