/**
 * Converts representations to objects suitable for rendering.
 *
 * @param {[{uri, label, description, isIiif}]} representations
 *   An array of objects describing representations.
 *
 * @returns {*[]|*}
 *   An array of objects with sourcesets and thumbnails.
 */
import getRenderedRepresentation from './getRenderedRepresentation';

export default function getRenderedRepresentations(representations) {
  if (representations.length === 0) {
    return [];
  }

  return representations.map(getRenderedRepresentation);
}
